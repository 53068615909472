import { RequestWrapper } from '../../../helpers/RequestWrapper';
import API from '..';

const USERS_ENDPOINT = 'dash/users';

class UsersAPI {
  static getAllUsers = (qs: string, errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.get(`${USERS_ENDPOINT}${qs}`)).data, errorHandler
    );
  };

  static getAllRoles = (errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.get(`${USERS_ENDPOINT}/roles`)).data, errorHandler
    );
  };

  static getUser = (id: string | undefined, errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.get(`${USERS_ENDPOINT}/${id}/show`)).data, errorHandler
    );
  };

  static sendActivationLink = (id: string | undefined, errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.get(`${USERS_ENDPOINT}/${id}/activation-link`)).data, errorHandler
    );
  };

  static updateRole = (userId: string | undefined, rolesIds: string[] | number[], errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.post(`${USERS_ENDPOINT}/${userId}/update-role`, { ids: rolesIds })).data, errorHandler
    );
  };

  static destroy = (ids: string[], errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.post(`${USERS_ENDPOINT}/destroy`, { ids: ids })).data, errorHandler
    );
  };
}

export { USERS_ENDPOINT, UsersAPI };
