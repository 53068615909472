import { ConfigProvider } from 'antd';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import App from '../App';
import { AuthProvider } from './auth/AuthProvider';

export const Providers: React.FC = () => {
  return (
    <BrowserRouter>
      <ConfigProvider theme={{ 
        token: { colorPrimary: '#4338ca' }
      }}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </ConfigProvider>
    </BrowserRouter>
  );
};
