import { Divider, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

import { ProjectsAPI } from '../../app/api/endpoints/Projects';
import { useCan } from '../../app/can';
import HeaderTitle from '../../components/Parcial/HeaderTitle';
import { failedAlert, handleError } from '../../helpers/Utils';
import { Project } from '../../models/Project';

export default function MonitoringProject() {
  const can = useCan();
  const navigate = useNavigate();
  const { projectId } = useParams<{projectId: string}>();
  const [loadingProject, setLoadingProject] = useState(false);
  const [project, setProject] = useState<Project>({} as Project);

  useEffect(() => {
    if (projectId) {
      getProject();
    }
  }, [ projectId ]);

  const getProject = async () => {
    setLoadingProject(true);
    const { result } = await ProjectsAPI.getProject(projectId, (error) => {
      handleError(error);
      setLoadingProject(false);
    });

    if (result) {
      if (result.status) {
        setProject(result.project);
      } else {
        failedAlert(result.message);
        navigate('/projects');
      }
      setLoadingProject(false);
    }
  };


  return (
    <div>
      <HeaderTitle
        onBack={ () => navigate(`/project/${project?.projectId}/overview`) }
        title={ project?.projectName || '' }
      />
      <Divider />
      {
        can('read', 'project') && can('update', 'project') &&
        <Spin tip="Loading..." spinning={ loadingProject } wrapperClassName="w-full">
          <div className="px-4 sm:px-12 rounded-xl border divide-y-2 divide-ft-ts/70 shadow-lg">
            <NavLink to={ `/project/${project.projectId}/monitoring/balance` }>
              <div className="flex items-center justify-between py-4 sm:py-7">
                <div className="flex items-center justify-center gap-x-3">
                  <svg xmlns="http://www.w3.org/2000/svg" className="hidden md:block h-12 w-12">
                    <path fill="none" d="M0 0h48v48H0z"></path>
                    <path d="M42 36v2c0 2.21-1.79 4-4 4H10c-2.21 0-4-1.79-4-4V10c0-2.21 1.79-4 4-4h28c2.21 0 4 1.79 4 4v2H24c-2.21 0-4 1.79-4 4v16c0 2.21 1.79 4 4 4h18zm-18-4h20V16H24v16zm8-5c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z"></path>
                  </svg>
                  <h3 className="text-base sm:text-2xl font-bold">Balance</h3>
                </div>
                <button className="transition-colors duration-[250ms] bg-red-700 text-white disabled:cursor-not-allowed disabled:bg-gray-400 disabled:text-gray-800 text-xs text-center sm:text-base rounded-lg px-4 py-1 tracking-[0.05rem w-40">Manage</button>
              </div>
            </NavLink>
            <Divider/>
            <NavLink to={ `/project/${project.projectId}/monitoring/controls` }>
              <div className="flex items-center justify-between py-4 sm:py-7">
                <div className="flex items-center justify-center gap-x-3">
                  <svg xmlns="http://www.w3.org/2000/svg" className="hidden md:block h-12 w-12">
                    <path fill="none" d="M0 0h48v48H0z"></path>
                    <path d="M42 36v2c0 2.21-1.79 4-4 4H10c-2.21 0-4-1.79-4-4V10c0-2.21 1.79-4 4-4h28c2.21 0 4 1.79 4 4v2H24c-2.21 0-4 1.79-4 4v16c0 2.21 1.79 4 4 4h18zm-18-4h20V16H24v16zm8-5c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z"></path>
                  </svg>
                  <h3 className="text-base sm:text-2xl font-bold">Controls</h3>
                </div>
                <button className="transition-colors duration-[250ms] bg-red-700 text-white disabled:cursor-not-allowed disabled:bg-gray-400 disabled:text-gray-800 text-xs text-center sm:text-base rounded-lg px-4 py-1 tracking-[0.05rem w-40">Manage</button>
              </div>
            </NavLink>
            <Divider/>
            <NavLink to={ `/project/${project.projectId}/monitoring/whitelists` }>
              <div className="flex items-center justify-between py-4 sm:py-7">
                <div className="flex items-center justify-center gap-x-3">
                  <svg xmlns="http://www.w3.org/2000/svg" className="hidden md:block h-12 w-12">
                    <path fill="none" d="M0 0h48v48H0z"></path>
                    <path d="M42 36v2c0 2.21-1.79 4-4 4H10c-2.21 0-4-1.79-4-4V10c0-2.21 1.79-4 4-4h28c2.21 0 4 1.79 4 4v2H24c-2.21 0-4 1.79-4 4v16c0 2.21 1.79 4 4 4h18zm-18-4h20V16H24v16zm8-5c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z"></path>
                  </svg>
                  <h3 className="text-base sm:text-2xl font-bold">Whitelists</h3>
                </div>
                <button className="transition-colors duration-[250ms] bg-red-700 text-white disabled:cursor-not-allowed disabled:bg-gray-400 disabled:text-gray-800 text-xs text-center sm:text-base rounded-lg px-4 py-1 tracking-[0.05rem w-40">Manage</button>
              </div>
            </NavLink>
          </div>
        </Spin>
      }
    </div>
  );
}

