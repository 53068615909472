import {
  PlusOutlined, SearchOutlined, SyncOutlined
} from '@ant-design/icons';
import { Button, Divider, Empty, Input, Spin } from 'antd';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { ProjectsAPI } from '../../app/api/endpoints/Projects';
import { useCan } from '../../app/can';
import HeaderTitle from '../../components/Parcial/HeaderTitle';
import ProjectCard from '../../components/Projects/ProjectCard';
import { handleError } from '../../helpers/Utils';
import { LocalProject } from '../../models/Project';

export default function Projects() {
  const can = useCan();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSync, setLoadingSync] = useState<boolean>(false);
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);

  useEffect(() => {
    getProjects();
  }, []);

  const getProjects = async () => {
    setLoading(true);
    const { result } = await ProjectsAPI.getAllProjects((error) => {
      handleError(error);
      setLoading(false);
    });

    if (result) {
      if (result.status) {
        setProjects(result.projects);
        setFilteredProjects(result.projects);
      } 
      setLoading(false);
    }
  };

  const syncProjects = async () => {
    setLoadingSync(true);
    const { result } = await ProjectsAPI.syncProjects((error) => {
      setLoadingSync(false);
      handleError(error);
    });

    if (result) {
      setLoadingSync(false);
      if (result.status) {
        getProjects();
      }
    }
  };

  const onProjectDeleted = (projectId: string) => {
    setProjects(projects.filter((p: LocalProject) => p.project_id !== projectId));
  };

  const searchProject = (e: ChangeEvent<HTMLInputElement>) => {
    if(e.target.value === '') {
      setFilteredProjects(projects);
    } else {
      setFilteredProjects(projects.filter((item: LocalProject) => {
        return item.project_name.toLowerCase().includes(e.target.value.toLowerCase());
      }));
    }
  };

  return (
    <>
      <div>
        <HeaderTitle
          onBack={ () => history.back() }
          title="Projects"
        />
        <Divider />
        <div className="flex mb-4">
          {
            can('read', 'project') && can('create', 'project') &&
            <Button
              loading={ loadingSync }
              onClick={ syncProjects }
              className="!text-white !bg-green-700 hover:bg-green-900"
              size="large"
            >
              { !loadingSync && <SyncOutlined className="pr-2"/> }
              Sync Projects from Fever App
            </Button>
          }
        </div>
        <div className="flex pb-4 flex-col sm:flex-row">
          <div className="mx-auto w-full sm:w-full flex items-center justify-start  text-ft-ts mr-4">
            <Input size="large" placeholder="Search project..." suffix={ <SearchOutlined /> } onChange={ (e) => searchProject(e) } />
          </div>
          {
            can('create', 'project') &&
            <div className="flex w-full sm:w-auto my-2 justify-center">
              <div className="">
                <NavLink to="/projects/create">
                  <div className="h-9 px-12 flex justify-center items-center text-sm font-medium rounded-md text-white bg-indigo-700 hover:bg-indigo-900 cursor-pointer ">
                    <PlusOutlined className="pr-2"/>
                    <span className="w-[100px]">New Project</span>
                  </div>
                </NavLink>
              </div>
            </div>
          }
        </div>
        {
          can('read', 'project') &&
          <div className="w-full flex flex-col justify-center items-center">
            <Spin tip="Loading..." spinning={ loading } wrapperClassName="w-full">
              {
                filteredProjects.length > 0 ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:gride-cols-2 gap-4 w-full mb-10  ">
                    {
                      filteredProjects.map((project: LocalProject) => (
                        <ProjectCard
                          key={ project.project_id }
                          project={ project }
                          onProjectDeleted={ onProjectDeleted }
                        />
                      ))
                    }
                  </div>
                ) : (
                  <Empty />
                )
              }
            </Spin>
          </div>
        }
      </div>
    </>
  );
}
