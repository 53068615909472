import React from 'react';

function Dashboard(): JSX.Element {

  return (
    <>
      Dashboard
    </>
  );
}

export default Dashboard;
