import { CloseCircleOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { CollectionsAPI } from '../../app/api/endpoints/Collections';
import { errorAlert, failedAlert, format422Error, handleError, validateImage } from '../../helpers/Utils';

export type UplodedFile =  {
  fileName: string;
  blobUrl: string;
}
interface CollectionUploadProps {
  defaultFile?: UplodedFile;
  onFileUplodSucceed: (arg: UplodedFile) => void;
}

export default function CollectionUpload({ onFileUplodSucceed, defaultFile }: CollectionUploadProps) {
  console.log(defaultFile);
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<any | undefined>();

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const { valid, error } = validateImage(acceptedFiles[0]);
    if(!valid && error) {
      errorAlert(error);
    } else {
      const formData = new FormData();
      formData.append('uploadedFile', acceptedFiles[0]);
      setLoading(true);
      const { result } = await CollectionsAPI.uploadFile(formData,
        (error) => {
          setLoading(false);
          if(error && error?.response && error.response.status === 422) {
            // @ts-ignore
            const errorFile = format422Error(error.response.data?.errors);
            if (errorFile && errorFile.collectionFile) {
              failedAlert(errorFile.collectionFile);
            }
          } else {
            handleError(error);  
          }
        });
      
      if (result) {
        setLoading(false);
        if (result.status) {
          onFileUplodSucceed({
            fileName: result.file.fileName,
            blobUrl: result.file.filePath
          });
          setFile(result.file);
        } else {
          failedAlert(result.message);
        }
      }
    }
  }, []);

  const removeFile = () => {
    onFileUplodSucceed({
      fileName: '',
      blobUrl: ''
    });
    setFile(undefined);
  };

  const checkDefaultFile = (file: UplodedFile | undefined) => {
    return file && file.blobUrl !== '' && file.fileName !== '';
  };


  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/jpg': [],
      'image/png': []
    },
    maxFiles: 1,
    onDrop
  });

  return (
    <Spin tip="Uploading file..." spinning={ loading }>
      {
        file || checkDefaultFile(defaultFile) ? (
          <div className="border-2 border-dashed border-gray-400 bg-gradient-to-b from-black to-transparent text-center cursor-pointer h-24 relative">
            <CloseCircleOutlined
              className="text-lg text-red-400 absolute top-3 right-3"
              onClick={ removeFile }
            />
            {
              file ? (
                <div className="overflow-hidden flex justify-center w-full h-full">
                  <img
                    className="h-full object-cover"
                    src={ file?.filePath }
                  />
                </div>
              ) : (
                <div className="overflow-hidden flex justify-center w-full h-full">
                  <img
                    className="h-full object-cover"
                    src={ defaultFile?.blobUrl }
                  />
                </div>
              )
            }
          </div>
        ) : (
          <div { ...getRootProps() } className="border-2 border-dashed border-gray-400 bg-gray-200 p-6 text-center cursor-pointer">
            <input { ...getInputProps() } />
            {
              isDragActive ?
                <>
                  <p>Drop the file here ...</p>
                  <p>(Only *.jpeg, *.jpg and *.png images will be accepted)</p>
                </> :
                <>
                  <p>Drag drop some file here, or click to select file</p>
                  <p>(Only *.jpeg, *.jpg and *.png images will be accepted)</p>
                </>
            }   
          </div>
                       
        )
      }
    </Spin>
  );
};
