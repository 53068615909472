import { Divider, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { CollectionsAPI } from '../../app/api/endpoints/Collections';
import { useCan } from '../../app/can';
import CollectionItems from '../../components/Collections/CollectionItems';
import Overview from '../../components/Collections/OverView';
import SettingsCollection from '../../components/Collections/SettingsCollection';
import Tabs, { TabsItems } from '../../components/Common/Tabs';
import HeaderTitle from '../../components/Parcial/HeaderTitle';
import { failedAlert, handleError } from '../../helpers/Utils';
import { LocalCollection } from '../../models/Collection';
;

export default function ShowCollection() {
  const can = useCan();
  const navigate = useNavigate();
  const { projectId } = useParams<{projectId: string}>();
  const { clubId } = useParams<{clubId: string}>();
  const { collectionId } = useParams<{collectionId: string}>();
  const [ searchParams ] = useSearchParams();
  const [loadingCollection, setLoadingCollection] = useState(false);
  const [collection, setCollection] = useState<LocalCollection>();
  const [activeKey, setActiveKey] = useState<number>(1);

  useEffect(() => {
    if (collectionId) {
      getCollection();
    }
  }, [ collectionId ]);

  const items : TabsItems[] = [
    {
      id: 1,
      label: 'overview',
      children: <Overview collection={ collection } projectId={ projectId } clubId={ clubId } />
    },
    {
      id: 2,
      label: 'items',
      children: <CollectionItems collection={ collection } projectId={ projectId } clubId={ clubId } />
    },
    {
      id: 3,
      label: 'proposals',
    },
    {
      id: 4,
      label: 'settings',
      children: <SettingsCollection collection={ collection } setCollection={ setCollection } projectId={ projectId } />
    },
  ];

  useEffect(() => {
    if (searchParams.get('tab')) {
      const tab = items.find(item => item.label === searchParams.get('tab'));
      setActiveKey(tab?.id || 1);
    }
  }, [searchParams, collectionId]);

  const getCollection = async () => {
    setLoadingCollection(true);
    const { result } = await CollectionsAPI.getCollection(collectionId, (error) => {
      handleError(error);
      setLoadingCollection(false);
    });

    if (result) {
      if (result.status) {
        setCollection(result.collection);
      } else {
        failedAlert(result.message);
        navigate(`/project/${projectId}/clubs/${clubId}?tab=collections`);
      }
      setLoadingCollection(false);
    }
  };

  return (
    <div>
      <HeaderTitle
        onBack={ () => navigate(`/project/${projectId}/clubs/${clubId}?tab=collections`) }
        title={ collection?.collection_name || '' }
      />
      <Divider />
      {
        can('read', 'collection') &&
        <Spin tip="Loading collection..." spinning={ loadingCollection } wrapperClassName="w-full">
          <div className="my-6">
            <Tabs activeKey={ activeKey } items={ items }/>
          </div>
        </Spin>
      }
    </div>
  );
}
