import { AxiosError } from 'axios';

import { RequestWrapper } from '../../../helpers/RequestWrapper';
import API from '..';

const ITEMS_ENDPOINT = 'dash/items';

class ItemsAPI {
  static getAllItems = (collectionId: string | undefined, errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.get(`${ITEMS_ENDPOINT}/${collectionId}`)).data, errorHandler
    );
  };

  static syncItems = (collectionId: string | undefined, errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.get(`${ITEMS_ENDPOINT}/${collectionId}/sync`)).data, errorHandler
    );
  };

  static getItem = (collectionId: string | undefined, itemId: string | undefined, errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.get(`${ITEMS_ENDPOINT}/${collectionId}/${itemId}/show`)).data, errorHandler
    );
  };

  static createItem = (collectionId: string | undefined, data: any, errorHandler: (error: AxiosError) => void) => {
    return RequestWrapper(
      async () => 
        (await API.post(`${ITEMS_ENDPOINT}/${collectionId}/store`, data)).data, errorHandler
    );
  };

  static uploadFile = (data: any, errorHandler: (error: AxiosError) => void) => {
    return RequestWrapper(
      async () => 
        (await API.post(`${ITEMS_ENDPOINT}/upload-file`, data)).data, errorHandler
    );
  };
  
  static updateItem = (collectionId: string | undefined, itemId: string | undefined, data: any, errorHandler: (error: AxiosError) => void) => {
    return RequestWrapper(
      async () => 
        (await API.put(`${ITEMS_ENDPOINT}/${collectionId}/${itemId}/update`, data)).data, errorHandler
    );
  };
  
  static updateItemVisibility = (collectionId: string | undefined, itemId: string | undefined, data: any, errorHandler: (error: AxiosError) => void) => {
    return RequestWrapper(
      async () => 
        (await API.put(`${ITEMS_ENDPOINT}/${collectionId}/${itemId}/update-visibility`, data)).data, errorHandler
    );
  };

  static destroyItem = (collectionId: string | undefined, itemId: string | undefined, errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.delete(`${ITEMS_ENDPOINT}/${collectionId}/${itemId}/destroy`)).data, errorHandler
    );
  };
}

export { ITEMS_ENDPOINT, ItemsAPI };
