import React, { ReactElement } from 'react';

import GoogleProvider from '../pages/Auth/GoogleProvider';
import Login from '../pages/Auth/Login';
import TwoFactorRecovery from '../pages/Auth/TwoFactorRecovery';
import TwoFactorVerification from '../pages/Auth/TwoFactorVerification';
import Categories from '../pages/Blog/Categories/Categories';
import CreatePost from '../pages/Blog/Posts/CreatePost';
import EditPost from '../pages/Blog/Posts/EditPost';
import Posts from '../pages/Blog/Posts/Posts';
import Collections from '../pages/Collections/Collections';
import CreateCollection from '../pages/Collections/CreateCollection';
import EditCollection from '../pages/Collections/EditCollection';
import ShowCollection from '../pages/Collections/ShowCollection';
import Dashboard from '../pages/Dashboard/Index';
import CreateItem from '../pages/Items/CreateItem';
import EditItem from '../pages/Items/EditItem';
import BalanceProject from '../pages/Projects/BalanceProject';
import CreateProject from '../pages/Projects/CreateProject';
import EditProject from '../pages/Projects/EditProject';
import MonitoringProject from '../pages/Projects/MonitoringProject';
import Projects from '../pages/Projects/Projects';
import SettingsProject from '../pages/Projects/SettingsProject';
import ShowProject from '../pages/Projects/ShowProject';
import CreateRole from '../pages/Roles/CreateRole';
import EditRole from '../pages/Roles/EditRole';
import Roles from '../pages/Roles/Roles';
import ShowUser from '../pages/Users/ShowUser';
import Users from '../pages/Users/Users';

export type RouterType = {
  path: string;
  element: ReactElement;
  requiredAuth: boolean;
}
const routes: RouterType[] = [
  {
    path: '/',
    element: <Dashboard/>,
    requiredAuth: true
  },
  {
    path: '/users',
    element: <Users/>,
    requiredAuth: true
  },
  {
    path: '/users/:id/show',
    element: <ShowUser/>,
    requiredAuth: true
  },
  {
    path: '/roles',
    element: <Roles/>,
    requiredAuth: true
  },
  {
    path: '/roles/create',
    element: <CreateRole/>,
    requiredAuth: true
  },
  {
    path: '/roles/:id/edit',
    element: <EditRole/>,
    requiredAuth: true
  },
  {
    path: '/projects',
    element: <Projects/>,
    requiredAuth: true
  },
  {
    path: '/project/:projectId',
    element: <ShowProject/>,
    requiredAuth: true
  },
  {
    path: '/projects/create',
    element: <CreateProject/>,
    requiredAuth: true
  },
  {
    path: '/projects/:projectId/edit',
    element: <EditProject/>,
    requiredAuth: true
  },
  {
    path: '/project/:projectId/collections',
    element: <Collections/>,
    requiredAuth: true
  },
  {
    path: '/project/:projectId/collections/:collectionId',
    element: <ShowCollection/>,
    requiredAuth: true
  },
  {
    path: '/project/:projectId/collections/create',
    element: <CreateCollection/>,
    requiredAuth: true
  },
  {
    path: '/project/:projectId/collections/:collectionId/edit',
    element: <EditCollection/>,
    requiredAuth: true
  },
  {
    path: '/project/:projectId/collections/:collectionId/items/create',
    element: <CreateItem/>,
    requiredAuth: true
  },
  {
    path: '/project/:projectId/collections/:collectionId/items/:itemId/update',
    element: <EditItem/>,
    requiredAuth: true
  },
  {
    path: '/project/:projectId/monitoring',
    element: <MonitoringProject/>,
    requiredAuth: true
  },
  {
    path: '/project/:projectId/monitoring/balance',
    element: <BalanceProject/>,
    requiredAuth: true
  },
  {
    path: '/project/:projectId/settings',
    element: <SettingsProject/>,
    requiredAuth: true
  },
  {
    path: '/blog/posts',
    element: <Posts/>,
    requiredAuth: true
  },
  {
    path: '/blog/posts/create',
    element: <CreatePost/>,
    requiredAuth: true
  },
  {
    path: '/blog/posts/:postId/edit',
    element: <EditPost/>,
    requiredAuth: true
  },
  {
    path: '/blog/categories',
    element: <Categories/>,
    requiredAuth: true
  },
  {
    path: '/login',
    element: <Login/>,
    requiredAuth: false
  },
  {
    path: '/auth/google',
    element: <GoogleProvider/>,
    requiredAuth: false
  },
  {
    path: '/verify-2fa',
    element: <TwoFactorVerification/>,
    requiredAuth: false
  },
  {
    path: '/verify-2fa-recovery',
    element: <TwoFactorRecovery/>,
    requiredAuth: false
  },
];

export default routes;
