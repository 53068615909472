import { AxiosError } from 'axios';

import { RequestWrapper } from '../../../helpers/RequestWrapper';
import API from '..';

const PROJECTS_ENDPOINT = 'dash/projects';

class ProjectsAPI {
  static getAllProjects = (errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.get(`${PROJECTS_ENDPOINT}`)).data, errorHandler
    );
  };

  static syncProjects = (errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.get(`${PROJECTS_ENDPOINT}/sync`)).data, errorHandler
    );
  };

  static syncProjectSetting = (projectId: string | undefined, errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.get(`${PROJECTS_ENDPOINT}/settings/${projectId}/sync`)).data, errorHandler
    );
  };

  static getProject = (projectId: string | undefined, errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.get(`${PROJECTS_ENDPOINT}/${projectId}/show`)).data, errorHandler
    );
  };

  static createProject = (data: any, errorHandler: (error: AxiosError) => void) => {
    return RequestWrapper(
      async () => 
        (await API.post(`${PROJECTS_ENDPOINT}/store`, data)).data, errorHandler
    );
  };

  static updateProject = (projectId: string | undefined, data: any, errorHandler: (error: AxiosError) => void) => {
    return RequestWrapper(
      async () => 
        (await API.put(`${PROJECTS_ENDPOINT}/${projectId}/update`, data)).data, errorHandler
    );
  };

  static updateProjectName = (projectId: string | undefined, data: any, errorHandler: (error: AxiosError) => void) => {
    return RequestWrapper(
      async () => 
        (await API.put(`${PROJECTS_ENDPOINT}/${projectId}/update-name`, data)).data, errorHandler
    );
  };

  static destroyProject = (projectId: string | undefined, errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.delete(`${PROJECTS_ENDPOINT}/${projectId}/destroy`)).data, errorHandler
    );
  };
}

export { PROJECTS_ENDPOINT, ProjectsAPI };
