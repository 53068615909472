import { Divider, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

import { ProjectsAPI } from '../../app/api/endpoints/Projects';
import { useCan } from '../../app/can';
import HeaderTitle from '../../components/Parcial/HeaderTitle';
import { failedAlert, handleError } from '../../helpers/Utils';
import { Project } from '../../models/Project';

export default function ShowProject() {
  const can = useCan();
  const navigate = useNavigate();
  const { projectId } = useParams<{projectId: string}>();
  const [loadingProject, setLoadingProject] = useState(false);
  const [project, setProject] = useState<Project>();

  useEffect(() => {
    if (projectId) {
      getProject();
    }
  }, [ projectId ]);

  const getProject = async () => {
    setLoadingProject(true);
    const { result } = await ProjectsAPI.getProject(projectId, (error) => {
      handleError(error);
      setLoadingProject(false);
    });

    if (result) {
      if (result.status) {
        setProject(result.project);
      } else {
        failedAlert(result.message);
        navigate('/projects');
      }
      setLoadingProject(false);
    }
  };

  return (
    <div>
      <HeaderTitle
        onBack={ () => navigate('/projects') }
        title={ project?.projectName || '' }
      />
      <Divider />
      {
        can('read', 'project') &&
        <Spin tip="Loading project..." spinning={ loadingProject } wrapperClassName="w-full">
          <div className="flex items-center justify-center gap-x-4 ">
            <div className=" bg-white w-5/6  min-w-[300px]  min-h-[100px] rounded-xl p-10 border border-gray-300">
              <div className="px-7 py-4 ">
                <div>
                  <h1 className="text-xl text-black font-bold tracking-[0.05rem] mb-8">Manage collections</h1>
                  <div className="flex gap-x-4 w-full">
                    <div className="hidden md:block w-10">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-20" overflow="hidden">
                        <defs>
                          <clipPath id="clipCollection">
                            <path d="M183 469H231V517H183z"></path>
                          </clipPath>
                        </defs>
                        <g fill="black" clipPath="url(#clipCollection)" transform="translate(-183 -469)">
                          <path d="M207.003 499.672l-13.167-5.586-9.814 4.164 22.981 9.75 22.981-9.75-9.814-4.164-13.167 5.586z"></path>
                          <path d="M207.003 478l-22.981 9.75 22.981 9.75 22.981-9.75-22.981-9.75z"></path>
                        </g>
                      </svg>
                    </div>
                    <div className="ml-8 flex flex-col items-start justify-start gap-y-6 font-medium w-full">
                      <div className="font-bold leading-6 text-base text-ft-lt tracking-wide">Upload your files, setup key attributes, price your items, define rarity, etc.</div>
                      <div className="flex flex-col md:flex-row md:space-x-2 justify-between items-center w-full ">
                        <div className=" w-full flex items-center justify-center gap-x-1 text-white font-medium rounded-lg ring-1 ring-black">
                          <div className="px-2 py-1 rounded-lg bg-white text-black tracking-[.07rem] flex items-center">
                            <span className="xl:block pr-1">Collections:</span>
                            <span>{ project?.collections_count }</span>
                          </div>
                        </div>
                        <NavLink
                          className="mt-2 md:mt-0 w-full px-5 py-1 text-base text-white hover:text-white bg-indigo-700 hover:bg-indigo-900 rounded-md text-center"
                          to={ `/project/${project?.projectId}/collections` }>
                            Start
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      }
    </div>
  );
}
