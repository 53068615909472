import { CloseCircleOutlined } from '@ant-design/icons';
import React from 'react';

import { PropertyType } from './ItemProperty';

interface PropertyCardProps {
  property: PropertyType;
  index: number;
  onRemove: (arg: number) => void;
}

export default function ItemPropertyCard ({ property, index, onRemove }: PropertyCardProps) {

  const calculateLevelsPercentage = () => {
    return +((parseFloat(property.value)/parseFloat(property.maxValue!))*100).toFixed(2);
  };
  
  return (
    <div className="bg-gray-100 py-2 px-5 rounded-lg tracking-[.12rem] border border-ft-lb relative">
      {
        property.propertyType === 'Levels' ? (
          <div className="space-y-4">
            <div className="text-ft-lt flex items-center justify-between">
              <div className="text-md font-bold mb-[1px] capitalize">{ property.propertyName }</div>
              <div className="text-md font-bold mb-[1px] mr-4">{ property.value } of { property.maxValue }</div>
            </div>
            <div className="relative overflow-hidden h-4 text-xs flex rounded-lg bg-slate-400/60">
              <div className="flex text-center text-white items-center justify-center bg-indigo-700" style={{ width: `${calculateLevelsPercentage()}%` }}></div>
              <h1 className="absolute w-full h-full text-center font-bold text-white">{ calculateLevelsPercentage() }%</h1>
            </div>
          </div>
        ) : (
          <div className="flex gap-x-10 justify-between ">
            <div className="w-32 space-y-1">
              <div className="text-md mb-[1px]">Name</div>
              <div className="text-md font-bold w-32 truncate capitalize">{ property.propertyName }</div>
            </div>
            {
              property.propertyType === 'Link' ? (
                <div className="w-56 space-y-1">
                  <div className="text-md mb-[1px]">Link</div>
                  <a target="_blank" href={ property.value } className="block text-md font-bold w-32 truncate text-indigo-500 cursor-pointer" rel="noreferrer">
                    { property.value }
                  </a>
                </div>
              ) : (
                <div className="text-ft-lt w-56 space-y-1">
                  <div className="text-md mb-[1px] ">Value</div>
                  <div className="text-md font-bold w-32 truncate capitalize">
                    { property.value }
                    {
                      property.propertyType === 'Stats' && ` of ${property.maxValue}`
                    }
                  </div>
                </div>
              )
            }
          </div>
        )
      }
      <CloseCircleOutlined
        className="absolute top-3 right-3 text-lg text-red-700 cursor-pointer"
        onClick={ () => onRemove(index) }
      />
    </div>
  );
}
