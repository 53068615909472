import { RequestWrapper } from '../../../helpers/RequestWrapper';
import { LoginRequestType } from '../../../models/Auth';
import API from '..';

const AUTH_ENDPOINT = 'auth';

class AuthAPI {
  static Login = class {
    static post = (credentials: LoginRequestType , errorHandler: (error: Error) => void) => {
      return RequestWrapper(
        async () => (await API.post(`${AUTH_ENDPOINT}/login`, credentials)).data, errorHandler
      );
    };

    static getProviderUrl = (provider: string , errorHandler: (error: Error) => void) => {
      return RequestWrapper(
        async () => (await API.get(`${AUTH_ENDPOINT}/${provider}/redirect`)).data, errorHandler
      );
    };

    static authProvider = (provider: string, search: string , errorHandler: (error: Error) => void) => {
      return RequestWrapper(
        async () => (await API.get(`${AUTH_ENDPOINT}/${provider}/callback${search}`)).data, errorHandler
      );
    };
  };

  static logout = class {
    static post = (errorHandler: (error: Error) => void) => {
      return RequestWrapper(
        async () => 
          (await API.post(`${AUTH_ENDPOINT}/logout`)).data, errorHandler
      );
    };
  };

  static challenge = class {
    static post = (challengeToken: string, twoFactorCode: string | null, recoveryCode: string | null, errorHandler: (error: Error) => void) => {
      return RequestWrapper(
        async () => 
          (await API.post(`${AUTH_ENDPOINT}/challenge`, {
            challenge_token: challengeToken,
            two_factor_code: twoFactorCode,
            recovery_code: recoveryCode
          })).data, errorHandler
      );
    };
  };
}

export { AUTH_ENDPOINT, AuthAPI };
