import {
  CheckCircleTwoTone,
  DeleteOutlined, FileTextOutlined, FrownTwoTone } from '@ant-design/icons';
import { Button, Col, Divider, Input, Popover, Row, Space,Table } from 'antd';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { UsersAPI } from '../../app/api/endpoints/Users';
import { useCan } from '../../app/can';
import ShowDate from '../../components/Common/ShowDate';
import HeaderTitle from '../../components/Parcial/HeaderTitle';
import { confirmAlert, failedAlert, handleError, successAlert } from '../../helpers/Utils';
import { User } from '../../models/User';

const { Search } = Input;


const Users: React.FC = (): JSX.Element =>{
  const can = useCan();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [search, setSearch] = useState<string|undefined>(undefined);
  const [searchLoading, setSearchLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 1,
    total: 1
  });

  const columns = [
    {
      title: 'Full name',
      dataIndex: 'name',
      sorter: true,
      render: (name: string, record: { id: React.Key }) => (
        <>
          {
            can('read', 'user') ?
              <NavLink
                to={ `/users/${record.id}/show` }
              >
                { name }
              </NavLink>
              :
              <span>{ name }</span>
          }
        </>
      )
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (email: string, record: { id: React.Key, email_verified_at: string }) => (
        <>
          { email }
          {
            record.email_verified_at ?
              <Popover content="Email verified">
                <CheckCircleTwoTone style={{ marginLeft: 5 }} twoToneColor="#52c41a"  key={ 0 }/>
              </Popover>
              :
              <Popover content="Email not verified">
                <FrownTwoTone style={{ marginLeft: 5 }} twoToneColor="red" key={ 1 }/>
              </Popover>
          }
        </>
      ),
    },
    {
      title: 'Username',
      dataIndex: 'username'
    },
    {
      title: 'Creation date',
      dataIndex: 'created_at',
      sorter: true,
      render: (created_at: Date) => (
        <ShowDate date={ created_at }/>
      )
    },
    {
      title: 'Actions',
      className: 'text-right',
      render: (record: { id: React.Key }) => (
        can('update', 'user') &&
          <div style={{ width: '100%', display: 'flex', justifyContent: 'end', gap: 5 }}>
            <NavLink
              to={ `/users/${record.id}/show` }
            >
              <Button type="primary" shape="circle" icon={ <FileTextOutlined /> } />
            </NavLink>
          </div>
      )
    },
  ];

  useEffect(() => {
    getUsers({ pagination });
  }, []);

  const getParams = (params: any) => ({
    query: params.query,
    page: params.pagination?.current,
    sort_field: params.sortField,
    sort_order: params.sortOrder,
    ...params.filters
  });

  const onSelectChange = (selectedRowKeys: string[])  => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const searchUser = async (query: string) => {
    setSearchLoading(true);
    const { result } = await UsersAPI.getAllUsers(`?query=${query}`, (error) => {
      handleError(error);
      setSearchLoading(false);
    });

    if (result) {
      if (result.status) {
        setUsers(result.users.data);
        setPagination( {
          current: result.users.meta.current_page,
          pageSize: result.users.meta.per_page,
          total: result.users.meta.total
        });
      } 
      setSearchLoading(false);
    }
  };

  const getUsers = async (params = {}) => {
    setLoading(true);
    const { result } = await UsersAPI.getAllUsers(`?${qs.stringify(getParams(params), { skipNulls: true })}`, (error) => {
      handleError(error);
      setLoading(false);
    });

    if (result) {
      if (result.status) {
        setUsers(result.users.data);
        setPagination( {
          current: result.users.meta.current_page,
          pageSize: result.users.meta.per_page,
          total: result.users.meta.total
        });
      } 
      setLoading(false);
    }
  };

  const handleTableChange = (
    newPagination: any,
    filters: any,
    sorter: any,
  ) => {
    getUsers({
      query: search,
      sortField: sorter.field as string,
      sortOrder: sorter.order as string,
      pagination: newPagination,
      filters: filters,
    });
  };

  const destroy = async () => {
    confirmAlert().then(async (res) => {
      if (res) {
        const { result } = await UsersAPI.destroy(selectedRowKeys,  (error) => {
          handleError(error);
        });
        if (result) {
          if (result.status) {
            setUsers(users.filter((user: User) => !selectedRowKeys.includes(user.id as string)));
            setSelectedRowKeys([]);
            successAlert('Deleted successfully.');
          } else {
            failedAlert(result.message);
          }
        }
      }
    });
  };

  return (
    <div>
      <HeaderTitle
        onBack={ () => history.back() }
        title="List of users"
      />
      <Divider />
      {
        can('read', 'user') &&
        <div>
          <Row>
            <Col span={ 12 }>
              <Search
                placeholder="Search by name or email..."
                value={ search }
                loading={ searchLoading }
                enterButton
                allowClear
                onChange={ (e) => setSearch(e.target.value) }
                onSearch={ (e) => searchUser(e) }
              />
            </Col>
          </Row>
          <Divider />
          {
            selectedRowKeys.length>0 &&
            <Row className="m-b-15">
              <Col span={ 12 }>
                { selectedRowKeys.length } ligne{ selectedRowKeys.length>1?'s':'' }
              </Col>
              <Col span={ 12 } style={{ textAlign: 'right' }}>
                <Space>
                  {
                    can('delete', 'user') &&
                    <>
                      <Button
                        type="primary"
                        danger
                        icon={ <DeleteOutlined /> }
                        onClick={ destroy }
                      >
                        Delete
                      </Button>
                    </>
                  }
                </Space>
              </Col>
            </Row>
          }

          <Table
            loading={ loading }
            rowKey={ user => user.id }
            // @ts-ignore
            rowSelection={ rowSelection }
            columns={ columns }
            // @ts-ignore
            dataSource={ users }
            pagination={ pagination }
            size="small"
            onChange={ handleTableChange }
          />
        </div>
      }
    </div>
  );
};

export default Users;
