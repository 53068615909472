import {
  PlusOutlined, SearchOutlined, SyncOutlined
} from '@ant-design/icons';
import { Button, Divider, Empty, Input, Spin } from 'antd';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

import { CollectionsAPI } from '../../app/api/endpoints/Collections';
import { useCan } from '../../app/can';
import CollectionCard from '../../components/Collections/CollectionCard';
import HeaderTitle from '../../components/Parcial/HeaderTitle';
import { handleError } from '../../helpers/Utils';
import { LocalCollection } from '../../models/Collection';

export default function Collections() {
  const can = useCan();
  const navigate = useNavigate();
  const { projectId } = useParams<{projectId: string}>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSync, setLoadingSync] = useState<boolean>(false);
  const [collections, setCollections] = useState([]);
  const [filteredCollections, setFilteredCollections] = useState([]);

  useEffect(() => {
    if(projectId) {
      getCollections();
    }
  }, [ projectId ]);

  const getCollections = async () => {
    setLoading(true);
    const { result } = await CollectionsAPI.getAllCollections(projectId, (error) => {
      handleError(error);
      setLoading(false);
    });

    if (result) {
      if (result.status) {
        setCollections(result.collections);
        setFilteredCollections(result.collections);
      } 
      setLoading(false);
    }
  };

  const syncCollections = async () => {
    setLoadingSync(true);
    const { result } = await CollectionsAPI.syncCollections(projectId, (error) => {
      setLoadingSync(false);
      handleError(error);
    });

    if (result) {
      setLoadingSync(false);
      if (result.status) {
        getCollections();
      }
    }
  };

  const searchCollection = (e: ChangeEvent<HTMLInputElement>) => {
    if(e.target.value === '') {
      setFilteredCollections(collections);
    } else {
      setFilteredCollections(collections.filter((item: LocalCollection) => {
        return item.collection_name.toLowerCase().includes(e.target.value.toLowerCase());
      }));
    }
  };

  return (
    <>
      <div>
        <HeaderTitle
          onBack={ () => navigate(`/project/${projectId}`) }
          title="Collections"
        />
        <Divider />
        <div className="flex mb-4">
          {
            can('read', 'collection') && can('create', 'collection') &&
            <Button
              loading={ loadingSync }
              onClick={ syncCollections }
              className="!text-white !bg-green-700 hover:bg-green-900"
              size="large"
            >
              { !loadingSync && <SyncOutlined className="pr-2"/> }
              Sync Collections from Fever App
            </Button>
          }
        </div>
        <div className="flex pb-4 flex-col sm:flex-row">
          <div className="mx-auto w-full sm:w-full flex items-center justify-start mr-4">
            <Input size="large" placeholder="Search collections..." suffix={ <SearchOutlined /> } onChange={ (e) => searchCollection(e) } />
          </div>
          {
            can('create', 'collection') &&
            <div className="flex w-full sm:w-auto my-2 justify-center">
              <div className="">
                <NavLink to={ `/project/${projectId}/collections/create` }>
                  <div className="h-9 px-12 flex justify-center items-center text-sm font-medium rounded-md text-white bg-indigo-700 hover:bg-indigo-900 cursor-pointer">
                    <PlusOutlined className="pr-2"/>
                    <span className="w-[100px]">New Collection</span>
                  </div>
                </NavLink>
              </div>
            </div>
          }
        </div>
        {
          can('read', 'collection') &&
          <div className="w-full flex flex-col justify-center items-center">
            <Spin tip="Loading..." spinning={ loading } wrapperClassName="w-full">
              {
                filteredCollections.length > 0 ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:gride-cols-2 gap-4 w-full mb-10  ">
                    {
                      filteredCollections.map((collection: LocalCollection) => (
                        <CollectionCard
                          key={ collection.collection_id }
                          collection={ collection }
                          projectId={ projectId }
                        />
                      ))
                    }
                  </div>
                ) : (
                  <Empty />
                )
              }
            </Spin>
          </div>
        }
      </div>
    </>
  );
}
