import { SaveOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Modal, Row, Switch } from 'antd';
import React, { ChangeEvent, FormEvent, useState } from 'react';

import { CategoriesAPI } from '../../../app/api/endpoints/Categories';
import { failedAlert, format422Error, handleError, slugify,successAlert } from '../../../helpers/Utils';
import { Category } from '../../../models/Blog';


interface CreateCategoryProps {
  showCreateCategoryModal: boolean;
  setShowCreateCategoryModal: (arg: boolean) => void;
  onCategoryCreated: (arg: Category) => void;
}
const CreateCategory = ({
  showCreateCategoryModal, setShowCreateCategoryModal, onCategoryCreated
}: CreateCategoryProps): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({} as Category);
  const [inputs, setInputs] = useState({
    name: '',
    slug: '',
    description: '',
    status: true
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.name === 'name') {
      setInputs(
        {
          ...inputs,
          name: e.target.value,
          slug: slugify(e.target.value),
        }
      );
    } else {
      setInputs({ ...inputs, [e.target.name]: e.target.value });
    }
  };

  const handleCloseCreateCategoryModal = () => {
    setShowCreateCategoryModal(false);
  };

  const saveCategory = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setValidationErrors({} as Category);
    const { result } = await CategoriesAPI.createCategory(inputs ,(error) => {
      setLoading(false);
      if(error && error?.response && error.response.status === 422) {
        // @ts-ignore
        setValidationErrors(format422Error(error.response.data?.errors));
      } else {
        handleError(error);  
      }
    });

    if (result) {
      setLoading(false);
      if (result.status) {
        onCategoryCreated(result.category);
        setShowCreateCategoryModal(false);
        successAlert('Saved successfully.');
        setInputs({ name: '', slug: '', description: '', status: true });
      } else {
        failedAlert(result.message);
      }
    }
  };

  return (
    <div>
      <Modal
        title="Create new category"
        open={ showCreateCategoryModal }
        footer={ null }
        onCancel={ handleCloseCreateCategoryModal }
      >
        <Divider/>
        <Form
          layout="vertical"
        >
          <Row gutter={ [10, 0] }>
            <Col span={ 12 }>
              <Form.Item
                label="Category name:"
                required
              >
                <Input
                  value={ inputs.name }
                  name="name"
                  onChange={ handleChange }
                />
                {
                  validationErrors && validationErrors.name && (
                    <span className="text-red-500">{ validationErrors.name }</span>
                  )
                }
              </Form.Item>
            </Col>
            <Col span={ 12 }>
              <Form.Item
                label="Slug:"
                required
              >
                <Input
                  value={ inputs.slug }
                  name="slug"
                  onChange={ handleChange }
                />
                {
                  validationErrors && validationErrors.slug && (
                    <span className="text-red-500">{ validationErrors.slug }</span>
                  )
                }
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Description:">
            <Input.TextArea
              value={ inputs.description }
              name="description"
              onChange={ handleChange }
            />
            {
              validationErrors && validationErrors.description && (
                <span className="text-red-500">{ validationErrors.description }</span>
              )
            }
          </Form.Item>
          <Form.Item
            label="Status:"
            rules={ [ { required: true } ] }
          >
            <Switch
              className="bg-gray-400 hover:opacity-70"
              checkedChildren="Published"
              unCheckedChildren="Unpublished"
              onChange={ (e) => setInputs({ ...inputs, status: e }) }
              checked={ inputs.status }
            />
            {
              validationErrors && validationErrors.status && (
                <span className="text-red-500">{ validationErrors.status }</span>
              )
            }
          </Form.Item>
          <Form.Item className="flex justify-end mb-0 border-t pt-4">
            <Button
              type="primary"
              onClick={ (e) => saveCategory(e) }
              loading={ loading }
              size="large"
              icon={ <SaveOutlined /> }
            >
                Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default CreateCategory;
