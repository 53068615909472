import { Modal } from 'antd';
import toast from 'react-hot-toast';

const { confirm } = Modal;

export const arrayToObject = (array: Array<{ propertyName: string, propertyValue: string }>) => {
  return array.reduce(function(obj: { [key:string]: string } , item){
    if(item.propertyName && item.propertyName !== '') {
      obj[item.propertyName] = item.propertyValue;
    } 
    return obj;
  }, {});
};

export const confirmAlert = () => {
  return new Promise((resolve, reject) => {
    confirm({
      title: 'Are you sure?',
      content: 'You will not be able to revert it!',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        resolve(true);
      },
      onCancel() {
        reject(false);
      },
    });
  });
};

export const errorAlert = (content: string) => {
  toast.error(content);
};

export const failedAlert = (content: string) => {
  toast.error(content);
};

export const format422Error = (errors: any) => {
  return errors.map((err: any) => {
    return {
      [err.field]: err.message
    };
  }).reduce(function(result: any, item: any) {
    const key = Object.keys(item)[0];
    result[key] = item[key];
    return result;
  }, {});
};

export const formatFileSize = (fileSizeInBytes: number) => {
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB'];

  let size = fileSizeInBytes;
  let unitIndex = 0;

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }

  return `${size.toFixed(2)} ${units[unitIndex]}`;
};

export const genPassword = (len: number) => {

  const letters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ123456789';
  let password = '';

  for (let i = 0; i < len; i++) {
    const generate = letters[Math.floor(Math.random() * letters.length)];
    password += generate;
  }
  return password;
};

export const handleError = (error: any) => {
  if (typeof error === 'object' && error.response && error.response.status) {
    switch (error.response.status) {
    case 401:
      failedAlert('Unauthorized');
      break;
    case 403:
      failedAlert(error.response.data.message);
      break;
    case 404:
      failedAlert('Endpoint not found');
      break;
    case 405:
      failedAlert('Method Not Allowed');
      break;
    case 500:
      failedAlert('Internal server Error');
      break;
    default:
      wrongAlert();
      break;
    }
  } else {
    failedAlert(error.message);
  }
};

export const resolveIpfsUrl = (ipfsUrl: string | undefined) => {
  if(ipfsUrl && ipfsUrl.startsWith('ipfs/')) {
    return ipfsUrl.replace('ipfs/', 'https://gateway.ipfscdn.io/ipfs/');
  }
  return undefined;
};

export const shortenAddress = (address: string): string => {
  if (address.length < 10) return address; // Check if the address is too short

  const start = address.slice(0, 6); // Taking the first 6 characters
  const end = address.slice(-6); // Taking the last 6 characters

  return `${start}...${end}`;
};

export const slugify = (text: string) => {
  const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;';
  const to = 'aaaaaeeeeeiiiiooooouuuunc------';

  const newText = text.split('').map(
    (letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)));

  return newText
    .toString()                     // Cast to string
    .toLowerCase()                  // Convert the string to lowercase letters
    .trim()                         // Remove whitespace from both sides of a string
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/&/g, '-y-')           // Replace & with 'and'
    .replace(/[^\w-]+/g, '')       // Remove all non-word chars
    .replace(/--+/g, '-');        // Replace multiple - with single -
};

export const successAlert = (content: string) => {
  return toast.success(content);
};

export const validateImage = (file: File) => {
  const allowedExtensions = ['jpg', 'jpeg', 'png'];
  const maxSizeInBytes = 4 * 1024 * 1024; // 4MB

  // Check file extension
  const fileNameParts = file.name.split('.');
  const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();
  if (!allowedExtensions.includes(fileExtension)) {
    return {
      valid: false,
      error: 'Invalid file extension. Only JPG, JPEG, and PNG files are allowed.',
    };
  }

  // Check file size
  if (file.size > maxSizeInBytes) {
    return {
      valid: false,
      error: 'File size exceeds the limit of 4MB.',
    };
  }

  return { valid: true };
};

export const validateImageDimensions = (file: File, minWidth: number, minHeight: number) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = function() {
      const width = img.width;
      const height = img.height;
      
      if (width >= minWidth && height >= minHeight) {
        resolve(true);
      } else {
        resolve(false);
      }
    };
    
    img.onerror = function() {
      resolve(false);
    };
    img.src = URL.createObjectURL(file);
  });
};

export const wrongAlert = () => {
  toast.error('Something went wrong please try again.');
};
