import { AxiosError } from 'axios';

import { RequestWrapper } from '../../../helpers/RequestWrapper';
import API from '..';

const ROLES_ENDPOINT = 'dash/roles';

class RolesAPI {
  static getAllRoles = (qs: string, errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.get(`${ROLES_ENDPOINT}${qs}`)).data, errorHandler
    );
  };

  static getRole = (id: string | undefined, errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.get(`${ROLES_ENDPOINT}/${id}/edit`)).data, errorHandler
    );
  };
  static getAllPermissions = (errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.get(`${ROLES_ENDPOINT}/permissions`)).data, errorHandler
    );
  };

  static store = (data: any, errorHandler: (error: AxiosError) => void) => {
    return RequestWrapper(
      async () => 
        (await API.post(`${ROLES_ENDPOINT}/store`, data)).data, errorHandler
    );
  };

  static update = (id: string, data: any, errorHandler: (error: AxiosError) => void) => {
    return RequestWrapper(
      async () => 
        (await API.post(`${ROLES_ENDPOINT}/${id}/update`, data)).data, errorHandler
    );
  };

  static destroy = (ids: number[], errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.post(`${ROLES_ENDPOINT}/destroy`, { ids: ids })).data, errorHandler
    );
  };
}

export { ROLES_ENDPOINT, RolesAPI };
