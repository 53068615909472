import { DownOutlined, LockOutlined,LogoutOutlined } from '@ant-design/icons';
import { Dropdown, Layout, MenuProps, Space } from 'antd';
import React, { useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { NavLink, Outlet } from 'react-router-dom';

import LogoWhitte from '../../assets/img/logo_white.svg';
import { useAuth } from '../../providers/auth/AuthProvider';
import GoallIcon from '../Common/GoallIcon';
import SideBar from './SideBar';

const { Header, Content, Sider, Footer } = Layout;

export default function Index() {
  const { profile, logout } = useAuth();
  const [collapsed, setCollapsed] = useState(false);

  const items: MenuProps['items'] = [
    {
      key: '1',
      icon: <LockOutlined />,
      label: (<NavLink to="/profile/password">Changer mot de passe</NavLink>)
    },
    {
      key: '2',
      type: 'divider',
    },
    {
      key: '3',
      icon: <LogoutOutlined />,
      label: (<span className="d-block" onClick={ logout }>Logout</span>),
    },
  ];

  return (
    <div className="wrapper">
      <Layout>
        <Header className="header">
          <div
            className="logo flex justify-center items-center"
            style={{ width: collapsed ? 79 : 239 }}
          >
            {
              collapsed ?
                <GoallIcon style={{ fill: 'white', width: 38, height: 60 }} /> :
                <img src={ LogoWhitte } style={{ width: 170 }} />
            }
          </div>
          <div className="logout">
            <Space style={{ color: '#fff', fontWeight: 'bold' }}>
              <Dropdown menu={{ items }} placement="bottomRight">
                <a onClick={ (e) => e.preventDefault() }>
                  <Space>
                    { profile?.name }
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </Space>
          </div>
        </Header>
        <Layout className="site-layout">
          <Sider
            collapsible
            collapsed={ collapsed }
            onCollapse={ (value) => setCollapsed(value) }
            width={ 240 } className="site-layout-background"
          >
            <SideBar />
          </Sider>
          <Layout style={{ padding: '0 24px', marginTop: '30px' }}>
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 'calc(100vh - 160px)'
              }}
            >
              <Outlet/>
            </Content>
            <Footer style={{ textAlign: 'center' }}>
                Copyright &copy; { new Date().getFullYear() } [Goall]
            </Footer>
          </Layout>
        </Layout>
      </Layout>
      <Toaster
        toastOptions={{
          style: {
            boxShadow: 'rgb(227, 233, 243) 0px 2px 4px 0px',
            backgroundColor: 'rgb(255, 255, 255)',
            borderRadius: 0,
            borderTopRightRadius: '2px',
            borderBottomRightRadius: '2px',
            maxWidth: '500px',
            minHeight: '60px'
          },
          success: {
            style: {
              borderLeft: '2px solid #61d345',
            },
          },
          error: {
            style: {
              borderLeft: '2px solid #ff4b4b',
            },
          },
          duration: 4000,
        }}
        position="top-center"
      />
    </div>
  );
};
