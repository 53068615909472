import React from 'react';
import { NavLink } from 'react-router-dom';

import GoalLogo from '../../assets/img/logo_icon.svg';
import ShowDate from '../../components/Common/ShowDate';
import { LocalProject } from '../../models/Project';
;

interface ProjectCardProps {
  project: LocalProject,
  onProjectDeleted: (arg: string) => void
}

export default function ProjectCard ({ project }: ProjectCardProps) {
  
  return (
    <NavLink to={ `/project/${project.project_id}` }>
      <div className="w-full flex flex-col bg-white rounded-md border p-2 min-w-[300px] ">
        <div className="text-xl flex rounded-md p-2 space-x-2 items-center leading-1 min-h-[80px]  ">
          <img src={ GoalLogo } alt="logo" className="h-8 w-8 object-contain rounded-full"/>
          <div className="flex flex-col ">
            <p className="truncate w-[220px]"> { project.project_name } </p>
          </div>
        </div>
        <div className="w-full p-2 flex flex-row justify-between ">
          <div className="text-gray-500">
            <span><ShowDate date={ project.updated_at } fromNow /></span>
          </div>
        </div>
      </div>
    </NavLink>
  );
}
