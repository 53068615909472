import { SaveOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Row, Switch } from 'antd';
import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CollectionsAPI } from '../../app/api/endpoints/Collections';
import { useCan } from '../../app/can';
import CollectionUpload, { UplodedFile } from '../../components/Common/CollectionUpload';
import HeaderTitle from '../../components/Parcial/HeaderTitle';
import { failedAlert, format422Error, handleError, successAlert } from '../../helpers/Utils';
import { Collection } from '../../models/Collection';

export default function CreateCollection() {
  const can = useCan();
  const navigate = useNavigate();
  const { projectId } = useParams<{projectId: string}>();
  const { clubId } = useParams<{clubId: string}>();
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({} as any);
  const [logoFile, setLogoFile] = useState({
    blobUrl: '',
    fileName: ''
  });
  const [bannerFile, setBannerFile] = useState({
    blobUrl: '',
    fileName: ''
  });
  const [inputs, setInputs] = useState({
    collectionName: '',
    collectionPrice: '',
    collectionExternalLink: '',
    isSBT: false,
    enableWhitelistPerToken: false,
    enableMaxSupplyPerWallet: false,
    collectionDescription: '',
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const onSwitchChange = (checked: boolean, event: any) => {
    setInputs({ ...inputs, [event.target.parentNode.id]: checked });
  };

  const onLogoFileUploded = (file: UplodedFile) => {
    setLogoFile({
      blobUrl: file.blobUrl,
      fileName: file.fileName
    });
  };

  const onBannerFileUploded = (file: UplodedFile) => {
    setBannerFile({
      blobUrl: file.blobUrl,
      fileName: file.fileName
    });
  };

  const saveCollection = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setValidationErrors({} as Collection);
    const data = {
      ...inputs,
      collectionLogo: logoFile,
      collectionBanner: bannerFile,
    };
    const { result } = await CollectionsAPI.createCollection(projectId, clubId, data,
      (error) => {
        if(error && error?.response && error.response.status === 422) {
          // @ts-ignore
          setValidationErrors(format422Error(error.response.data?.errors));
        } else {
          handleError(error);  
        }
        setLoading(false);
      });
    
    if (result) {
      if (result.status) {
        successAlert('Saved successfully.');
        navigate(`/project/${projectId}/clubs/${clubId}?tab=collections`);
      } else {
        failedAlert(result.message);
      }
      setLoading(false);
    }
  };

  return (
    <div>
      <HeaderTitle
        onBack={ () => navigate(`/project/${projectId}/clubs/${clubId}?tab=collections`) }
        title="Create a new collection"
      />
      <Divider />
      {
        can('create', 'collection') &&
        <Form
          layout="vertical"
        >
          <Row gutter={ 24 }>
            <Col span={ 8 }>
              <Form.Item
                label="Collection Name:"
                name="collectionName"
                rules={ [ { required: true } ] }
              >
                <Input
                  value={ inputs.collectionName }
                  name="collectionName"
                  size="large"
                  onChange={ (e) => handleInputChange(e) }
                />
                {
                  validationErrors && validationErrors.collectionName && (
                    <span className="ant-form-item-explain-error">{ validationErrors.collectionName }</span>
                  )
                }
              </Form.Item>
            </Col>
            <Col span={ 8 }>
              <Form.Item
                label="Collection Price:"
                name="collectionPrice"
                rules={ [ { required: true } ] }
              >
                <Input
                  value={ inputs.collectionPrice }
                  name="collectionPrice"
                  size="large"
                  onChange={ (e) => handleInputChange(e) }
                />
                {
                  validationErrors && validationErrors.collectionPrice && (
                    <span className="ant-form-item-explain-error">{ validationErrors.collectionPrice }</span>
                  )
                }
              </Form.Item>
            </Col>
            <Col span={ 8 }>
              <Form.Item
                label="External Link:"
              >
                <Input
                  value={ inputs.collectionExternalLink }
                  name="collectionExternalLink"
                  size="large"
                  onChange={ (e) => handleInputChange(e) }
                />
                {
                  validationErrors && validationErrors.collectionExternalLink && (
                    <span className="ant-form-item-explain-error">{ validationErrors.collectionExternalLink }</span>
                  )
                }
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={ 24 } className="mb-4">
            <Col span={ 8 }>
              <div className="flex gap-4">
                <Switch
                  id="enableWhitelistPerToken"
                  className="bg-[rgba(0,0,0,.25)] hover:bg-[rgba(0,0,0,.45)]"
                  defaultChecked={ inputs.enableWhitelistPerToken }
                  onChange={ onSwitchChange }
                />
                <span>Enable Whitelist Per Token</span>
              </div>
              {
                validationErrors && validationErrors.enableWhitelistPerToken && (
                  <span className="ant-form-item-explain-error">{ validationErrors.enableWhitelistPerToken }</span>
                )
              }
            </Col>
            <Col span={ 8 }>
              <div className="flex gap-4">
                <Switch
                  id="enableMaxSupplyPerWallet"
                  className="bg-[rgba(0,0,0,.25)] hover:bg-[rgba(0,0,0,.45)]"
                  defaultChecked={ inputs.enableMaxSupplyPerWallet }
                  onChange={ onSwitchChange }
                />
                <span>Enable Max Supply Per Wallet</span>
              </div>
              {
                validationErrors && validationErrors.enableMaxSupplyPerWallet && (
                  <span className="ant-form-item-explain-error">{ validationErrors.enableMaxSupplyPerWallet }</span>
                )
              }
            </Col>
            <Col span={ 8 }>
              <div className="flex gap-4">
                <Switch
                  id="isSBT"
                  className="bg-[rgba(0,0,0,.25)] hover:bg-[rgba(0,0,0,.45)]"
                  defaultChecked={ inputs.isSBT }
                  onChange={ onSwitchChange }
                />
                <span>Enable SBT(SoulBound Token)</span>
              </div>
              {
                validationErrors && validationErrors.isSBT && (
                  <span className="ant-form-item-explain-error">{ validationErrors.isSBT }</span>
                )
              }
            </Col>
          </Row>
          <Row gutter={ 24 }>
            <Col span={ 14 }>
              <Form.Item
                label="Collection Description:"
              >
                <Input.TextArea
                  rows={ 4 }
                  value={ inputs.collectionDescription }
                  name="collectionDescription"
                  onChange={ (e) => handleInputChange(e) }
                />
                {
                  validationErrors && validationErrors.collectionDescription && (
                    <span className="ant-form-item-explain-error">{ validationErrors.collectionDescription }</span>
                  )
                }
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={ 24 }>
            <Col span={ 12 }>
              <Form.Item
                label="Logo Image 150 x 150"
                required
              >
                <CollectionUpload
                  onFileUplodSucceed={ onLogoFileUploded }
                />
              </Form.Item>
            </Col>
            <Col span={ 12 }>
              <Form.Item
                label="Banner Image 2000 x 1152"
              >
                <CollectionUpload
                  onFileUplodSucceed={ onBannerFileUploded }
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button
              type="primary"
              onClick={ (e) => saveCollection(e) }
              loading={ loading }
              size="large"
              icon={ <SaveOutlined /> }
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      }
    </div>
  );
}
