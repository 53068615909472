import { Alert, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthAPI } from '../../app/api/endpoints/Auth';
import Logo from '../../assets/img/logo.svg';
import { useAuth } from '../../providers/auth/AuthProvider';

export default function GoogleProvider() {
  const navigate = useNavigate();
  const { isConnected, postLogin } = useAuth();
  const [loading , setLoading] = useState<boolean>(false);
  const [error , setError] = useState<string>('');
  const [ search ] = useState<string>(window.location.search);

  useEffect(() => {
    if(search){
      authUserByProvider('google', search);
    }
  }, [ search ]);

  const authUserByProvider = async (provider: string, search: string) => {
    setLoading(true);
    const { result } = await AuthAPI.Login.authProvider(provider, search, () => {
      setLoading(false);
      setError('Login error, please try again.');
    });

    if (result) {
      setLoading(false);
      if (result.status) {
        postLogin(result.token);
      } else {
        setError('Login error, please try again.');
      }
    }
  };


  useEffect(() => {
    isConnected && navigate('/account');
  }, [ isConnected ]);

  return (
    <div className="login-page">
      <div className="login-box ">
        <div className="header">
          <div className="flex justify-center">
            <img src={ Logo } style={{ width: 200 }} alt="Goall" />
          </div>
          <p className="mb-6">
            Login to start your session
          </p>
        </div>
        <div className="flex justify-center items-center">
          {
            loading ? <Spin /> :
              <>
                {
                  error && 
                  <Alert
                    message="Error"
                    description={ error }
                    type="error"
                    showIcon
                  />
                }
              </>
          }
        </div>
      </div>
    </div>
  );
}
