import { PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Row, Spin, Switch } from 'antd';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ItemsAPI } from '../../app/api/endpoints/Items';
import { useCan } from '../../app/can';
import ItemImageUpload from '../../components/Items/ItemImageUpload';
import ItemProperty, { PropertyType } from '../../components/Items/ItemProperty';
import ItemPropertyCard from '../../components/Items/ItemPropertyCard';
import HeaderTitle from '../../components/Parcial/HeaderTitle';
import { errorAlert, failedAlert, format422Error, handleError, successAlert } from '../../helpers/Utils';
import { Item } from '../../models/Item';

export default function EditItem() {
  const can = useCan();

  const navigate = useNavigate();
  const { projectId } = useParams<{projectId: string}>();
  const { clubId } = useParams<{clubId: string}>();
  const { collectionId } = useParams<{collectionId: string}>();
  const { itemId } = useParams<{itemId: string}>();
  const [loading, setLoading] = useState(false);
  const [loadingItem, setLoadingItem] = useState(false);
  const [item, setItem] = useState<Item>({} as Item);
  const [validationErrors, setValidationErrors] = useState({} as Item);
  const [itemImage, setItemImage] = useState('');
  const [inputs, setInputs] = useState({
    itemName: '',
    itemPrice: '',
    itemExternalLink: '',
    maxSupply: 1,
    maxPerSale: 1,
    withoutWhitelist: false,
    itemDescription: ''
  });
  const [showItemPropertyModal, setShowItemPropertyModal] = useState(false);
  const [properties, setProperties] =  useState<PropertyType[]>([]);

  useEffect(() => {
    if (itemId) {
      getItem();
    }
  }, [ itemId ]);

  const getItem = async () => {
    setLoadingItem(true);
    const { result } = await ItemsAPI.getItem(collectionId, itemId, (error) => {
      handleError(error);
      setLoadingItem(false);
    });

    if (result) {
      if (result.status) {
        setItem(result.item);
      } else {
        failedAlert(result.message);
        navigate(`/project/${projectId}/club/${clubId}/collections/${collectionId}?tab=items`);
      }
      setLoadingItem(false);
    }
  };

  useEffect(() => {
    if (item) {
      setInputs({
        itemName: item.itemName,
        itemPrice: item?.itemPrice?.toString(),
        itemExternalLink: item.itemExternalLink,
        maxSupply: item.maxSupply,
        maxPerSale: item.maxPerSale,
        withoutWhitelist: item.withoutWhitelist,
        itemDescription: item.itemDescription
      });
      setItemImage(item.itemImage);
      setProperties(item.itemProperties);
    }
  }, [ item ]);

  const handleShowItemPropertyModal = () => {
    setShowItemPropertyModal(true);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const onSwitchChange = (checked: boolean, event: any) => {
    setInputs({ ...inputs, [event.target.parentNode.id]: checked });
  };

  const onItemImageUploded = (blobUrl: string) => {
    setItemImage(blobUrl);
  };

  const updateItem = async (e: FormEvent) => {
    e.preventDefault();
    setValidationErrors({} as Item);
    if (inputs.maxPerSale <= inputs.maxSupply) {
      setLoading(true);
      const data = {
        ...inputs,
        itemImage: itemImage,
        itemProperties: properties
      };
      const { result } = await ItemsAPI.updateItem(collectionId, itemId, data,
        (error) => {
          setLoading(false);
          if(error && error?.response && error.response.status === 422) {
            // @ts-ignore
            setValidationErrors(format422Error(error.response.data?.errors));
          } else {
            handleError(error);  
          }
        });
      
      if (result) {
        if (result.status) {
          setLoading(false);
          successAlert('Saved successfully.');
          navigate(`/project/${projectId}/club/${clubId}/collections/${collectionId}?tab=items`);
        } else {
          failedAlert(result.message);
        }
      }
    } else {
      errorAlert('Maximum sales per wallet must be less than Number of replicates.');
    }
  };

  const addProperty = (property: PropertyType) => {
    setProperties([
      ...properties,
      property
    ]);
  };

  const removeProperty = (index: number) => {
    const newProperties = [ ...properties ];
    newProperties.splice(index, 1);
    setProperties(newProperties);
  };

  return (
    <div>
      <HeaderTitle
        onBack={ () => navigate(`/project/${projectId}/club/${clubId}/collections/${collectionId}?tab=items`) }
        title="Update item"
      />
      <Divider />
      {
        can('update', 'item') &&
        <Spin tip="Loading item" spinning={ loadingItem }>
          <Form
            layout="vertical"
          >
            <Row gutter={ 24 }>
              <Col span={ 8 }>
                <Form.Item
                  label="Item Name:"
                  name="itemName"
                  rules={ [ { required: true } ] }
                >
                  <Input
                    value={ inputs.itemName }
                    name="itemName"
                    size="large"
                    onChange={ (e) => handleInputChange(e) }
                  />
                  {
                    validationErrors && validationErrors.itemName && (
                      <span className="ant-form-item-explain-error">{ validationErrors.itemName }</span>
                    )
                  }
                </Form.Item>
              </Col>
              <Col span={ 8 }>
                <Form.Item
                  label="Item Price:"
                  name="itemPrice"
                  rules={ [ { required: true } ] }
                >
                  <Input
                    value={ inputs.itemPrice }
                    name="itemPrice"
                    size="large"
                    onChange={ (e) => handleInputChange(e) }
                  />
                  {
                    validationErrors && validationErrors.itemPrice && (
                      <span className="ant-form-item-explain-error">{ validationErrors.itemPrice }</span>
                    )
                  }
                </Form.Item>
              </Col>
              <Col span={ 8 }>
                <Form.Item
                  label="External Link:"
                >
                  <Input
                    value={ inputs.itemExternalLink }
                    name="itemExternalLink"
                    size="large"
                    onChange={ (e) => handleInputChange(e) }
                  />
                  {
                    validationErrors && validationErrors.itemExternalLink && (
                      <span className="ant-form-item-explain-error">{ validationErrors.itemExternalLink }</span>
                    )
                  }
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={ 24 } className="mb-4">
              <Col span={ 8 }>
                <Form.Item
                  label="Number of replicates:"
                  required
                >
                  <Input
                    value={ inputs.maxSupply }
                    name="maxSupply"
                    size="large"
                    onChange={ (e) => handleInputChange(e) }
                  />
                  {
                    validationErrors && validationErrors.maxSupply && (
                      <span className="ant-form-item-explain-error">{ validationErrors.maxSupply }</span>
                    )
                  }
                </Form.Item>
              </Col>
              <Col span={ 8 }>
                <Form.Item
                  label="Maximum sales per wallet:"
                  required
                >
                  <Input
                    value={ inputs.maxPerSale }
                    name="maxPerSale"
                    size="large"
                    onChange={ (e) => handleInputChange(e) }
                  />
                  {
                    validationErrors && validationErrors.maxPerSale && (
                      <span className="ant-form-item-explain-error">{ validationErrors.maxPerSale }</span>
                    )
                  }
                </Form.Item>
              </Col>
              <Col span={ 8 }>
                <div className="flex gap-4">
                  <Switch
                    id="withoutWhitelist"
                    className="bg-[rgba(0,0,0,.25)] hover:bg-[rgba(0,0,0,.45)]"
                    defaultChecked={ inputs.withoutWhitelist }
                    onChange={ onSwitchChange }
                  />
                  <span>Item without Whitelist</span>
                </div>
                {
                  validationErrors && validationErrors.withoutWhitelist && (
                    <span className="ant-form-item-explain-error">{ validationErrors.withoutWhitelist }</span>
                  )
                }
              </Col>
            </Row>
            <Row gutter={ 24 }>
              <Col span={ 12 }>
                <Form.Item
                  label="Item Description:"
                >
                  <Input.TextArea
                    rows={ 4 }
                    value={ inputs.itemDescription }
                    name="itemDescription"
                    onChange={ (e) => handleInputChange(e) }
                  />
                  {
                    validationErrors && validationErrors.itemDescription && (
                      <span className="ant-form-item-explain-error">{ validationErrors.itemDescription }</span>
                    )
                  }
                </Form.Item>
              </Col>
              <Col span={ 12 }>
                <Form.Item
                  label="Upload Item"
                  required
                >
                  <ItemImageUpload
                    defaultFile={ itemImage }
                    onFileUplodSucceed={ onItemImageUploded }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={ 24 }>
              <Col span={ 24 }>
                <div className="space-y-5">
                  {
                    properties && properties.length > 0 ? (
                      <div className="w-full max-h-64 py-2 grid md:grid-cols-2 xl:grid-cols-3 gap-5 items-center overflow-y-scroll justify-center">
                        {
                          properties.map((property, index) => (
                            <ItemPropertyCard
                              key={ index }
                              property={ property }
                              index={ index }
                              onRemove={ (i) => removeProperty(i) }
                            />
                          ))
                        }
                      </div>
                    ) : (
                      <div className="flex gap-3 items-center justify-center text-ft-lt tracking-[.1rem]">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 " viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd"></path>
                        </svg>
                        <p>You haven&apos;t defined any properties yet.</p>
                      </div>
                    )
                  }
                </div>
                <ItemProperty
                  showItemPropertyModal={ showItemPropertyModal }
                  setShowItemPropertyModal={ setShowItemPropertyModal }
                  onPropertyAdded={ addProperty }
                />
                <div className="flex flex-col w-full justify-center items-center gap-y-2 my-5">
                  <button
                    type="button"
                    className="flex items-center border w-full md:w-auto rounded-lg px-10 md:px-6 lg:px-10 py-2 font-medium text-base hover:bg-gray-300"
                    onClick={ handleShowItemPropertyModal }
                  >
                    <PlusOutlined className="mr-2"/>
                    <span>Add new property</span>
                  </button>
                </div>
              </Col>
            </Row>
            <Form.Item>
              <Button
                type="primary"
                onClick={ (e) => updateItem(e) }
                loading={ loading }
                size="large"
                icon={ <SaveOutlined /> }
              >
              Save
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      }
    </div>
  );
}
