import React, { Fragment, ReactNode, useEffect, useState } from 'react';

export type TabsItems = {
  id: number;
  label: ReactNode | string;
  children?: ReactNode
}
interface TabsProps {
  activeKey: number;
  items: TabsItems[];
}

export default function Tabs({ activeKey, items }: TabsProps) {
  const [activeTab, setActiveTab] = useState<number>();
  
  useEffect(() => {
    setActiveTab(activeKey);
  }, [ activeKey ]);

  const onClickTab = (value: number) => {
    setActiveTab(value);
  };

  return (
    <>
      <div className="sm:hidden my-6">
        <label htmlFor="tabs" className="sr-only">Select a tab</label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-ft-lp focus:ring-ft-lp"
          onChange={ (e) => onClickTab(parseInt(e.target.value)) }
        >
          {
            items && items.map(item => (
              <option key={ item.id } value={ item.id }>
                { item.label }
              </option>
            ))
          }
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex" aria-label="Tabs">
            {
              items && items.map(item => (
                <div
                  key={ item.id }
                  className={ `${ activeTab !== item.id ? 'cursor-pointer border-transparent text-gray-500 hover:text-black hover:font-bold hover:border-gray-300' : 'text-indigo-900 border-indigo-900'} w-1/4 py-4 px-1 text-center border-b-2 font-bold text-sm` }
                  onClick={ () => activeTab !== item.id && onClickTab(item.id) }
                >
                  <span className="capitalize">{ item.label }</span>
                </div>
              ))
            }
          </nav>
        </div>
      </div>
      <div className="mt-12">
        {
          items && items.map(item => (
            activeTab === item.id && <Fragment key={ item.id }>{ item.children }</Fragment>
          ))
        }
      </div>
    </>
  );
};
