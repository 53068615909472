import 'react-quill/dist/quill.snow.css';

// @ts-ignore
import ImageResize from 'quill-image-resize-module-react';
import React from 'react';
import ReactQuill, { Quill, ReactQuillProps } from 'react-quill';

Quill.register('modules/imageResize', ImageResize);

const modules = {
  toolbar: [
    [ { 'size': [] } ],
    [ { 'header': [1, 2, 3, 4, 5, 6, false] } ],
    ['bold', 'italic', 'underline','strike', 'blockquote'],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    [ { 'align': [] } ],
    ['link', 'image'],
    [ 'clean' ],
  ],
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize', 'Toolbar']
  }
};

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image'
];


export default function QuillEditor({ value , onChange }: ReactQuillProps) {

  return (
    <ReactQuill
      className="h-[400px] mb-4"
      theme="snow"
      modules={ modules }
      formats={ formats }
      value={ value }
      onChange={ onChange }
    />
  );
}
