import { PlusOutlined,SearchOutlined } from '@ant-design/icons';
import { Divider, Empty, Input, Spin } from 'antd';
import React, { ChangeEvent, useEffect, useState } from 'react';

import { CategoriesAPI } from '../../../app/api/endpoints/Categories';
import { useCan } from '../../../app/can';
import CategoryCard from '../../../components/Cards/CategoryCard';
import HeaderTitle from '../../../components/Parcial/HeaderTitle';
import { handleError } from '../../../helpers/Utils';
import { Category } from '../../../models/Blog';
import CreateCategory from './CreateCategory';
import EditCategory from './EditCategory';


const Categories: React.FC = (): JSX.Element => {
  const can = useCan();
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState<Category[]>([]);
  const [category, setCategory] = useState<Category | undefined>();
  const [filteredCategories, setFilteredCategories] = useState<Category[]>([]);
  const [showCreateCategoryModal, setShowCreateCategoryModal] = useState(false);
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);

  const getCategories = async() => {
    setLoading(true);
    const { result } = await CategoriesAPI.getAllCategories((error) => {
      setLoading(false);
      handleError(error);
    });

    if (result) {
      if (result.status) {
        setLoading(false);
        setCategories(result.categories);
        setFilteredCategories(result.categories);
      } 
      
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const createCategory = () => {
    setShowCreateCategoryModal(true);
  };

  const onCategoryCreated = (category: Category) => {
    if (category) {
      setCategories([
        ...categories,
        category
      ]);
      setFilteredCategories([
        ...categories,
        category
      ]);
    }
  };

  const editCategory = (category: Category) => {
    setCategory(category);
    setShowEditCategoryModal(true);
  };

  const onCategoryUpdated = (category: Category) => {
    if (category) {
      setCategories(categories.map(cat => {
        if (category.id === cat.id) {
          return category;
        } else {
          return cat;
        }
      }));
      setFilteredCategories(filteredCategories.map(cat => {
        if (category.id === cat.id) {
          return category;
        } else {
          return cat;
        }
      }));
    }
  };

  const onCategoryDeleted = (id: number) => {
    setCategories(categories.filter(cat => cat.id !== id));
    setFilteredCategories(filteredCategories.filter(cat => cat.id !== id));
  };

  const searchCategories = (e: ChangeEvent<HTMLInputElement>) => {
    if(e.target.value === '') {
      setFilteredCategories(categories);
    } else {
      setFilteredCategories(categories.filter((item: Category) => {
        return item.name.toLowerCase().includes(e.target.value.toLowerCase());
      }));
    }
  };


  return (
    <>
      <HeaderTitle
        onBack={ () => history.back() }
        title="Categories"
      />
      <Divider />
      {
        can('create', 'categories') &&
        <div className="flex pb-4 flex-col sm:flex-row">
          <div className="mx-auto w-full sm:w-full flex items-center justify-start  text-ft-ts mr-4">
            <Input size="large" placeholder="Search categories..." suffix={ <SearchOutlined /> } onChange={ (e) => searchCategories(e) } />
          </div>
          {
            can('create', 'categories') &&
            <>
              <div className="flex w-full sm:w-auto my-2 justify-center">
                <div className="">
                  <button
                    className="h-9 px-12 flex justify-center items-center text-sm font-medium rounded-md text-white bg-indigo-700 hover:bg-indigo-900"
                    onClick={ createCategory }
                  >
                    <PlusOutlined className="pr-2"/>
                    <span className="w-[100px]">New Category</span>
                  </button>
                </div>
              </div>
              <CreateCategory
                showCreateCategoryModal={ showCreateCategoryModal }
                setShowCreateCategoryModal={ setShowCreateCategoryModal }
                onCategoryCreated={ onCategoryCreated }
              />
            </>
          }
        </div>
      }
      {
        can('read', 'categories') &&
          <div className="w-full flex flex-col justify-center items-center">
            <Spin tip="Loading..." spinning={ loading } wrapperClassName="w-full">
              {
                filteredCategories.length > 0 ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full mb-10">
                    {
                      filteredCategories.map((category: Category) => (
                        <CategoryCard
                          key={ category.id }
                          category={ category }
                          editCategory={ editCategory }
                          onCategoryDeleted={ onCategoryDeleted }
                        />
                      ))
                    }
                  </div>
                ) : (
                  <Empty />
                )
              }
            </Spin>
          </div>
      }
      {
        can('update', 'categories') &&
          <EditCategory
            setCategory={ setCategory }
            category={ category }
            showEditCategoryModal={ showEditCategoryModal }
            setShowEditCategoryModal={ setShowEditCategoryModal }
            onCategoryUpdated={ onCategoryUpdated }
          />
      }
    </>
  );
};

export default Categories;
