import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form,Input, Modal, Row, Select, Space } from 'antd';
// @ts-ignore
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { PostsAPI } from '../../app/api/endpoints/Posts';
import { handleError } from '../../helpers/Utils';
import { Category } from '../../models/Blog';
import { SelectItemsProps } from '../../models/CommonInterfaces';

export type SearchObj = {
  search: string;
  category: string
}

interface FilterPostsModalProps {
  showFilterModal: boolean;
  setShowFilterModal: (arg: boolean) => void;
  onFilterChange: (arg: SearchObj) => void;
}

export default function FilterPostsModal({ showFilterModal, setShowFilterModal, onFilterChange }: FilterPostsModalProps) {
  const [categories, setCategories] = useState<Category[]>([]);
  const [categoriesOptions, setCategoriesOptions] = useState<SelectItemsProps[]>([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [filters, setFilters] = useState({
    search: '',
    category: '',
  });

  useEffect(() => {
    if (showFilterModal) {
      getCategories();
    }
  }, [ showFilterModal ]);

  const getCategories = async () => {
    const { result } = await PostsAPI.getAllCategories((error) => {
      handleError(error);
    });

    if (result) {
      if (result.status) {
        setCategories(result.categories);
      }
    }
  };

  useEffect(() => {
    setIsDisabled(_.values(filters).every(_.isEmpty));
  }, [ filters ]);

  useEffect(() => {
    setCategoriesOptions(
      categories.map(category => {
        return { value: category.id, label: category.name  };
      })
    );
  }, [ categories ]);

  const onSelectCategoryChange = (value: string) => {
    setFilters({ ...filters, category: value?.toString() });
  };

  const clearFilter = () => {
    setFilters({
      search: '',
      category: ''
    });
    onFilterChange({} as any);
    handleCloseFilterModal();
  };

  const onFilter = () => {
    onFilterChange(filters);
    handleCloseFilterModal();
  };

  const handleCloseFilterModal = () => {
    setShowFilterModal(false);
  };

  return (
    <Modal
      title="Filter"
      open={ showFilterModal }
      footer={ null }
      onCancel={ handleCloseFilterModal }
    >
      <Divider />
      <Form
        layout="vertical"
      >
        <Row gutter={ [0, 20] } className="m-b-20">
          <Col span={ 24 }>
            <Input
              placeholder="Post title..."
              value={ filters.search }
              onChange={ (e) => setFilters({ ...filters, search: e.target.value }) }
            />
          </Col>
          <Col span={ 24 }>
            <Form.Item
              label="Filter by category"
            >
              <Select
                allowClear
                showSearch
                placeholder="Category"
                style={{ width: '100%' }}
                value={
                  Object.values(categoriesOptions).find((op: any) =>
                    parseInt(op.value) === parseInt(filters.category as string)
                  )?.label
                }
                optionFilterProp="children"
                onChange={ onSelectCategoryChange }
                onClear={ () => {
                  setFilters({ ...filters, category: '' });
                } }
                filterOption={ (input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={ categoriesOptions }
              />
            </Form.Item>
          </Col>
          <Col span={ 24 }>
            <Divider className="m-t-0"/>
            <Space.Compact block>
              <Button
                type="primary"
                disabled={ isDisabled }
                icon={ <SearchOutlined /> }
                onClick={ onFilter }
                style={{ width: '50%' }}
              >
                Search
              </Button>
              <Button
                type="primary"
                danger
                icon={ <CloseOutlined /> }
                onClick={ clearFilter }
                style={{ width: '50%' }}
              >
                Clear
              </Button>
            </Space.Compact>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
