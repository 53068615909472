import {
  CloseOutlined,
  CopyOutlined, EditOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
// @ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useNavigate, useParams } from 'react-router-dom';

import { ProjectsAPI } from '../../app/api/endpoints/Projects';
import { useCan } from '../../app/can';
import HeaderTitle from '../../components/Parcial/HeaderTitle';
import DeleteProjectModal from '../../components/Projects/DeleteProjectModal';
import { failedAlert, format422Error, handleError, successAlert } from '../../helpers/Utils';
import { Project } from '../../models/Project';

export default function SettingsProject() {
  const can = useCan();
  const navigate = useNavigate();
  const { projectId } = useParams<{projectId: string}>();
  const [loading, setLoading] = useState(false);
  const [loadingProject, setLoadingProject] = useState(false);
  const [loadingSyncSetting, setLoadingSyncSetting] = useState(false);
  const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false);
  const [project, setProject] = useState<Project>({} as Project);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [projectName, setProjectName] = useState<string>('');
  const [validationErrors, setValidationErrors] = useState({} as any);

  useEffect(() => {
    if (projectId) {
      getProject();
    }
  }, [ projectId ]);

  const getProject = async () => {
    setLoadingProject(true);
    const { result } = await ProjectsAPI.getProject(projectId, (error) => {
      handleError(error);
      setLoadingProject(false);
    });

    if (result) {
      if (result.status) {
        setProject(result.project);
        setProjectName(result.project.projectName);
      } else {
        failedAlert(result.message);
        navigate('/projects');
      }
      setLoadingProject(false);
    }
  };

  const syncProjectSetting = async () => {
    setLoadingSyncSetting(true);
    const { result } = await ProjectsAPI.syncProjectSetting(projectId, (error) => {
      handleError(error);
      setLoadingSyncSetting(false);
    });

    if (result) {
      if (result.status) {
        successAlert('Successfully updated');
      } else {
        failedAlert(result.message);
      }
      setLoadingSyncSetting(false);
    }
  };

  const updateProjectName = async () => {
    setLoading(true);
    const { result } = await ProjectsAPI.updateProjectName(projectId, { projectName: projectName },
      (error) => {
        if(error && error?.response && error.response.status === 422) {
          // @ts-ignore
          setValidationErrors(format422Error(error.response.data?.errors));
        } else {
          handleError(error);  
        }
        setLoading(false);
      });
    
    if (result) {
      if (result.status) {
        successAlert('Updated successfully.');
        setProject({
          ...project,
          projectName: projectName
        });
        setIsEditable(false);
      } else {
        failedAlert(result.message);
      }
      setLoading(false);
    }
  };

  return (
    <div>
      <HeaderTitle
        onBack={ () => navigate('/projects') }
        title={ project?.projectName || '' }
      />
      <Divider />
      {
        can('read', 'project') && can('update', 'project') &&
        <Spin tip="Loading..." spinning={ loadingProject } wrapperClassName="w-full">
          <div className="flex flex-col justify-center items-center">
            <div className="bg-white w-5/6  min-h-[100px] rounded-xl p-10 border border-gray-300">
              <div className="flex flex-row justify-between items-center">
                <div>
                  <p className="font-bold">Sync project settings</p>
                  <p>Pull all collection contract congig from FeverToken.</p>
                </div>
                <Button
                  loading={ loadingSyncSetting }
                  className="!text-white !bg-green-500"
                  onClick={ syncProjectSetting }
                >
                  <SyncOutlined/> Sync
                </Button>
              </div>
            </div>
            <div className="min-h-[50px]"/>
            <div className="bg-white w-5/6 min-h-[100px] rounded-xl p-10 border border-gray-300">
              <div className="flex flex-row justify-between items-center">
                <h1 className="text-xl font-bold tracking-[0.05rem]">Project Name</h1>
                {
                  isEditable ? (

                    <button
                      className="p-2 rounded-full bg-gray-200"
                      onClick={ () => setIsEditable(false) }
                    >
                      <CloseOutlined className="text-lg text-red-500 flex items-center justify-center" />
                    </button>
                  ) : (
                    <button
                      className="p-2 rounded-full bg-gray-200"
                      onClick={ () => setIsEditable(true) }
                    >
                      <EditOutlined className="text-lg flex items-center justify-center" />
                    </button>
                  )
                }
              </div>
              {
                isEditable ?
                  <>
                    <div className="mt-5 flex">
                      <Input
                        className="flex-1 mr-2"
                        size="large"
                        value={ projectName }
                        onChange={ (e) => setProjectName(e.target.value) }
                      />
                      <Button loading={ loading } onClick={ () => updateProjectName() } size="large" type="primary">Submit</Button>
                    </div>
                    {
                      validationErrors && validationErrors.projectName && (
                        <span className="ant-form-item-explain-error">{ validationErrors.projectName }</span>
                      )
                    }
                  </>
                  :
                  <div className="mt-5 text-xl">{ project?.projectName }</div>
              }
            </div>
            <div className="min-h-[50px]"/>
            <div className="bg-white w-5/6  min-h-[100px] rounded-xl p-10 border border-gray-300">
              <h1 className="text-xl text-black font-bold tracking-[0.05rem]">Project ID</h1>
              <div className="flex flex-col gap-x-3 w-full mt-2">
                <span className="text-sm text-gray-500 tracking-[0.05rem] my-5">Used when interacting with the FeverTokens API.</span>
                <div className="flex flex-row justify-between items-center rounded-xl text-black border border-gray-300">
                  <span className="px-4 py-2">{ project?.projectId }</span>
                  <CopyToClipboard
                    text={ project?.projectId }
                    onCopy={ () => successAlert('Copied to clipboard') }
                  >
                    <CopyOutlined className="text-lg mr-2" />
                  </CopyToClipboard>
                  
                </div>
              </div>
            </div>
            <div className="min-h-[50px]"/>
            <div className="flex justify-start items-start w-5/6">
              <h2 className="text-xl text-red-500 font-bold text-left mb-2">Danger Zone</h2>
            </div>
            <div className="bg-white w-5/6  min-h-[100px] rounded-xl p-10 border border-red-300">
              <div className="flex flex-row justify-between items-center">
                <div>
                  <p className="font-bold">Delete this project</p>
                  <p>Once you delete a project, there is no going back.</p>
                </div>
                <div
                  className="text-white bg-red-500 rounded-xl px-4 py-2 cursor-pointer"
                  onClick={ () => setShowDeleteProjectModal(true) }
                >
                    Delete Project
                </div>
                <DeleteProjectModal
                  projectId={ project?.projectId }
                  projectName={ project?.projectName }
                  showDeleteProjectModal={ showDeleteProjectModal }
                  setShowDeleteProjectModal={ setShowDeleteProjectModal }
                />
              </div>
            </div>
          </div>
        </Spin>
      }
    </div>
  );
}

