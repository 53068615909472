import { CopyOutlined, LinkOutlined, ShareAltOutlined } from '@ant-design/icons';
import React from 'react';
// @ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useNavigate } from 'react-router-dom';

import { networks } from '../../helpers/Networks';
import { shortenAddress, successAlert } from '../../helpers/Utils';
import { CollectionParamsProps } from '../../models/Collection';


export default function Overview({ collection, projectId, clubId }: CollectionParamsProps) {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col justify-center items-center space-y-8 ">
      <div className="bg-white w-full min-h-[100px] rounded-xl p-4 md:p-10 md:w-5/6 border border-gray-300">
        <h1 className="text-xl text-black font-bold tracking-[0.05rem] md:px-7">
          Configuration
        </h1>
        <div className="py-5 px-6 my-2 rounded-lg w-full h-full">
          {
            collection?.config ? (
              <div className="space-y-3 mt-3">
                <span className="text-xs font-semibold inline-block py-1 px-2 rounded-lg uppercase last:mr-0 mr-1 text-rose-600 bg-rose-200">
                  Contract Type : { collection?.config?.contract_type }
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 rounded-lg uppercase last:mr-0 mr-1 text-teal-600 bg-teal-200">
                  Network : Ethereum
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 rounded-lg uppercase last:mr-0 mr-1 bg-green-200">
                  Reveal Mechanism : { collection?.config?.features?.revealMechanism }
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 rounded-lg uppercase last:mr-0 mr-1 text-red-600 bg-red-200">
                  Storage : { collection?.config?.features?.OffChainStorage }
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 rounded-lg uppercase last:mr-0 mr-1 text-violet-600 bg-violet-200">
                  Randomness : { collection?.config?.features?.randomness }
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 rounded-lg uppercase last:mr-0 mr-1 text-slate-600 bg-slate-200">
                  Type of sale access : { collection?.config?.sales_options?.saleAccess }
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 rounded-lg uppercase last:mr-0 mr-1 text-rose-600 bg-rose-200">
                  Type of token payment : { collection?.config?.sales_options?.tokenPayment }
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 rounded-lg uppercase last:mr-0 mr-1 bg-green-200">
                  Type of sale strategy : { collection?.config?.sales_options?.saleStrategy }
                </span>
              </div>
            ) : (
              <div className="space-y-3 mt-3 text-ft-lt">
                <p className="text-base py-1 px-2 rounded-lg last:mr-0 mr-1">No configuration found</p>
              </div>
            )
          }
          <hr className="my-3 border-0 h-[2px] bg-gray-200 w-1/3 mx-auto"/>
          <h3 className="w-full mb-3 font-bold">
            Testnet
          </h3>
          {
            collection?.config?.deployement && collection?.config?.deployement?.testnet ? (
              <>
                <div className="flex flex-wrap items-center justify-center gap-4 max-w-lg">
                  <div className="flex-1">Factory  address:</div>
                  <div className="flex-1">
                    <div className="cursor-pointer">
                      <span>{ shortenAddress(collection?.config?.deployement?.testnet?.proxyAddress) }</span>
                    </div>
                  </div>
                  <div className="flex-1 flex items-center">
                    <CopyToClipboard
                      text={ collection?.config?.deployement?.testnet?.proxyAddress }
                      onCopy={ () => successAlert('Copied to clipboard') }
                    >
                      <CopyOutlined className="text-lg" />
                    </CopyToClipboard>
                    <a target="_blank" rel="noreferrer" href={ `${networks[collection?.config?.deployement?.testnet?.networkId]?.explorers[0]?.url}/address/${collection?.config?.deployement?.testnet?.proxyAddress}` }><LinkOutlined className="text-lg"/></a>
                  </div>
                </div>
                <div className="flex flex-wrap items-center justify-center gap-4 max-w-lg">
                  <div className="flex-1">Royalty  address:</div>
                  <div className="flex-1">
                    <div className="cursor-pointer">
                      <span>{ shortenAddress(collection?.config?.deployement?.testnet?.royaltyAddress) }</span>
                    </div>
                  </div>
                  <div className="flex-1 flex items-center">
                    <CopyToClipboard
                      text={ collection?.config?.deployement?.testnet?.royaltyAddress }
                      onCopy={ () => successAlert('Copied to clipboard') }
                    >
                      <CopyOutlined className="text-lg" />
                    </CopyToClipboard>
                    <a target="_blank" rel="noreferrer" href={ `${networks[collection?.config?.deployement?.testnet?.networkId]?.explorers[0]?.url}/address/${collection?.config?.deployement?.testnet?.royaltyAddress}` }><LinkOutlined className="text-lg"/></a>
                  </div>
                </div>
                <div className="flex flex-wrap items-center justify-center gap-4 max-w-lg">
                  <div className="flex-1">InitialSplit  address:</div>
                  <div className="flex-1">
                    <div className="cursor-pointer">
                      <span>{ shortenAddress(collection?.config?.deployement?.testnet?.initialSplitAddress) }</span>
                    </div>
                  </div>
                  <div className="flex-1 flex items-center">
                    <CopyToClipboard
                      text={ collection?.config?.deployement?.testnet?.initialSplitAddress }
                      onCopy={ () => successAlert('Copied to clipboard') }
                    >
                      <CopyOutlined className="text-lg" />
                    </CopyToClipboard>
                    <a target="_blank" rel="noreferrer" href={ `${networks[collection?.config?.deployement?.testnet?.networkId]?.explorers[0]?.url}/address/${collection?.config?.deployement?.testnet?.initialSplitAddress}` }><LinkOutlined className="text-lg"/></a>
                  </div>
                </div>
              </>
            ) : (
              <p className="text-[14px]">Not deployed yet to testnet, please deploy it to testnet</p>
            )
          }
          <hr className="my-3 border-0 h-[2px] bg-gray-200  w-1/3 mx-auto"/>
          <h3 className="w-full mb-3 font-bold">Mainnet</h3>
          {
            collection?.config?.deployement && collection?.config?.deployement?.mainnet ? (
              <></>
            ) : (
              <p className="text-[14px]">Not deployed yet to mainnet, please deploy it to mainnet</p>
            )
          }
        </div>
      </div>
      <div className="flex w-full items-center justify-center gap-x-4 cursor-pointer">
        <div className="bg-white w-5/6  min-h-[100px] rounded-xl p-10 border border-gray-300">
          <div className="flex flex-row justify-between items-center">
            <h1 className="text-xl w-full text-black font-bold tracking-[0.05rem]">Items Overview</h1>
            <div className="flex justify-end items-center w-full">
              <button
                className="bg-indigo-900 tracking-[.13rem] disabled:cursor-not-allowed disabled:bg-gray-400 px-5 py-1 text-base text-white rounded-md"
                onClick={ () => navigate(`/project/${projectId}/club/${clubId}/collections/${collection?.collection_id}?tab=items`) }
              >
                View Items
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="flex w-full items-center justify-center gap-x-4 cursor-pointer">
          <div className="bg-white w-5/6  min-h-[100px] rounded-xl p-10 border border-gray-300">
            <div className="flex flex-row justify-between items-center my-5">
              <h1 className="text-xl w-full text-black font-bold tracking-[0.05rem] ">Splitters</h1>
            </div>
            {
              collection?.config.deployement ? (
                <ul role="list" className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-2 xl:gap-x-8">
                  {
                    collection?.config.deployement?.testnet.royaltyAddress &&
                    <li className="overflow-hidden rounded-xl border border-gray-200">
                      <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                        <div className="h-10 w-10  rounded-lg bg-white object-cover ring-1 ring-gray-900/10 flex justify-center items-center">
                          <img className="h-5 w-5" src={ networks[collection?.config?.deployement?.testnet?.networkId]?.ftIcon } alt="" />
                        </div>
                        <div className="text-sm font-medium leading-6 text-gray-900">
                          <div className="cursor-pointer">
                            <span>{ shortenAddress(collection?.config.deployement?.testnet.royaltyAddress) }</span>
                          </div>
                          <span className=" mx-1 inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">Testnet</span>
                          <span className="mx-1 inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">Royalty Address</span>
                        </div>
                        <div className="relative ml-auto cursor-copy">
                          <div className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500 cursor-copy">
                            <CopyToClipboard
                              text={ `https://app.fevertokens.io/splitter?address=${collection?.config.deployement?.testnet.royaltyAddress}&networkId=${collection?.config.deployement?.testnet.networkId}` }
                              onCopy={ () => successAlert('Copied to clipboard') }
                            >
                              <ShareAltOutlined className="font-bold text-xl" />
                            </CopyToClipboard>
                            <span className="sr-only">Open options</span>
                          </div>
                        </div>
                      </div>
                      <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">Total Released</dt>
                          <dd className="flex items-start gap-x-2">
                            <div className="font-medium text-gray-900 space-x-1 ">
                              <span>0</span><span>MATIC</span>
                            </div>
                          </dd>
                        </div>
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">Balance</dt>
                          <dd className="flex items-start gap-x-2">
                            <div className="font-medium text-gray-900 space-x-1 ">
                              <span>0</span><span>MATIC</span>
                            </div>
                            <div className="hidden">To Release</div>
                          </dd>
                        </div>
                        <div className="flex justify-between gap-x-4 py-3">
                          <a href={ `https://app.fevertokens.io/splitter?address=${collection?.config.deployement?.testnet.royaltyAddress}&networkId=${collection?.config.deployement?.testnet.networkId}` }>
                            <button
                              className="transition-colors duration-[250ms] disabled:cursor-not-allowed disabled:bg-gray-400  px-3 py-0.5 text-[13px] rounded-md font-bold tracking-normal disabled:bg-bray-700 bg-black text-white"
                            >
                              Payout Page
                            </button>
                          </a>
                        </div>
                      </dl>
                    </li>
                  }
                  {
                    collection?.config.deployement?.testnet.initialSplitAddress &&
                    <li className="overflow-hidden rounded-xl border border-gray-200">
                      <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                        <div className="h-10 w-10  rounded-lg bg-white object-cover ring-1 ring-gray-900/10 flex justify-center items-center">
                          <img className="h-5 w-5" src={ networks[collection?.config?.deployement?.testnet?.networkId]?.ftIcon } alt="" />
                        </div>
                        <div className="text-sm font-medium leading-6 text-gray-900">
                          <div className="cursor-pointer">
                            <span>{ shortenAddress(collection?.config.deployement?.testnet.initialSplitAddress) }</span>
                          </div>
                          <span className=" mx-1 inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">Testnet</span>
                          <span className="mx-1 inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">Initial Split Address</span>
                        </div>
                        <div className="relative ml-auto cursor-copy">
                          <div className="block p-2.5 text-gray-400 hover:text-gray-500 cursor-copy">
                            <CopyToClipboard
                              text={ `https://app.fevertokens.io/splitter?address=${collection?.config.deployement?.testnet.initialSplitAddress}&networkId=${collection?.config.deployement?.testnet.networkId}` }
                              onCopy={ () => successAlert('Copied to clipboard') }
                            >
                              <ShareAltOutlined className="font-bold text-xl" />
                            </CopyToClipboard>
                            <span className="sr-only">Open options</span>
                          </div>
                        </div>
                      </div>
                      <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">Total Released</dt>
                          <dd className="flex items-start gap-x-2">
                            <div className="font-medium text-gray-900 space-x-1 ">
                              <span>0</span><span>MATIC</span>
                            </div>
                          </dd>
                        </div>
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">Balance</dt>
                          <dd className="flex items-start gap-x-2">
                            <div className="font-medium text-gray-900 space-x-1 ">
                              <span>0</span><span>MATIC</span>
                            </div>
                            <div className="hidden">To Release</div>
                          </dd>
                        </div>
                        <div className="flex justify-between gap-x-4 py-3">
                          <a href={ `https://app.fevertokens.io/splitter?address=${collection?.config.deployement?.testnet.initialSplitAddress}&networkId=${collection?.config.deployement?.testnet.networkId}` }>
                            <button
                              className="transition-colors duration-[250ms] disabled:cursor-not-allowed disabled:bg-gray-400  px-3 py-0.5 text-[13px] rounded-md font-bold tracking-normal disabled:bg-bray-700 bg-black text-white"
                            >
                              Payout Page
                            </button>
                          </a>
                        </div>
                      </dl>
                    </li>
                  }
                </ul>
              ) : (
                <div className="flex flex-col items-center justify-center">
                  <h1 className="text-2xl font-bold">No Splitter Found</h1>
                  <p className="text-lg text-ft-l-gray-200">Please deploy a splitter contract to start splitting your royalties</p>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
}
