// import { LockFilled } from '@ant-design/icons';
import { Button, Col, Divider, Form,Input, Modal, Row, } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProjectsAPI } from '../../app/api/endpoints/Projects';
import { failedAlert, handleError, successAlert } from '../../helpers/Utils';

export type SearchObj = {
  search: string;
  category: string
}

interface DeleteProjectModalProps {
  projectId: string;
  projectName: string;
  showDeleteProjectModal: boolean;
  setShowDeleteProjectModal: (arg: boolean) => void;
}

export default function DeleteProjectModal({ projectId, projectName, showDeleteProjectModal, setShowDeleteProjectModal }: DeleteProjectModalProps) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [projectNameInput, setProjectNameInput] = useState<string>('');

  useEffect(() => {
    setIsDisabled(projectName !== projectNameInput);
  }, [ projectNameInput ]);

  const handleCloseDeleteProjectModal = () => {
    setShowDeleteProjectModal(false);
  };

  const destroy = async (projectId: string | undefined) => {
    if (!isDisabled) {
      setLoading(true);
      const { result } = await ProjectsAPI.destroyProject(projectId,  (error) => {
        setLoading(false);
        handleError(error);
      });
      if (result) {
        setLoading(false);
        if (result.status) {
          successAlert('Project Deleted successfully.');
          handleCloseDeleteProjectModal();
          navigate('/projects');
        } else {
          failedAlert(result.message);
        }
      }
    }
  };

  return (
    <Modal
      title={ <>Delete <span className="select-none">{ projectName }</span></> }
      open={ showDeleteProjectModal }
      footer={ null }
      onCancel={ handleCloseDeleteProjectModal }
    >
      <Divider />
      <Form
        layout="vertical"
      >
        <Row gutter={ [0, 20] } className="m-b-20">
          
          <Col span={ 24 }>
            <p className="text-sm font-bold mb-2">To confirm, type &quot;<span className="select-none">{ projectName }</span>&quot; in the box below</p>
            <Input
              size="large"
              placeholder="Project name..."
              value={ projectNameInput }
              onChange={ (e) => setProjectNameInput(e.target.value) }
            />
          </Col>
          <Col span={ 24 }>
            <Button
              danger
              disabled={ isDisabled }
              loading={ loading }
              onClick={ () => destroy(projectId) }
              style={{ width: '100%' }}
            >
              Delete this project
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
