import { SaveOutlined } from '@ant-design/icons';
import { Button, Card, Col, Collapse, Divider, Form, Input, Row, Select } from 'antd';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PostsAPI } from '../../../app/api/endpoints/Posts';
import { useCan } from '../../../app/can';
import ImageUpload from '../../../components/Common/ImageUpload';
import QuillEditor from '../../../components/Common/QuillEditor';
import HeaderTitle from '../../../components/Parcial/HeaderTitle';
import { failedAlert, format422Error, handleError, successAlert } from '../../../helpers/Utils';
import { Category, Post, Tag } from '../../../models/Blog';
import { SelectItemsProps } from '../../../models/CommonInterfaces';
import CreateCategory from '../Categories/CreateCategory';

export default function CreatePost() {
  const can = useCan();
  const navigate = useNavigate();
  const [loadingDraft, setLoadingDraft] = useState(false);
  const [loadingPublish, setLoadingPublish] = useState(false);
  const [showCreateCategoryModal, setShowCreateCategoryModal] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const [categoriesOptions, setCategoriesOptions] = useState<SelectItemsProps[]>([]);
  const [validationErrors, setValidationErrors] = useState({} as Post);
  const [inputs, setInputs] = useState({
    title: '',
    body: '',
    category_id: '1',
    tags: [] as string[]
  });
  const [meta, setMeta] = useState({
    meta_description: '',
    opengraph_title: '',
    opengraph_description: '',
    twitter_title: '',
    twitter_description: '',
  });
  const [featuredImage, setFeaturedImage] = useState<File | undefined>(undefined);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    const { result } = await PostsAPI.getAllCategories((error) => {
      handleError(error);
    });

    if (result) {
      if (result.status) {
        setCategories(result.categories);
        setTags(result.tags);
      }
    }
  };

  const createCategory = () => {
    setShowCreateCategoryModal(true);
  };

  const onCategoryCreated = (category: Category) => {
    if (category && category.status) {
      setCategories([
        ...categories,
        category
      ]);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setCategoriesOptions(
      categories.map(category => {
        return { value: category.id, label: category.name  };
      })
    );
  }, [ categories ]);

  const onSelectCategoryChange = (value: string) => {
    setInputs({ ...inputs, category_id: value?.toString() });
  };

  const onTagChange = (value: string[]) => {
    setInputs({ ...inputs, tags: value });
  };

  const onImageChange = (image: File | undefined) => {
    setFeaturedImage(image);
  };

  const onMetaChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setMeta({ ...meta, [e.target.name]: e.target.value });
  };

  const savePost = async (e: FormEvent, status: string) => {
    e.preventDefault();
    status === '1' ? setLoadingPublish(true) : setLoadingDraft(true);
    setValidationErrors({} as Post);
    const formData = new FormData();
    formData.append('title', inputs.title);
    formData.append('body', inputs.body);
    formData.append('category', inputs.category_id);
    formData.append('tags', inputs.tags.join(','));
    formData.append('status', status);
    formData.append('meta', JSON.stringify(meta));
    if (featuredImage) {
      formData.append('featured_image', featuredImage);
    }
    const { result } = await PostsAPI.store(formData,
      (error) => {
        setLoadingDraft(false);
        setLoadingPublish(false);
        if(error && error?.response && error.response.status === 422) {
          // @ts-ignore
          setValidationErrors(format422Error(error.response.data?.errors));
        } else {
          handleError(error);  
        }
      });
    
    if (result) {
      setLoadingDraft(false);
      setLoadingPublish(false);
      if (result.status) {
        successAlert('Saved successfully.');
        navigate('/blog/posts');
      } else {
        failedAlert(result.message);
      }
    }
  };

  return (
    <div>
      <HeaderTitle
        onBack={ () => navigate('/blog/posts') }
        title="Create a new post"
      />
      <Divider />
      {
        can('create', 'posts') &&
        <Form
          layout="vertical"
        >
          <Row gutter={ 24 }>
            <Col sm={ 24 } md={ 18 }>
              <Card title="Post content" bordered={ false }>
                <Form.Item
                  label="Title:"
                  required
                >
                  <Input
                    value={ inputs.title }
                    name="title"
                    size="large"
                    onChange={ (e) => handleInputChange(e) }
                  />
                  {
                    validationErrors && validationErrors.title && (
                      <span className="text-red-500">{ validationErrors.title }</span>
                    )
                  }
                </Form.Item>
                <Form.Item>
                  <QuillEditor 
                    theme="snow"
                    value={ inputs.body }
                    onChange={ (text) => setInputs({ ...inputs, body: text }) }
                  />
                  {
                    validationErrors && validationErrors.body && (
                      <span className="text-red-500">{ validationErrors.body }</span>
                    )
                  }
                </Form.Item>
              </Card>
              <div className="h-4"></div>
              <Collapse size="large" className="bg-white shadow-lg rounded-lg border-none">
                <Collapse.Panel
                  header="Post meta tags" key="1"
                >
                  <Form.Item
                    label="Meta description:"
                  >
                    <Input.TextArea
                      value={ meta.meta_description }
                      name="meta_description"
                      size="large"
                      onChange={ (e) => onMetaChange(e) }
                    />
                  </Form.Item>
                  <Divider/>
                  <Form.Item
                    label="Facebook Card Title"
                  >
                    <Input
                      value={ meta.opengraph_title }
                      name="opengraph_title"
                      size="large"
                      onChange={ (e) => onMetaChange(e) }
                    />
                  </Form.Item>
                  <Form.Item
                    label="Facebook Card Description:"
                  >
                    <Input.TextArea
                      value={ meta.opengraph_description }
                      name="opengraph_description"
                      size="large"
                      onChange={ (e) => onMetaChange(e) }
                    />
                  </Form.Item>
                  <Divider/>
                  <Form.Item
                    label="Twitter Card Title"
                  >
                    <Input
                      value={ meta.twitter_title }
                      name="twitter_title"
                      size="large"
                      onChange={ (e) => onMetaChange(e) }
                    />
                  </Form.Item>
                  <Form.Item
                    label="Twitter Card Description:"
                  >
                    <Input.TextArea
                      value={ meta.twitter_description }
                      name="twitter_description"
                      size="large"
                      onChange={ (e) => onMetaChange(e) }
                    />
                  </Form.Item>
                </Collapse.Panel>
              </Collapse>
            </Col>
            <Col sm={ 24 } md={ 6 }>
              <Card bordered={ false }>
                <Button
                  className="mr-2 mb-4 md:mb-0"
                  type="default"
                  onClick={ (e) => savePost(e, '0') }
                  loading={ loadingDraft }
                  disabled={ loadingPublish }
                  size="large"
                  icon={ <SaveOutlined /> }
                >
                  Draft
                </Button>
                <Button
                  className="mb-4 md:mb-0"
                  type="primary"
                  onClick={ (e) => savePost(e, '1') }
                  loading={ loadingPublish }
                  disabled={ loadingDraft }
                  size="large"
                  icon={ <SaveOutlined /> }
                >
                  Publish
                </Button>
              </Card>
              <div className="h-4"></div>
              <Card title={ <><span className="text-red-500 text-xs">*</span> Post Category</> } bordered={ false }>
                <Select
                  showSearch
                  placeholder="Category"
                  style={{ width: '100%' }}
                  value={
                    Object.values(categoriesOptions).find((op: any) =>
                      parseInt(op.value) === parseInt(inputs.category_id as string)
                    )?.label
                  }
                  optionFilterProp="children"
                  onChange={ onSelectCategoryChange }
                  filterOption={ (input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={ categoriesOptions }
                />
                {
                  validationErrors && validationErrors.category_id && (
                    <span className="text-red-500">{ validationErrors.category_id }</span>
                  )
                }
                {
                  can('create', 'categories') &&
                  <>
                    <span className="float-right cursor-pointer text-indigo-800 hover:text-indigo-500" onClick={ createCategory }>+ create new</span>
                    <CreateCategory
                      showCreateCategoryModal={ showCreateCategoryModal }
                      setShowCreateCategoryModal={ setShowCreateCategoryModal }
                      onCategoryCreated={ onCategoryCreated }
                    />
                  </>
                  
                }
              </Card>
              <div className="h-4"></div>
              <Card title="Tags" bordered={ false }>
                <Select
                  mode="tags"
                  style={{ width: '100%' }}
                  placeholder="Select or create new tags"
                  onChange={ onTagChange }
                  options={ tags.map(tag => (
                    { value: tag.name, label: tag.name  }
                  )) }
                />
              </Card>
              <div className="h-4"></div>
              <Card title={ <><span className="text-red-500 text-xs">*</span> Featured image</> } bordered={ false }>
                <ImageUpload onImageChange={ (image) => onImageChange(image) }/>
                {
                  validationErrors && validationErrors.featured_image && (
                    <span className="text-red-500">{ validationErrors.featured_image }</span>
                  )
                }
              </Card>
            </Col>
          </Row>
        </Form>
      }
    </div>
  );
}
