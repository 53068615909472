import { AxiosError, AxiosResponse } from 'axios';


interface responseType<T> {
    result: void | T,
    error: void | AxiosResponse
}

export const RequestWrapper = async <T, >(requestHandler: () => Promise<T>, errorHandler: (error: AxiosError) => void) => {
  const response:responseType<T> = { result: undefined, error: undefined };
  response.result = await requestHandler().catch((err) => {
    errorHandler(err);
    response.error = err;
  });
  return response;
};

export type { responseType };
