import {
  DeleteOutlined, EditOutlined, FilterOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Badge, Button, Col, Divider, Row, Space, Table, Tag } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { PostsAPI } from '../../../app/api/endpoints/Posts';
import { useCan } from '../../../app/can';
import ShowDate from '../../../components/Common/ShowDate';
import FilterPostsModal, { SearchObj } from '../../../components/Filters/FilterPostsModal';
import HeaderTitle from '../../../components/Parcial/HeaderTitle';
import { confirmAlert, failedAlert, handleError, successAlert } from '../../../helpers/Utils';
import { Category, Post } from '../../../models/Blog';


const Posts: React.FC = (): JSX.Element =>{
  const can = useCan();
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState<Post[]>([]);
  const [search, setSearch] = useState<SearchObj|undefined>(undefined);
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
  const [filterCount, setFilterCount] = useState<number>(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 1,
    total: 1
  });

  const columns: ColumnsType<Post> = [
    {
      title: 'Title',
      dataIndex: 'title',
      sorter: true,
      render: (title: string, record: { id: React.Key }) => (
        <div className="max-w-md truncate">
          {
            can('update', 'posts') ?
              <NavLink
                to={ `/blog/posts/${record.id}/edit` }
              >
                { title }
              </NavLink>
              :
              <span>{ title }</span>
          }
        </div>
      )
    },
    {
      title: 'Category name',
      dataIndex: 'category',
      render: (category: Category) => (
        <Tag color="blue" key={ category?.id }>
          { category?.name }
        </Tag>
      ),
    },
    {
      title: 'Publish status',
      dataIndex: 'status',
      filters: [
        { text: 'Published', value: 1 },
        { text: 'Unpublished', value: 0 },
      ],
      render: (status: boolean) => (
        <>
          {
            status ?
              <Tag color="green">
                Published
              </Tag>
              :
              <Tag color="red">
                Unpublished
              </Tag>
          }
        </>
      ),
    },
    {
      title: 'Creation date',
      dataIndex: 'created_at',
      sorter: true,
      render: (created_at: Date) => (
        <ShowDate date={ created_at }/>
      )
    },
    {
      title: 'Actions',
      className: 'text-right',
      render: (record: { id: React.Key }) => (
        can('update', 'posts') &&
          <div style={{ width: '100%', display: 'flex', justifyContent: 'end', gap: 5 }}>
            <NavLink
              to={ `/blog/posts/${record.id}/edit` }
            >
              <Button type="primary" shape="circle" icon={ <EditOutlined /> } />
            </NavLink>
          </div>
      )
    },
  ];

  useEffect(() => {
    getPosts({ pagination });
  }, []);

  const getParams = (params: any) => ({
    ...params.query,
    page: params.pagination?.current,
    sort_field: params.sorter?.field,
    sort_order: params.sorter?.order,
    ...params.filters
  });

  const onSelectChange = (selectedRowKeys: React.Key[])  => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const getPosts = async (params = {}) => {
    setLoading(true);
    const { result } = await PostsAPI.getAllPosts(`?${qs.stringify(getParams(params), { skipNulls: true })}`, (error) => {
      handleError(error);
      setLoading(false);
    });

    if (result) {
      if (result.status) {
        setPosts(result.posts.data);
        setPagination( {
          current: result.posts.meta.current_page,
          pageSize: result.posts.meta.per_page,
          total: result.posts.meta.total
        });
      } 
      setLoading(false);
    }
  };

  const handleFilterModal = () => {
    setShowFilterModal(true);
  };

  const onFilterChange = (searchObj: { search: string, category: string }) => {
    setSearch(searchObj);
    getPosts({
      query: searchObj,
      pagination: pagination
    });
  };

  useEffect(() => {
    const newArr = search ? Object.values(search).filter(s => s !== '') : [];
    setFilterCount(newArr.length);
  }, [ search ]);

  const handleTableChange: TableProps<Post>['onChange'] = (
    newPagination,
    filters,
    sorter,
  ) => {
    getPosts({
      query: search,
      sorter: sorter,
      pagination: newPagination,
      filters: filters,
    });
  };

  const destroy = async () => {
    confirmAlert().then(async (res) => {
      if (res) {
        const { result } = await PostsAPI.destroy(selectedRowKeys,  (error) => {
          handleError(error);
        });
        if (result) {
          if (result.status) {
            getPosts({ pagination });
            setSelectedRowKeys([]);
            successAlert('Deleted successfully.');
          } else {
            failedAlert(result.message);
          }
        }
      }
    });
  };

  return (
    <div>
      <HeaderTitle
        onBack={ () => history.back() }
        title="List of posts"
      />
      <Divider />
      {
        can('read', 'posts') &&
        <div>
          <Row>
            <Col span={ 12 }>
              <Badge count={ filterCount }>
                <Button
                  type="primary"
                  icon={ <FilterOutlined /> }
                  onClick={ handleFilterModal }
                >Filter</Button>
              </Badge>
              <FilterPostsModal
                showFilterModal={ showFilterModal }
                setShowFilterModal={ setShowFilterModal }
                onFilterChange={ onFilterChange }
              />
            </Col>
            <Col span={ 12 } style={{ textAlign: 'right', marginBottom: '20px' }}>
              <Space>
                {
                  can('create', 'posts') &&
                    <NavLink
                      to="/blog/posts/create"
                    >
                      <Button type="primary" icon={ <PlusCircleOutlined /> } >Add new post</Button>
                    </NavLink>
                }
              </Space>
            </Col>
          </Row>
          <Divider />
          {
            selectedRowKeys.length>0 &&
            <Row className="m-b-15">
              <Col span={ 12 }>
                { selectedRowKeys.length } row{ selectedRowKeys.length>1?'s':'' } selected
              </Col>
              <Col span={ 12 } style={{ textAlign: 'right' }}>
                <Space>
                  {
                    can('delete', 'posts') &&
                    <>
                      <Button
                        type="primary"
                        danger
                        icon={ <DeleteOutlined /> }
                        onClick={ destroy }
                      >
                        Delete
                      </Button>
                    </>
                  }
                </Space>
              </Col>
            </Row>
          }

          <Table<Post>
            loading={ loading }
            rowKey={ post => post.id }
            rowSelection={ rowSelection }
            columns={ columns }
            dataSource={ posts }
            pagination={ pagination }
            size="small"
            onChange={ handleTableChange }
          />
        </div>
      }
    </div>
  );
};

export default Posts;
