import { PlusOutlined } from '@ant-design/icons';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { CollectionParamsProps } from '../../models/Collection';
import Items from '../Items/Items';


export default function CollectionItems({ collection, projectId, clubId }: CollectionParamsProps) {

  return (
    <div>
      <Items collectionId={ collection?.collection_id } projectId={ projectId } clubId={ clubId } />
      <div className=" flex flex-col items-center justify-center gap-4 w-full">
        <NavLink
          to={ `/project/${projectId}/collections/${collection?.collection_id}/items/create` }
          className="h-9 px-12 flex justify-center items-center text-sm font-medium rounded-md text-white hover:text-white bg-indigo-700 hover:bg-indigo-900"
        >
          <PlusOutlined className="pr-2"/>
            Create new item
        </NavLink>
        { /* <a href="">
          <button className="tracking-[.13rem] disabled:cursor-not-allowed disabled:bg-gray-400  underline text-[14px] transition-colors duration-[250ms] font-medium text-ft-l-gray-200 w-full lg:w-auto lg:px-10">
            Upload generated items
          </button>
        </a>
        <a href="">
          <button className="tracking-[.13rem] disabled:cursor-not-allowed disabled:bg-gray-400  underline text-[14px] transition-colors duration-[250ms] font-medium text-ft-l-gray-200 w-full lg:w-auto lg:px-10">
            Create multiple items with same attributes
          </button>
        </a> */ }
      </div>
    </div>
  );
}
