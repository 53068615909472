import { AbilityBuilder,createMongoAbility } from '@casl/ability';

import { useAuth } from '../providers/auth/AuthProvider';


export const useCan = () => {
  const { permissions } = useAuth();

  const defineAbilitiesFor = (permissions: string[]) => {
    const { can, build } = new AbilityBuilder(createMongoAbility);
    // This logic depends on how the
    // server sends you the permissions array
  
    if (permissions) {
      permissions.forEach((p) => {
        const per = p.split('-');
        can(per[0], per[1]);
      });
    }
  
    return build();
  };
  
  const can = (action: string, subject: string) => {
    const ability = defineAbilitiesFor(permissions);
    return ability.can(action, subject);
  };
  
  return can;
};

