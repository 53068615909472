import { SafetyOutlined } from '@ant-design/icons';
import { Alert,Button, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import Logo from '../../assets/img/logo.svg';
import { useAuth } from '../../providers/auth/AuthProvider';

export default function TwoFactorVerification() {
  const navigate = useNavigate();
  const { loading, twoFactorLogin, error, setError, isConnected } = useAuth();
  const [token, setToken] = useState<string>('');

  const onSubmit = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setError(undefined);
    if (token !== '' && !isNaN(parseInt(token))) {
      await twoFactorLogin(token, null);
    } else {
      setError('Code should be a valid 6 digits.');
    }
  };

  useEffect(() => {
    isConnected && navigate('/');
  }, [ isConnected ]);

  return (
    <div className="login-page">
      <div className="login-box ">
        <div className="header">
          <div className="flex justify-center">
            <img src={ Logo } style={{ width: 200 }} alt="Goall" />
          </div>
          <p className="mb-6">
            Two Factor verification
          </p>
        </div>
        {
          error &&
        <Alert className="m-b-15" message={ error } type="error" showIcon />
        }
        <Form
          name="2fa"
          layout="vertical"
        >
          <Form.Item required>
            <Input
              size="large"
              placeholder="Authentication code from your authenticator app"
              prefix={ <SafetyOutlined /> }
              value={ token }
              onChange={ (e) =>  setToken(e.target.value) }
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              onClick={ (e) => onSubmit(e) }
              block={ true }
              loading={ loading }
              size="large"
            >
            Verify
            </Button>
          </Form.Item>
          <p className="text-center mt-6 dark:text-white">Lost access to your device? <NavLink to="/verify-2fa-recovery" className="text-indigo-800">Tap here to use a recovery code.</NavLink></p>
        </Form>
      </div>
    </div>
  );
}

