import { CheckCircleTwoTone, DeleteOutlined, EditOutlined, FolderOutlined, FrownTwoTone } from '@ant-design/icons';
import { Popover } from 'antd';
import React from 'react';

import { CategoriesAPI } from '../../app/api/endpoints/Categories';
import { confirmAlert, failedAlert, handleError, successAlert } from '../../helpers/Utils';
import { Category } from '../../models/Blog';
import ShowDate from '../Common/ShowDate';

interface CategoryCardProps {
  category: Category,
  editCategory: (arg: Category) => void
  onCategoryDeleted: (arg: number) => void
}

export default function CategoryCard ({ category, editCategory,onCategoryDeleted }: CategoryCardProps) {

  const destroy = (catregoryId: number) => {
    confirmAlert().then(async(res) => {
      if (res) {
        const { result } = await CategoriesAPI.destroyCategory(catregoryId, (error) => {
          handleError(error);
        });

        if (result) {
          if (result.status) {
            successAlert('Deleted successfully.');
            onCategoryDeleted(catregoryId);
          } else {
            failedAlert(result.message);
          }
          
        }
      }
    });
  };
  
  return (
    <div className="w-full flex flex-col bg-white rounded-md border p-2 min-w-[300px]">
      <div className="text-xl flex rounded-md p-2 space-x-2 items-center leading-1 min-h-[80px]">
        <FolderOutlined />
        <div className="flex flex-col ">
          <p className="truncate w-[220px]">
            { category.name }
            {
              category.status ?
                <Popover content="Published">
                  <CheckCircleTwoTone className="ml-1.5 text-base" twoToneColor="#52c41a"  key={ 0 }/>
                </Popover>
                :
                <Popover content="Unpublished">
                  <FrownTwoTone className="ml-1.5 text-base" twoToneColor="red" key={ 1 }/>
                </Popover>
            }
          </p>
        </div>
      </div>
      <div className="w-full p-2 flex flex-row justify-between">
        <div className="text-gray-500">
          <span><ShowDate date={ category.created_at } fromNow /></span>
        </div>
        <div>
          <span className="text-gray-700 mr-2" onClick={ () => editCategory(category) }>
            <span className="inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-sm font-medium text-gray-800 cursor-pointer">
              <EditOutlined className="mr-2" /> Edit
            </span>
          </span>
          <span className="text-gray-700" onClick={ () => destroy(category.id) }>
            <span className="inline-flex items-center rounded-full bg-red-100 px-3 py-0.5 text-sm font-medium text-red-800 cursor-pointer">
              <DeleteOutlined className="mr-2" /> Delete
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}
