import { SaveOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Row, Select } from 'antd';
import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProjectsAPI } from '../../app/api/endpoints/Projects';
import { useCan } from '../../app/can';
import HeaderTitle from '../../components/Parcial/HeaderTitle';
import { failedAlert, format422Error, handleError, successAlert } from '../../helpers/Utils';
import { Project } from '../../models/Project';

export default function CreateProject() {
  const can = useCan();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({} as any);
  const [inputs, setInputs] = useState({
    projectName: '',
    projectDescription: '',
    projectStatus: 'PUBLISHED',
    projectType: '',
    projectContentType: '',
  });


  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const onSelectStatusChange = (value: string) => {
    setInputs({ ...inputs, projectStatus: value });
  };

  const saveProject = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setValidationErrors({} as Project);
    const { result } = await ProjectsAPI.createProject(inputs,
      (error) => {
        if(error && error?.response && error.response.status === 422) {
          // @ts-ignore
          setValidationErrors(format422Error(error.response.data?.errors));
        } else {
          handleError(error);  
        }
        setLoading(false);
      });
    
    if (result) {
      if (result.status) {
        successAlert('Saved successfully.');
        navigate('/projects');
      } else {
        failedAlert(result.message);
      }
      setLoading(false);
    }
  };

  return (
    <div>
      <HeaderTitle
        onBack={ () => navigate('/projects') }
        title="Create a new project"
      />
      <Divider />
      {
        can('create', 'project') &&
        <Form
          layout="vertical"
        >
          <Row gutter={ 24 }>
            <Col span={ 12 }>
              <Form.Item
                label="Project Name:"
                name="projectName"
                rules={ [ { required: true } ] }
              >
                <Input
                  value={ inputs.projectName }
                  name="projectName"
                  size="large"
                  onChange={ (e) => handleInputChange(e) }
                />
                {
                  validationErrors && validationErrors.projectName && (
                    <span className="ant-form-item-explain-error">{ validationErrors.projectName }</span>
                  )
                }
              </Form.Item>
              <Form.Item
                label="Project Description:"
              >
                <Input.TextArea
                  rows={ 6 }
                  value={ inputs.projectDescription }
                  name="projectDescription"
                  onChange={ (e) => handleInputChange(e) }
                />
                {
                  validationErrors && validationErrors.projectDescription && (
                    <span className="ant-form-item-explain-error">{ validationErrors.projectDescription }</span>
                  )
                }
              </Form.Item>
            </Col>
            <Col span={ 12 }>
              <Form.Item
                label="Project Status:"
                required
              >
                <Select
                  value={ inputs.projectStatus }
                  style={{ width: '100%' }}
                  size="large"
                  onChange={ onSelectStatusChange }
                  options={ [
                    { value: 'PUBLISHED', label: 'PUBLISHED' }
                  ] }
                />
                {
                  validationErrors && validationErrors.description && (
                    <span className="ant-form-item-explain-error">{ validationErrors.description }</span>
                  )
                }
              </Form.Item>
              <Form.Item
                label="Project Type:"
                name="projectType"
                rules={ [ { required: true } ] }
              >
                <Input
                  value={ inputs.projectType }
                  name="projectType"
                  size="large"
                  onChange={ (e) => handleInputChange(e) }
                />
                {
                  validationErrors && validationErrors.projectType && (
                    <span className="ant-form-item-explain-error">{ validationErrors.projectType }</span>
                  )
                }
              </Form.Item>
              <Form.Item
                label="Project Content Type:"
                name="projectContentType"
                rules={ [ { required: true } ] }
              >
                <Input
                  value={ inputs.projectContentType }
                  name="projectContentType"
                  size="large"
                  onChange={ (e) => handleInputChange(e) }
                />
                {
                  validationErrors && validationErrors.projectContentType && (
                    <span className="ant-form-item-explain-error">{ validationErrors.projectContentType }</span>
                  )
                }
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button
              type="primary"
              onClick={ (e) => saveProject(e) }
              loading={ loading }
              size="large"
              icon={ <SaveOutlined /> }
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      }
    </div>
  );
}
