import { Button, Col, Divider, Input, Modal, Row, Select, Space } from 'antd';
import React, { ChangeEvent, useState } from 'react';

import { errorAlert } from '../../helpers/Utils';

export type PropertyType = {
  propertyName: string;
  propertyType: string;
  value: string;
  maxValue?: string;
}

interface ItemPropertyProps {
  showItemPropertyModal: boolean;
  setShowItemPropertyModal: (arg: boolean) => void;
  onPropertyAdded: (arg: PropertyType) => void
}

const propertyHelper: { [key: string]: { [key:string]: string} } = {
  'Textual': {
    desc: 'Textual traits that show up underneath your item.',
    namePlaceholder: 'e.g Color',
    valuePlaceholder: 'e.g Red',
  },
  'Levels': {
    desc: 'Numerical traits that show as a progress bar.',
    namePlaceholder: 'e.g Level',
    valuePlaceholder: 'e.g 1',
    maxValuePlaceholder: 'e.g 10',
  },
  'Stats': {
    desc: 'Numerical traits that show as numbers.',
    namePlaceholder: 'e.g Success Rate',
    valuePlaceholder: 'e.g 1',
    maxValuePlaceholder: 'e.g 10',
  },
  'Link': {
    desc: 'A link that is shown as a clickable URL.',
    namePlaceholder: 'e.g Docs',
    valuePlaceholder: 'e.g https//goall.io',
  },
};

export default function ItemProperty({ showItemPropertyModal, setShowItemPropertyModal, onPropertyAdded }: ItemPropertyProps) {
  const [property, setProperties] = useState<PropertyType>({
    propertyName: '',
    propertyType: 'Textual',
    value: '',
    maxValue: '',
  });

  const handleCancel = () => {
    setShowItemPropertyModal(false);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setProperties({ ...property, [e.target.name]: e.target.value });
  };

  const onSelectStatusChange = (value: string) => {
    setProperties({
      propertyName: '',
      value: '',
      maxValue: '',
      propertyType: value
    });
  };

  const addProperty = (closeable: boolean) => {
    if (property.propertyName === '') {
      errorAlert('Property name is required');
    } else if (property.value === '') {
      errorAlert('Property value is required');
    } else if (
      ['Stats', 'Levels'].includes(property.propertyType) &&
      (property.maxValue === '' ||
      isNaN(parseFloat(property.maxValue!)))
    ) {
      errorAlert('Max Value should be a valid number');
    } else {
      if (['Stats', 'Levels'].includes(property.propertyType) && isNaN(parseFloat(property.value!))) {
        errorAlert('Value should be a valid number');
      } else {
        if (property.maxValue === '') {
          delete property['maxValue'];
          onPropertyAdded(property);
          
        } else {
          onPropertyAdded(property);
        }
        setProperties({
          propertyName: '',
          propertyType: property.propertyType,
          value: '',
          maxValue: '',
        });
        if (closeable) {
          setShowItemPropertyModal(false);
        }
      }
    }
  };

  return (
    <Modal
      title="Add item property"
      open={ showItemPropertyModal }
      footer={ false }
      onCancel={ handleCancel }
    >
      <div className="mb-4">
        <p>Property Type</p>
        <Select
          value={ property.propertyType }
          style={{ width: '100%' }}
          size="large"
          onChange={ onSelectStatusChange }
          options={ [
            { value: 'Textual', label: 'Textual' },
            { value: 'Levels', label: 'Levels' },
            { value: 'Stats', label: 'Stats' },
            { value: 'Link', label: 'Link' },
          ] }
        />
        <p className="text-sm text-gray-400">{ propertyHelper[property.propertyType]['desc'] }</p>
      </div>
      <Divider/>
      <div className="mb-4">
        <p>Property Name</p>
        <Input
          value={ property.propertyName }
          name="propertyName"
          placeholder={ propertyHelper[property.propertyType]['namePlaceholder'] }
          size="large"
          onChange={ (e) => handleInputChange(e) }
        />
      </div>
      <div className="mb-4">
        <p>Value</p>
        <Row gutter={ 24 }>
          <Col span={ ['Stats', 'Levels'].includes(property.propertyType) ? 10 : 24 }>
            <Input
              value={ property.value }
              name="value"
              placeholder={ propertyHelper[property.propertyType]['valuePlaceholder'] }
              size="large"
              onChange={ (e) => handleInputChange(e) }
            />
          </Col>
          {
            ['Stats', 'Levels'].includes(property.propertyType) &&
              <>
                <Col span={ 4 }>
                  <p className="font-bold mt-2">out of</p>
                </Col>
                <Col span={ 10 }>
                  <Input
                    value={ property.maxValue }
                    name="maxValue"
                    placeholder={ propertyHelper[property.propertyType]['maxValuePlaceholder'] }
                    size="large"
                    onChange={ (e) => handleInputChange(e) }
                  />
                </Col>
              </>
          }
        </Row>
      </div>
      <Space>
        <Button
          type="primary"
          onClick={ () => addProperty(false) }
          size="large"
        >
          Add more
        </Button>
        <Button
          type="primary"
          onClick={ () => addProperty(true) }
          size="large"
        >
          Add & close
        </Button>
      </Space>
    </Modal>
  );
};
