import {
  ContactsFilled,
  ControlFilled,
  DashboardFilled,
  FolderFilled,
  FolderOpenFilled,
  FolderOutlined,
  GoldFilled,
  HomeFilled,
  ReadFilled,
  ReadOutlined,
  // SafetyOutlined,
  SettingFilled,
  WindowsFilled } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import React, { useEffect, useState }  from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';

import { useCan } from '../../app/can';
import { useAuth } from '../../providers/auth/AuthProvider';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  hasAccess?: boolean,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
  // @ts-expect-error if this function return empty
): MenuItem {
  if(hasAccess) {
    return {
      key,
      icon,
      children,
      label,
      type
    } as MenuItem;
  }
}

function Sidebar() {
  const can = useCan();
  const { profile } = useAuth();
  const location = useLocation();
  const { projectId } = useParams<{projectId: string}>();
  const [items, setItems] = useState<MenuProps['items']>([]);
  const [openKeys, setOpenKeys] = useState<string[]>([ 'home' ]);
  const [isSubMenu, setISubMenu] = useState<boolean>(false);

  const menuItems = [
    getItem(<NavLink to="/">Dashboard</NavLink>, 'home', true, <DashboardFilled />),

    // Manage users and roles
    getItem('Users & Roles', 'users', can('read', 'user'), <ContactsFilled />, [
      getItem(
        <NavLink to="/users">Users management</NavLink>, '5', can('read', 'user'),
        <FolderOpenFilled />
      ),
      getItem(
        <NavLink to="/roles">Roles management</NavLink>, '6', can('read', 'user'),
        <FolderOpenFilled />
      ),
    ]),

    // Manage Fivver Projects
    getItem(<NavLink to="/projects">My Projects</NavLink>, 'projects', can('read', 'project'), <WindowsFilled />),

    // Manage Blog
    getItem('Blog', 'blog', can('read', 'user'), <ReadFilled />, [
      getItem(
        <NavLink to="/blog/posts">Posts</NavLink>, 'posts', can('read', 'user'),
        <ReadOutlined />
      ),
      getItem(
        <NavLink to="/blog/categories">Categories</NavLink>, 'cats', can('read', 'user'),
        <FolderOutlined />
      ),
    ]),

    // Media
    // getItem('Media', 'media', can('read', 'user'), <FolderFilled />),

    // Settings
    getItem('Settings', 'settings', can('read', 'user'), <SettingFilled />),
  ];

  const subMenuItems = [
    getItem(<NavLink to="/projects">My Projects</NavLink>, 'projects', can('read', 'project'), <FolderFilled />),
    getItem(<NavLink to={ `/project/${projectId}` }>Overview</NavLink>, 'overview', can('read', 'project'), <HomeFilled />),
    // getItem(
    //   <NavLink to={ `/project/${projectId}/clubs` }>Clubs</NavLink>,
    //   'clubs', can('read', 'project') && can('read', 'clubs'),
    //   <SafetyOutlined />
    // ),
    getItem(
      <NavLink to={ `/project/${projectId}/collections` }>Collections</NavLink>,
      'collections', can('read', 'project') && can('read', 'collection'),
      <GoldFilled />
    ),
    getItem(
      <NavLink to={ `/project/${projectId}/monitoring` }>Monitoring</NavLink>,
      'monitoring', can('read', 'project'),
      <ControlFilled />
    ),
    getItem(
      <NavLink to={ `/project/${projectId}/settings` }>Settings</NavLink>,
      'settings', can('read', 'project'),
      <SettingFilled />
    ),
  ];

  useEffect(() => {
    setISubMenu(/\/project\/pr_*/.test(location.pathname));
  }, [ location ]);

  useEffect(() => {
    isSubMenu ? setItems(subMenuItems) : setItems(menuItems);
  }, [profile, isSubMenu]);

  const rootSubmenuKeys = ['home', 'users', 'projects', 'blog', 'settings'];

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [ latestOpenKey ] : []);
    }
  };

  return (
    <>
      <div style={{ marginTop: 25 }}/>
      <Menu
        mode="inline"
        openKeys={ openKeys }
        defaultSelectedKeys={ [ 'home' ] }
        defaultOpenKeys={ [ 'home' ] }
        items={ items }
        onOpenChange={ onOpenChange }
      />
    </>
  );
}

export default Sidebar;
