import { SafetyOutlined } from '@ant-design/icons';
import { Alert,Button, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import Logo from '../../assets/img/logo.svg';
import { useAuth } from '../../providers/auth/AuthProvider';

export default function TwoFactorRecovery() {
  const navigate = useNavigate();
  const { loading, twoFactorLogin, error, setError, isConnected } = useAuth();
  const [recoveryCode, setRecoveryCode] = useState<string>('');

  const onSubmit = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setError(undefined);
    if (recoveryCode !== '') {
      await twoFactorLogin(null, recoveryCode);
    } else {
      setError('Recovery code is required.');
    }
  };

  useEffect(() => {
    isConnected && navigate('/');
  }, [ isConnected ]);

  return (
    <div className="login-page">
      <div className="login-box ">
        <div className="header">
          <div className="flex justify-center">
            <img src={ Logo } style={{ width: 200 }} alt="Goall" />
          </div>
          <p className="mb-6">
            Verify your account
          </p>
        </div>
        {
          error &&
        <Alert className="m-b-15" message={ error } type="error" showIcon />
        }
        <Form
          name="login"
          layout="vertical"
        >
          <Form.Item required>
            <p className="text-sm mb-3 dark:text-white">Enter a recovery code to verify your account. Once you use a recovery code, it can&apos;t be used again.</p>
            <Input
              size="large"
              placeholder="Recovery code here"
              prefix={ <SafetyOutlined /> }
              value={ recoveryCode }
              onChange={ (e) => setRecoveryCode(e.target.value) }
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              onClick={ (e) => onSubmit(e) }
              block={ true }
              loading={ loading }
              size="large"
            >
              Verify
            </Button>
          </Form.Item>
          <p className="text-center mt-6 dark:text-white"><NavLink to="/verify-2fa" className="text-indigo-700">Use Authentication App Instead</NavLink></p>
        </Form>
      </div>
    </div>
  );
}

