import {
  DeleteOutlined,
  EditOutlined,   PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Input, Row, Space,Table } from 'antd';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { RolesAPI } from '../../app/api/endpoints/Roles';
import { useCan } from '../../app/can';
import ShowDate from '../../components/Common/ShowDate';
import HeaderTitle from '../../components/Parcial/HeaderTitle';
import { confirmAlert, failedAlert, handleError, successAlert } from '../../helpers/Utils';
import { Role } from '../../models/User';

const { Search } = Input;


const Roles: React.FC = (): JSX.Element => {
  const can = useCan();
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState<Role[]>([]);
  const [search, setSearch] = useState<string|undefined>(undefined);
  const [searchLoading, setSearchLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 1,
    total: 1
  });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      render: (name: string, record: { id: React.Key }) => (
        <>
          {
            can('update', 'role') ?
              <NavLink
                to={ `/roles/${record.id}/edit` }
              >
                { name }
              </NavLink>
              :
              <span>{ name }</span>
          }
        </>
      )
    },
    {
      title: 'Description',
      dataIndex: 'description'
    },
    {
      title: 'Creation date',
      dataIndex: 'created_at',
      sorter: true,
      render: (created_at: Date) => (
        <ShowDate date={ created_at }/>
      )
    },
  ];

  useEffect(() => {
    getRoles({ pagination });
  }, []);

  const getParams = (params: any) => ({
    query: params.query,
    page: params.pagination?.current,
    sort_field: params.sortField,
    sort_order: params.sortOrder,
    ...params.filters
  });

  const onSelectChange = (selectedRowKeys: number[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const searchRole = async (query: string) => {
    setSearchLoading(true);
    const { result } = await RolesAPI.getAllRoles(`?query=${query}`, (error) => {
      handleError(error);
      setSearchLoading(false);
    });

    if (result) {
      if (result.status) {
        setRoles(result.roles.data);
        setPagination( {
          current: result.roles.meta.current_page,
          pageSize: result.roles.meta.per_page,
          total: result.roles.meta.total
        });
      } 
      setSearchLoading(false);
    }
  };

  const getRoles = async (params = {}) => {
    setLoading(true);
    const { result } = await RolesAPI.getAllRoles(`?${qs.stringify(getParams(params), { skipNulls: true })}`,
      (error) => {
        handleError(error);
        setLoading(false);
      });

    if (result) {
      if (result.status) {
        setRoles(result.roles.data);
        setPagination( {
          current: result.roles.meta.current_page,
          pageSize: result.roles.meta.per_page,
          total: result.roles.meta.total
        });
      } 
      setLoading(false);
    }
  };

  const handleTableChange = (
    newPagination: any,
    filters: any,
    sorter: any,
  ) => {
    getRoles({
      query: search,
      sortField: sorter.field as string,
      sortOrder: sorter.order as string,
      pagination: newPagination,
      filters: filters,
    });
  };

  const destroy = () => {
    confirmAlert().then(async (res) => {
      if (res) {
        const { result } = await RolesAPI.destroy(selectedRowKeys,  (error) => {
          handleError(error);
        });
        if (result) {
          if (result.status) {
            setRoles(roles.filter((role: Role) => !selectedRowKeys.includes(role.id)));
            setSelectedRowKeys([]);
            successAlert('Deleted successfully.');
          } else {
            failedAlert(result.message);
          }
        }
      }
    });
  };

  return (
    <div>
      <HeaderTitle
        onBack={ () => history.back() }
        title="List of roles"
      />
      <Divider />
      {
        can('read', 'role') &&
        <div>
          <Row>
            <Col span={ 12 }>
              <Search
                placeholder="Search by role name..."
                value={ search }
                loading={ searchLoading }
                enterButton
                allowClear
                onChange={ (e) => setSearch(e.target.value) }
                onSearch={ (e) => searchRole(e) }
              />
            </Col>
            <Col span={ 12 } style={{ textAlign: 'right' }}>
              <Space>
                {
                  can('create', 'role') &&
                  <NavLink
                    to="/roles/create"
                  >
                    <Button type="primary" icon={ <PlusCircleOutlined /> } >Create</Button>
                  </NavLink>
                }
              </Space>
            </Col>
          </Row>
          <Divider />
          {
            selectedRowKeys.length>0 &&
            <Row className="m-b-15">
              <Col span={ 12 }>
                { selectedRowKeys.length } row{ selectedRowKeys.length>1?'s':'' } selected
              </Col>
              <Col span={ 12 } style={{ textAlign: 'right' }}>
                <Space>
                  {
                    can('update', 'role') &&
                    <NavLink
                      to={ `/roles/${selectedRowKeys[0]}/edit` }
                    >
                      <Button type="primary" icon={ <EditOutlined /> } >Edit</Button>
                    </NavLink>
                  }
                  {
                    can('delete', 'role') &&
                    <>
                      <Button
                        type="primary"
                        danger
                        icon={ <DeleteOutlined /> }
                        onClick={ destroy }
                      >
                        Delete
                      </Button>
                    </>
                  }
                </Space>
              </Col>
            </Row>
          }
          <Table
            loading={ loading }
            rowKey={ role => role.id }
            // @ts-ignore
            rowSelection={ rowSelection }
            columns={ columns }
            dataSource={ roles }
            pagination={ pagination }
            size="small"
            onChange={ handleTableChange }
          />
        </div>
      }
    </div>
  );
};

export default Roles;
