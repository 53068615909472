import { SaveOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Row, Select, Spin } from 'antd';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ProjectsAPI } from '../../app/api/endpoints/Projects';
import { useCan } from '../../app/can';
import HeaderTitle from '../../components/Parcial/HeaderTitle';
import { failedAlert, format422Error, handleError, successAlert } from '../../helpers/Utils';
import { Project } from '../../models/Project';

export default function EditProject() {
  const can = useCan();
  const navigate = useNavigate();
  const { projectId } = useParams<{projectId: string}>();
  const [loading, setLoading] = useState(false);
  const [loadingProject, setLoadingProject] = useState(false);
  const [project, setProject] = useState<Project>();
  const [validationErrors, setValidationErrors] = useState({} as any);
  const [inputs, setInputs] = useState({
    projectName: '',
    projectDescription: '',
    projectStatus: 'PUBLISHED',
    projectType: '',
    projectContentType: '',
  });

  useEffect(() => {
    if (projectId) {
      getProject();
    }
  }, [ projectId ]);

  useEffect(() => {
    if (project) {
      setInputs({
        projectName: project.projectName,
        projectDescription: project.projectDescription,
        projectStatus: project.projectStatus,
        projectType: project.projectType,
        projectContentType: project.projectContentType,
      });
    }
  }, [ project ]);

  const getProject = async () => {
    setLoadingProject(true);
    const { result } = await ProjectsAPI.getProject(projectId, (error) => {
      handleError(error);
      setLoadingProject(false);
    });

    if (result) {
      if (result.status) {
        setProject(result.project);
      } else {
        failedAlert(result.message);
        navigate('/projects');
      }
      setLoadingProject(false);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const onSelectStatusChange = (value: string) => {
    setInputs({ ...inputs, projectStatus: value });
  };

  const updateProject = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const { result } = await ProjectsAPI.updateProject(projectId, inputs,
      (error) => {
        if(error && error?.response && error.response.status === 422) {
          // @ts-ignore
          setValidationErrors(format422Error(error.response.data?.errors));
        } else {
          handleError(error);  
        }
        setLoading(false);
      });
    
    if (result) {
      if (result.status) {
        successAlert('Updated successfully.');
        navigate('/projects');
      } else {
        failedAlert(result.message);
      }
      setLoading(false);
    }
  };

  return (
    <div>
      <HeaderTitle
        onBack={ () => navigate('/projects') }
        title="Update project"
      />
      <Divider />
      {
        can('update', 'project') &&
        <Spin tip="Loading project..." spinning={ loadingProject } wrapperClassName="w-full">
          <Form
            layout="vertical"
          >
            <Row gutter={ 24 }>
              <Col span={ 12 }>
                <Form.Item
                  label="Project Name:"
                  rules={ [ { required: true } ] }
                >
                  <Input
                    value={ inputs.projectName }
                    name="projectName"
                    size="large"
                    onChange={ (e) => handleInputChange(e) }
                  />
                  {
                    validationErrors && validationErrors.projectName && (
                      <span className="ant-form-item-explain-error">{ validationErrors.projectName }</span>
                    )
                  }
                </Form.Item>
                <Form.Item
                  label="Project Description:"
                >
                  <Input.TextArea
                    value={ inputs.projectDescription }
                    rows={ 6 }
                    name="projectDescription"
                    onChange={ (e) => handleInputChange(e) }
                  />
                  {
                    validationErrors && validationErrors.projectDescription && (
                      <span className="ant-form-item-explain-error">{ validationErrors.projectDescription }</span>
                    )
                  }
                </Form.Item>
              </Col>
              <Col span={ 12 }>
                <Form.Item
                  label="Project Status:"
                >
                  <Select
                    value={ inputs.projectStatus }
                    style={{ width: '100%' }}
                    size="large"
                    onChange={ onSelectStatusChange }
                    options={ [
                      { value: 'PUBLISHED', label: 'PUBLISHED' }
                    ] }
                  />
                  {
                    validationErrors && validationErrors.description && (
                      <span className="ant-form-item-explain-error">{ validationErrors.description }</span>
                    )
                  }
                </Form.Item>
                <Form.Item
                  label="Project Type:"
                  rules={ [ { required: true } ] }
                >
                  <Input
                    value={ inputs.projectType }
                    name="projectType"
                    size="large"
                    onChange={ (e) => handleInputChange(e) }
                  />
                  {
                    validationErrors && validationErrors.projectType && (
                      <span className="ant-form-item-explain-error">{ validationErrors.projectType }</span>
                    )
                  }
                </Form.Item>
                <Form.Item
                  label="Project Content Type:"
                  rules={ [ { required: true } ] }
                >
                  <Input
                    value={ inputs.projectContentType }
                    name="projectContentType"
                    size="large"
                    onChange={ (e) => handleInputChange(e) }
                  />
                  {
                    validationErrors && validationErrors.projectContentType && (
                      <span className="ant-form-item-explain-error">{ validationErrors.projectContentType }</span>
                    )
                  }
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button
                type="primary"
                onClick={ (e) => updateProject(e) }
                loading={ loading }
                size="large"
                icon={ <SaveOutlined /> }
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      }
    </div>
  );
}
