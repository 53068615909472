import { SaveOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Row, Spin } from 'antd';
// @ts-ignore
import groupBy from 'lodash/groupBy';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { RolesAPI } from '../../app/api/endpoints/Roles';
import { useCan } from '../../app/can';
import HeaderTitle from '../../components/Parcial/HeaderTitle';
import { failedAlert, format422Error, handleError, slugify, successAlert } from '../../helpers/Utils';
import { Permission, Role } from '../../models/User';

const CreateRole = () => {
  const can = useCan();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [validationErrors, setValidationErrors] = useState({} as any);
  const [inputs, setInputs] = useState({
    name: '',
    slug: '',
    description: ''
  });
  const [checkedPermissions, setCheckedPermissions] = useState<string[]>([]);

  useEffect(() => {
    getPermissions();
  }, []);

  const getPermissions = async () => {
    setLoading(true);
    setCheckedPermissions([]);
    const { result } = await RolesAPI.getAllPermissions(
      (error) => {
        handleError(error);
        setLoading(false);
      });

    if (result) {
      if (result.status) {
        const per = result.permissions.map((p: Permission) => {
          const title = p.slug.split('-')[1];
          return {
            title: title,
            permission: p
          };
        });
        const groupedPer = groupBy(per, function(n: any) {
          return n.title;
        });
        // @ts-ignore
        setPermissions(groupedPer);
      } 
      setLoading(false);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.name === 'name') {
      setInputs(
        {
          ...inputs,
          name: e.target.value,
          slug: slugify(e.target.value),
        }
      );
    } else {
      setInputs({ ...inputs, [e.target.name]: e.target.value });
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedCheckboxes = [ ...checkedPermissions ];
    const isChecked = selectedCheckboxes.includes(event.target.value);
    if (!isChecked) {
      selectedCheckboxes.push(event.target.value);
    } else {
      selectedCheckboxes.splice(selectedCheckboxes.indexOf(event.target.value), 1);
    }
    setCheckedPermissions(selectedCheckboxes);
  };

  const saveRole = async (e: FormEvent) => {
    e.preventDefault();
    setLoadingSubmit(true);
    setValidationErrors({} as Role);
    const data = {
      ...inputs,
      permissions: checkedPermissions
    };
    const { result } = await RolesAPI.store(data,
      (error) => {
        if(error && error?.response && error.response.status === 422) {
          // @ts-ignore
          setValidationErrors(format422Error(error.response.data?.errors));
        } else {
          handleError(error);
        }
        setLoadingSubmit(false);
      });
    
    if (result) {
      if (result.status) {
        successAlert('Saved successfully.');
        navigate('/roles');
      } else {
        failedAlert(result.message);
      }
      setLoadingSubmit(false);
    }
  };

  return (
    <>
      <HeaderTitle
        onBack={ () => navigate('/roles') }
        title="Create new role"
      />
      <Divider />
      {
        can('create', 'role') &&
          <Spin tip="Loading..." spinning={ loading }>
            <Row>
              <Col span={ 24 }>
                <Form
                  layout="vertical"
                >
                  <Row gutter={ 24 }>
                    <Col span={ 12 }>
                      <Form.Item
                        label="Name:"
                        rules={ [ { required: true } ] }
                      >
                        <Input
                          value={ inputs.name }
                          name="name"
                          size="large"
                          onChange={ (e) => handleInputChange(e) }
                        />
                        {
                          validationErrors && validationErrors.name && (
                            <span className="ant-form-item-explain-error">{ validationErrors.name }</span>
                          )
                        }
                      </Form.Item>
                      <Form.Item
                        label="Slug:"
                        rules={ [ { required: true } ] }
                      >
                        <Input
                          value={ inputs.slug }
                          name="slug"
                          size="large"
                          onChange={ (e) => handleInputChange(e) }
                        />
                        {
                          validationErrors && validationErrors.slug && (
                            <span className="ant-form-item-explain-error">{ validationErrors.slug }</span>
                          )
                        }
                      </Form.Item>
                      <Form.Item
                        label="Description:"
                      >
                        <Input.TextArea
                          value={ inputs.description }
                          name="description"
                          onChange={ (e) => handleInputChange(e) }
                        />
                        {
                          validationErrors && validationErrors.description && (
                            <span className="ant-form-item-explain-error">{ validationErrors.description }</span>
                          )
                        }
                      </Form.Item>
                    </Col>
                    <Col span={ 12 }>
                      <Form.Item
                        label="Permissions:"
                        rules={ [ { required: true } ] }
                      >
                        <table className="table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th className="bg-grey">Manage</th>
                              <th className="bg-grey text-center" colSpan={ 4 }>Permissions</th>
                            </tr>
                          </thead>
                          <tbody>
                            { Object.keys(permissions).map((key) => (
                              <tr key={ key }>
                                <td className="bg-grey">Manage { key }</td>
                                {
                                  // @ts-ignore
                                  permissions[key].map((p) => (
                                    <td key={ p.permission.id }>
                                      <div key={ p.permission.id } className="form-check">
                                        <input
                                          className="form-check-input"
                                          name={ p.permission.name }
                                          type="checkbox"
                                          value={ p.permission.id }
                                          onChange={ (e) => handleChange(e) }
                                        />
                                        <label className="form-check-label">
                                          <span className="text-capitalize">{ p.permission.slug.split('-')[0] }</span>
                                        </label>
                                      </div>
                                    </td>
                                  ))
                                }
                              </tr>
                            ))
                            }
                          </tbody>
                        </table>
                        {
                          validationErrors && validationErrors.permissions && (
                            <span className="error">{ validationErrors.permissions[0] }</span>
                          )
                        }
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item>
                    <Button
                      type="primary"
                      onClick={ (e) => saveRole(e) }
                      loading={ loadingSubmit }
                      size="large"
                      icon={ <SaveOutlined /> }
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Spin>
      }
    </>
  );
};

export default CreateRole;
