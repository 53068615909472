import {
  DeleteOutlined, EditOutlined,
  EyeFilled, EyeInvisibleFilled, SyncOutlined } from '@ant-design/icons';
import { Button, Spin, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { ItemsAPI } from '../../app/api/endpoints/Items';
import { useCan } from '../../app/can';
import ShowDate from '../../components/Common/ShowDate';
import { confirmAlert, failedAlert, handleError, successAlert } from '../../helpers/Utils';
import { LocalItem } from '../../models/Item';

interface ItemProps {
  collectionId: string | undefined;
  projectId: string | undefined;
  clubId: string | undefined;
}

export default function Items({ collectionId, projectId }: ItemProps) {
  const can = useCan();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSync, setLoadingSync] = useState(false);
  const [items, setItems] = useState<LocalItem[]>([]);

  useEffect(() => {
    if(collectionId) {
      getItems();
    }
  }, [ collectionId ]);

  const getItems = async () => {
    setLoading(true);
    const { result } = await ItemsAPI.getAllItems(collectionId, (error) => {
      handleError(error);
      setLoading(false);
    });

    if (result) {
      if (result.status) {
        setItems(result.items);
      } 
      setLoading(false);
    }
  };

  const syncItems = async () => {
    setLoadingSync(true);
    const { result } = await ItemsAPI.syncItems(collectionId, (error) => {
      setLoadingSync(false);
      handleError(error);
    });

    if (result) {
      setLoadingSync(false);
      if (result.status) {
        getItems();
      }
    }
  };

  const updateItemVisibility = async (itemId: string, itemVisibility: boolean) => {
    setLoading(true);
    const { result } = await ItemsAPI.updateItemVisibility(collectionId, itemId, { itemVisibility: itemVisibility },
      (error) => {
        if(error && error?.response && error.response.status === 422) {
          // @ts-ignore
          setValidationErrors(format422Error(error.response.data?.errors));
        } else {
          handleError(error);  
        }
        setLoading(false);
      });
    
    if (result) {
      if (result.status) {
        successAlert('Updated successfully.');
        const newItems = items.filter(item => item.item_id !== itemId);
        setItems([
          ...newItems,
          result.item
        ]);
      } else {
        failedAlert(result.message);
      }
      setLoading(false);
    }
  };

  const destroy = async (itemId: string | undefined) => {
    confirmAlert().then(async (res) => {
      if (res) {
        setLoading(true);
        const { result } = await ItemsAPI.destroyItem(collectionId, itemId, (error) => {
          setLoading(false);
          handleError(error);
        });
        if (result) {
          setLoading(false);
          if (result.status) {
            successAlert('Item Deleted successfully.');
            setItems(items.filter((item) => item.item_id !== itemId));
          } else {
            failedAlert(result.message);
          }
        }
      }
    });
  };

  const columns = [
    {
      title: 'Image',
      dataIndex: 'item_image',
      render: (itemImage: string) => (
        <div className="h-10 w-10">
          <img
            className="w-full h-full object-cover"
            src={ itemImage }
          />
        </div>
      )
    },
    {
      title: 'Item Name',
      dataIndex: 'item_name'
    },
    {
      title: 'Visibility',
      dataIndex: 'visibility',
      render: (visibility: string, record: { item_id: React.Key }) => (
        <>
          {
            visibility ?
              <EyeFilled className="text-xl" onClick={ () => updateItemVisibility(record.item_id.toString(), false) }/> :
              <EyeInvisibleFilled className="text-xl" onClick={ () => updateItemVisibility(record.item_id.toString(), true) }/>
          }
        </>
      )
    },
    {
      title: 'Creation date',
      dataIndex: 'created_at',
      render: (createdAt: Date) => (
        <ShowDate date={ createdAt }/>
      )
    },
    {
      title: 'Actions',
      className: 'text-right',
      render: (record: { item_id: React.Key }) => (
        can('update', 'item') &&
          <div className="w-full flex justify-end gap-1">
            <NavLink
              to={ `/project/${projectId}/collections/${collectionId}/items/${record.item_id}/update` }
            >
              <Button type="primary" shape="circle" icon={ <EditOutlined /> } />
            </NavLink>
            <Button type="ghost" className="!bg-red-700 hover:!bg-red-500" shape="circle" icon={ <DeleteOutlined className="text-white" /> } onClick={ () => destroy(record.item_id.toString()) } />
          </div>
      )
    },
  ];

  return (
    <div>
      <Spin tip="Loading items" spinning={ loading }>
        <div className="flex mb-4">
          {
            can('read', 'collection') && can('create', 'collection') &&
            <Button
              loading={ loadingSync }
              onClick={ syncItems }
              className="!text-white !bg-green-700 hover:bg-green-900"
              size="large"
            >
              { !loadingSync && <SyncOutlined className="pr-2"/> }
              Sync Items from Fever App
            </Button>
          }
        </div>

        {
          items && items.length > 0 ? (
            <Table
              loading={ loading }
              rowKey={ item => item.item_id }
              columns={ columns }
              // @ts-ignore
              dataSource={ items }
              size="small"
            />
          ) : (
            <div className="text-center text-lg text-ft-lt font-medium h-full py-8">
            You have nothing to show for now, Create your first items
            </div>
          )
        }
      </Spin>
    </div>
  );
}
