import { SaveOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Divider, Empty, Form, Modal, Row, Space, Spin } from 'antd';
import type { CheckboxChangeEvent, CheckboxOptionType } from 'antd/es/checkbox';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { UsersAPI } from '../../app/api/endpoints/Users';
import { handleError, successAlert } from '../../helpers/Utils';
import { Role, User } from '../../models/User';

const CheckboxGroup = Checkbox.Group;

interface ChangeRoleProps {
  user: User;
  showChangeRoleModal: boolean;
  setShowChangeRoleModal: (arg: boolean) => void;
  onRoleChange: () => void;
}
export default function ChangeRole({ user, showChangeRoleModal, setShowChangeRoleModal, onRoleChange }: ChangeRoleProps) {
  const[loading, setLoading] = useState<boolean>(false);
  const[loadingRoles, setLoadingRoles] = useState<boolean>(false);
  const[roles, setRoles] = useState<CheckboxOptionType[]>([]);
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  useEffect(() => {
    if (showChangeRoleModal) {
      getRoles();
    }
  }, [ showChangeRoleModal ]);

  useEffect(() => {
    if (user) {
      setCheckedList(user.roles.map(r => r.id));
    }
  }, [ user ]);

  const getRoles = async () => {
    setLoadingRoles(true);
    const { result } = await UsersAPI.getAllRoles((error) => {
      handleError(error);
      setLoadingRoles(false);
    });

    if (result) {
      if (result.status) {
        setRoles(result.roles.map((role: Role) => {
          return {
            label: role.name,
            value: role.id
          };
        }));
      } 
      setLoadingRoles(false);
    }
  };

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < roles.length);
    setCheckAll(list.length === roles.length);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? roles.map(r => r.value) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const handleCloseChangeRoleModal = () => {
    setShowChangeRoleModal(false);
  };

  const onSubmit = async () => {
    setLoading(true);
    const { result } = await UsersAPI.updateRole(user?.id , checkedList as string[], (error) => {
      handleError(error);
      setLoading(false);
    });

    if (result) {
      if (result.status) {
        successAlert('Role updated successfully.');
        onRoleChange();
        handleCloseChangeRoleModal();
      } 
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Change user role"
      open={ showChangeRoleModal }
      footer={ null }
      onCancel={ handleCloseChangeRoleModal }
    >
      <Divider />
      <Spin tip="Loading roles..." spinning={ loadingRoles }>
        <Form
          layout="vertical"
        >
          {
            roles.length > 0 ? (
              <Row gutter={ [0, 20] } className="m-b-20">
                <Col span={ 24 }>
                  <Checkbox indeterminate={ indeterminate } onChange={ onCheckAllChange } checked={ checkAll }>
                    Check all
                  </Checkbox>
                  <Divider />
                  <CheckboxGroup options={ roles } value={ checkedList } onChange={ onChange } />
                </Col>
                <Col span={ 24 }>
                  <Divider className="m-t-0"/>
                  <Space.Compact>
                    <Button
                      type="primary"
                      loading={ loading }
                      icon={ <SaveOutlined /> }
                      onClick={ onSubmit }
                    >
                      Update
                    </Button>
                  </Space.Compact>
                </Col>
              </Row>
            ) : (
              <Empty description="No roles exists">
                <NavLink to="/roles/create">Create New</NavLink>
              </Empty>
            )
          }
        </Form>
      </Spin>
    </Modal>
  );
}
