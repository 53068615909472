import { AxiosError } from 'axios';

import { RequestWrapper } from '../../../helpers/RequestWrapper';
import { PasswordRequestType } from '../../../models/User';
import API from '..';

const USER_ENDPOINT = 'account';

class UserAPI {

  static Profile = class {
    static get = (errorHandler: (error: Error) => void) => {
      return RequestWrapper(
        async () => 
          (await API.get(`${USER_ENDPOINT}/profile`)).data, errorHandler
      );
    };
    static updatePassword = (inputs: PasswordRequestType, errorHandler: (error: AxiosError) => void) => {
      return RequestWrapper(
        async () => 
          (await API.post(`${USER_ENDPOINT}/update-password`, inputs)).data, errorHandler
      );
    };
  };

  static Notifications = class {
    static getAll = (errorHandler: (error: Error) => void) => {
      return RequestWrapper(
        async () => {
          const notifications = await API.get(`${USER_ENDPOINT}/notifications`);
          return notifications?.data;
        }, 
        errorHandler
      );
    };

    static markAsRead = (notificationId: number, errorHandler: (error: Error) => void) => {   
      return RequestWrapper(
        async () => {
          const response = await API.get(`${USER_ENDPOINT}/notification/mark-as-read/${notificationId}`);
          return response.data;
        },
        errorHandler
      );
    };
  };

}

export { USER_ENDPOINT, UserAPI };
