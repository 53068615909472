import { CloseCircleOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { errorAlert, validateImage, validateImageDimensions } from '../../helpers/Utils';

interface ImageUploadProps {
  defaultUrl?: string;
  onImageChange: (arg: File | undefined) => void;
}

export default function ImageUpload({ onImageChange, defaultUrl }: ImageUploadProps) {
  const [imageUrl, setImageUrl] = useState<string | undefined>();

  const onDrop = useCallback(async (acceptedImages: File[]) => {
    const { valid, error } = validateImage(acceptedImages[0]);
    if(!valid && error) {
      errorAlert(error);
    } else {
      const validDimension = await validateImageDimensions(acceptedImages[0], 1200, 675);
      if(!validDimension) {
        errorAlert('Image dimensions should be minimum 1200px width and 675px height.');
      } else {
        onImageChange(acceptedImages[0]);
        getBase64(acceptedImages[0], (url) => {
          setImageUrl(url);
        });
      }
    }
  }, []);

  useEffect(() => {
    if (defaultUrl) {
      setImageUrl(defaultUrl);
    }
  }, [ defaultUrl ]);

  const removeFile = () => {
    onImageChange(undefined);
    setImageUrl(undefined);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/jpg': [],
      'image/png': []
    },
    maxFiles: 1,
    onDrop
  });

  const getBase64 = (img: File, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  return (
    <>
      {
        imageUrl ? (
          <div className="border-2 border-dashed border-gray-400 bg-gradient-to-b from-black to-transparent text-center cursor-pointer h-40 relative">
            <span className="absolute top-0 right-0 px-1 pt-1 bg-black bg-opacity-40">
              <CloseCircleOutlined
                className="text-lg text-white"
                onClick={ removeFile }
              />
            </span>
            <div className="overflow-hidden flex justify-center w-full h-full">
              <img
                className="h-full object-cover"
                src={ imageUrl }
              />
            </div>
          </div>
        ) : (
          <div { ...getRootProps() } className="border-2 border-dashed border-gray-400 bg-gray-200 py-4 px-3 text-center cursor-pointer">
            <input { ...getInputProps() } />
            {
              isDragActive ?
                <>
                  <p>Drop the image here ...</p>
                  <p>(Only *.jpeg, *.jpg and *.png images will be accepted)</p>
                </> :
                <>
                  <p>Drag & drop image here, or click to select image</p>
                  <p>(Only *.jpeg, *.jpg and *.png images will be accepted)</p>
                </>
            }
            <p className="font-bold text-xs mt-2">Recommended dimensions 1200x675 pixels</p>
          </div>
                       
        )
      }
    </>
  );
};
