import { AxiosError } from 'axios';

import { RequestWrapper } from '../../../helpers/RequestWrapper';
import API from '..';

const CATEGORIES_ENDPOINT = 'dash/categories';

class CategoriesAPI {
  static getAllCategories = (errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.get(`${CATEGORIES_ENDPOINT}`)).data, errorHandler
    );
  };

  static getCategory = (categoryId: number | undefined, errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.get(`${CATEGORIES_ENDPOINT}/${categoryId}/show`)).data, errorHandler
    );
  };

  static createCategory = (data: any, errorHandler: (error: AxiosError) => void) => {
    return RequestWrapper(
      async () => 
        (await API.post(`${CATEGORIES_ENDPOINT}/store`, data)).data, errorHandler
    );
  };

  static updateCategory = (categoryId: number | undefined, data: any, errorHandler: (error: AxiosError) => void) => {
    return RequestWrapper(
      async () => 
        (await API.put(`${CATEGORIES_ENDPOINT}/${categoryId}/update`, data)).data, errorHandler
    );
  };

  static destroyCategory = (categoryId: number | undefined, errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.delete(`${CATEGORIES_ENDPOINT}/${categoryId}/destroy`)).data, errorHandler
    );
  };
}

export { CATEGORIES_ENDPOINT, CategoriesAPI };
