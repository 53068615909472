import {
  EditOutlined,
  EyeFilled,
  EyeInvisibleFilled
} from '@ant-design/icons';
import { Spin } from 'antd';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { CollectionsAPI } from '../../app/api/endpoints/Collections';
import { failedAlert, handleError, successAlert } from '../../helpers/Utils';
import { LocalCollection } from '../../models/Collection';
;

interface CollectionCardProps {
  collection: LocalCollection,
  projectId: string | undefined
}

export default function CollectionCard ({ collection, projectId }: CollectionCardProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [isCollectionVisible, setIsCollectionVisible] = useState<boolean>(collection.visibility);

  const updateCollectionVisibility = async (collectionVisibility: boolean) => {
    setLoading(true);
    const { result } = await CollectionsAPI.updateCollectionVisibility(collection?.collection_id,
      { collectionVisibility: collectionVisibility },
      (error) => {
        if(error && error?.response && error.response.status === 422) {
          // @ts-ignore
          setValidationErrors(format422Error(error.response.data?.errors));
        } else {
          handleError(error);  
        }
        setLoading(false);
      });
    
    if (result) {
      if (result.status) {
        successAlert('Visibility updated successfully.');
        setIsCollectionVisible(result.collection?.visibility);
      } else {
        failedAlert(result.message);
      }
      setLoading(false);
    }
  };
  
  return (
    <Spin tip="Updating..." spinning={ loading }>
      <div className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
        <div className="flex w-full items-center justify-between space-x-6 p-6">
          <div className="flex-1 truncate">
            <div className="flex items-center space-x-3">
              <NavLink className="truncate text-sm font-medium text-gray-900" to={ `/project/${projectId}/collections/${collection?.collection_id}` }>
                { collection?.collection_name }
              </NavLink>
              <div className="flex items-center justify-center">
                {
                  collection?.config?.complete && collection?.config?.deployement ? (
                    <div className="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium capitalize ring-1 ring-inset text-yellow-800  bg-yellow-50 ring-yellow-600/20">
                      { collection.config.deployement.status }
                    </div>
                  ) : (
                    <div className="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium capitalize ring-1 ring-inset bg-gray-50 text-gray-600 ring-gray-500/10">
                      Unsettled
                    </div>
                  )
                }
              </div>
            </div>
          </div>
          <NavLink to="/project/pr_2PMnNJ6zCak6afCXppnZoLVHBFv/collections/co_2PMxQNwdkSxGWx2yzBMWxEyTyD5">
            <img src={ collection?.collection_images?.collectionLogo?.blobUrl } alt="" className="h-14 w-14 flex-shrink-0 rounded-full bg-gray-300"/>
          </NavLink>
        </div>
        <div className=" flex items-center  divide-x divide-gray-200">
          <div className="flex w-0 flex-1 ">
            <div className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent text-sm font-semibold text-gray-900">
              <NavLink to={ `/project/${projectId}/collections/${collection.collection_id}/edit` }>
                <EditOutlined className="text-lg mr-2"/>
                Edit Collection
              </NavLink>
            </div>
          </div>
          <div className=" flex w-0 flex-1">
            <div className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent  text-sm font-semibold text-gray-900">
              <div className="w-full flex flex-row justify-center items-center my-4  text-ft-lt">
                <div className=" cursor-pointer  h-6 w-6 ">
                  {
                    isCollectionVisible ?
                      <EyeFilled onClick={ () => updateCollectionVisibility(false) } className="text-lg"/> :
                      <EyeInvisibleFilled onClick={ () => updateCollectionVisibility(true) } className="text-lg"/>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
}
