import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import routes, { RouterType } from '../app/routers';
import Layout from '../components/Layout';
import { useAuth } from '../providers/auth/AuthProvider';


function RequireAuth({ children }: { children: JSX.Element }) {
  const { isConnected } = useAuth();
  const location = useLocation();
  if (!isConnected) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

const Navigation = (): JSX.Element => {

  return (
    <>
      <Routes>
        <Route>
          {
            routes.map((route: RouterType, index: number) => (
              route.requiredAuth ?
                <Route key={ index } element={ <Layout /> }>
                  <Route
                    path={ route.path }
                    element={
                      <RequireAuth>
                        { route.element }
                      </RequireAuth>
                    }
                  />
                </Route>   :
                <Route key={ index } path={ route.path } element={ route.element } />
            ))
          }
        </Route>
      </Routes>
    </>
  );
};

export default Navigation;
