import {
  CloseOutlined,
  CopyOutlined,
  EditOutlined,
  EyeFilled,
  EyeInvisibleFilled } from '@ant-design/icons';
import { Button, Input, Spin } from 'antd';
import React, { useState } from 'react';
// @ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { CollectionsAPI } from '../../app/api/endpoints/Collections';
import { useCan } from '../../app/can';
import { failedAlert, format422Error, handleError, successAlert } from '../../helpers/Utils';
import { CollectionParamsProps } from '../../models/Collection';
import DeleteCollectionModal from './DeleteCollectionModal';

export default function SettingsCollection({ collection, setCollection, projectId }: CollectionParamsProps) {
  const can = useCan();
  const [loading, setLoading] = useState(false);
  const [loadingVisibility, setLoadingVisibility] = useState(false);
  const [showDeleteCollectionModal, setShowDeleteCollectionModal] = useState(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [collectionName, setCollectionName] = useState<string | undefined>(collection?.collection_name);
  const [validationErrors, setValidationErrors] = useState({} as any);

  const updateCollectionName = async () => {
    setLoading(true);
    const { result } = await CollectionsAPI.updateCollectionName(collection?.collection_id, { collectionName: collectionName },
      (error) => {
        if(error && error?.response && error.response.status === 422) {
          // @ts-ignore
          setValidationErrors(format422Error(error.response.data?.errors));
        } else {
          handleError(error);  
        }
        setLoading(false);
      });
    
    if (result) {
      if (result.status) {
        successAlert('Updated successfully.');
        // @ts-ignore
        setCollection({
          ...collection,
          collection_name: collectionName!
        });
        setIsEditable(false);
      } else {
        failedAlert(result.message);
      }
      setLoading(false);
    }
  };
  const updateCollectionVisibility = async (collectionVisibility: boolean) => {
    setLoadingVisibility(true);
    const { result } = await CollectionsAPI.updateCollectionVisibility(collection?.collection_id,
      { collectionName: collection?.collection_name, collectionVisibility: collectionVisibility },
      (error) => {
        if(error && error?.response && error.response.status === 422) {
          // @ts-ignore
          setValidationErrors(format422Error(error.response.data?.errors));
        } else {
          handleError(error);  
        }
        setLoadingVisibility(false);
      });
    
    if (result) {
      if (result.status) {
        successAlert('Visibility updated successfully.');
        // @ts-ignore
        setCollection({
          ...collection,
          visibility: result.collection?.visibility
        });
      } else {
        failedAlert(result.message);
      }
      setLoadingVisibility(false);
    }
  };

  return (
    <>
      {
        can('read', 'project') && can('update', 'collection') &&
        <div className="flex flex-col justify-center items-center">
          <div className="bg-white w-5/6 min-h-[100px] rounded-xl p-10 border border-gray-300">
            <div className="flex flex-row justify-between items-center">
              <h1 className="text-xl font-bold tracking-[0.05rem]">Collection Name</h1>
              {
                isEditable ? (

                  <button
                    className="p-2 rounded-full bg-gray-200"
                    onClick={ () => setIsEditable(false) }
                  >
                    <CloseOutlined className="text-lg text-red-500 flex items-center justify-center" />
                  </button>
                ) : (
                  <button
                    className="p-2 rounded-full bg-gray-200"
                    onClick={ () => setIsEditable(true) }
                  >
                    <EditOutlined className="text-lg flex items-center justify-center" />
                  </button>
                )
              }
            </div>
            {
              isEditable ?
                <>
                  <div className="mt-5 flex">
                    <Input
                      className="flex-1 mr-2"
                      size="large"
                      value={ collectionName }
                      onChange={ (e) => setCollectionName(e.target.value) }
                    />
                    <Button loading={ loading } onClick={ () => updateCollectionName() } size="large" type="primary">Submit</Button>
                  </div>
                  {
                    validationErrors && validationErrors.collectionName && (
                      <span className="ant-form-item-explain-error">{ validationErrors.collectionName }</span>
                    )
                  }
                </>
                :
                <div className="mt-5 text-xl">{ collection?.collection_name }</div>
            }
          </div>
          <div className="min-h-[50px]"/>
          <div className="bg-white w-5/6  min-h-[100px] rounded-xl p-10 border border-gray-300">
            <Spin className="w-full" tip="Updating visibility..." spinning={ loadingVisibility }>
              <div className="flex flex-row justify-between items-center">
                <h1 className="text-xl text-black font-bold tracking-[0.05rem]">Collection Visibility</h1>
                <div className="w-[50px]">
                  <div className="w-full flex flex-row justify-center items-center my-4 text-ft-lt">
                    <div className="cursor-pointer h-6 w-6 ">
                      {
                        collection?.visibility ?
                          <EyeFilled onClick={ () => updateCollectionVisibility(false) } className="text-xl"/> :
                          <EyeInvisibleFilled onClick={ () => updateCollectionVisibility(true) } className="text-xl"/>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </Spin>
          </div>
          <div className="min-h-[50px]"/>
          <div className="bg-white w-5/6 min-h-[100px] rounded-xl p-10 border border-gray-300">
            <h1 className="text-xl text-black font-bold tracking-[0.05rem]">Collection ID</h1>
            <div className="flex flex-col gap-x-3 w-full mt-2">
              <span className="text-sm text-gray-500 tracking-[0.05rem] my-5">Used when interacting with the FeverTokens API.</span>
              <div className="flex flex-row justify-between items-center rounded-xl text-black border border-gray-300">
                <span className="px-4 py-2">{ collection?.collection_id }</span>
                <CopyToClipboard
                  text={ collection?.collection_id }
                  onCopy={ () => successAlert('Copied to clipboard') }
                >
                  <CopyOutlined className="text-lg mr-2" />
                </CopyToClipboard>
                  
              </div>
            </div>
          </div>
          <div className="min-h-[50px]"/>
          <div className="flex justify-start items-start w-5/6">
            <h2 className="text-xl text-red-500 font-bold text-left mb-2">Danger Zone</h2>
          </div>
          <div className="bg-white w-5/6 min-h-[100px] rounded-xl p-10 border border-red-300">
            <div className="flex flex-row justify-between items-center">
              <div>
                <p className="font-bold">Delete this collection</p>
                <p>Once you delete a collection, there is no going back.</p>
              </div>
              <div
                className="text-white bg-red-500 rounded-xl px-4 py-2 cursor-pointer"
                onClick={ () => setShowDeleteCollectionModal(true) }
              >
                Delete Collection
              </div>
              <DeleteCollectionModal
                projectId={ projectId }
                clubId={ '' }
                collectionId={ collection?.collection_id }
                collectionName={ collection?.collection_name }
                showDeleteCollectionModal={ showDeleteCollectionModal }
                setShowDeleteCollectionModal={ setShowDeleteCollectionModal }
              />
            </div>
          </div>
        </div>
      }
    </>
  );
}

