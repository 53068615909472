import { AxiosError } from 'axios';

import { RequestWrapper } from '../../../helpers/RequestWrapper';
import API from '..';

const COLLECTION_ENDPOINT = 'dash/collections';

class CollectionsAPI {
  static getAllCollections = (projectId: string | undefined, errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.get(`${COLLECTION_ENDPOINT}/${projectId}`)).data, errorHandler
    );
  };

  static syncCollections = (projectId: string | undefined, errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.get(`${COLLECTION_ENDPOINT}/${projectId}/sync`)).data, errorHandler
    );
  };

  static getCollection = (collectionId: string | undefined, errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.get(`${COLLECTION_ENDPOINT}/${collectionId}/show`)).data, errorHandler
    );
  };

  static getClubs = (errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.get(`${COLLECTION_ENDPOINT}/clubs`)).data, errorHandler
    );
  };

  static createCollection = (projectId: string | undefined, clubId: string | undefined, data: any, errorHandler: (error: AxiosError) => void) => {
    return RequestWrapper(
      async () => 
        (await API.post(`${COLLECTION_ENDPOINT}/${projectId}/${clubId}/store`, data)).data, errorHandler
    );
  };

  static uploadFile = (data: any, errorHandler: (error: AxiosError) => void) => {
    return RequestWrapper(
      async () => 
        (await API.post(`${COLLECTION_ENDPOINT}/upload-file`, data)).data, errorHandler
    );
  };
  
  static updateCollection = (collectionId: string | undefined, data: any, errorHandler: (error: AxiosError) => void) => {
    return RequestWrapper(
      async () => 
        (await API.put(`${COLLECTION_ENDPOINT}/${collectionId}/update`, data)).data, errorHandler
    );
  };

  static updateCollectionName = (collectionId: string | undefined, data: any, errorHandler: (error: AxiosError) => void) => {
    return RequestWrapper(
      async () => 
        (await API.put(`${COLLECTION_ENDPOINT}/${collectionId}/update-name`, data)).data, errorHandler
    );
  };

  static updateCollectionVisibility = (collectionId: string | undefined, data: any, errorHandler: (error: AxiosError) => void) => {
    return RequestWrapper(
      async () => 
        (await API.put(`${COLLECTION_ENDPOINT}/${collectionId}/update-visibility`, data)).data, errorHandler
    );
  };

  static destroyCollection = (collectionId: string | undefined, errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.delete(`${COLLECTION_ENDPOINT}/${collectionId}/destroy`)).data, errorHandler
    );
  };
}

export { COLLECTION_ENDPOINT, CollectionsAPI };
