import { Button, Col, Divider, Form,Input, Modal, Row, } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CollectionsAPI } from '../../app/api/endpoints/Collections';
import { failedAlert, handleError, successAlert } from '../../helpers/Utils';

export type SearchObj = {
  search: string;
  category: string
}

interface DeleteCollectionModalProps {
  projectId: string | undefined;
  clubId: string | undefined;
  collectionId: string | undefined;
  collectionName: string | undefined;
  showDeleteCollectionModal: boolean;
  setShowDeleteCollectionModal: (arg: boolean) => void;
}

export default function DeleteCollectionModal({ projectId, clubId, collectionId, collectionName, showDeleteCollectionModal, setShowDeleteCollectionModal }: DeleteCollectionModalProps) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [collectionNameInput, setProjectNameInput] = useState<string>('');

  useEffect(() => {
    setIsDisabled(collectionName !== collectionNameInput);
  }, [ collectionNameInput ]);

  const handleCloseDeleteCollectionModal = () => {
    setShowDeleteCollectionModal(false);
  };

  const destroy = async (collectionId: string | undefined) => {
    if (!isDisabled) {
      setLoading(true);
      const { result } = await CollectionsAPI.destroyCollection(collectionId,  (error) => {
        setLoading(false);
        handleError(error);
      });
      if (result) {
        setLoading(false);
        if (result.status) {
          successAlert('Collection Deleted successfully.');
          navigate(`/project/${projectId}/clubs/${clubId}?tab=collections`);
        } else {
          failedAlert(result.message);
        }
      }
    }
  };

  return (
    <Modal
      title={ <>Delete <span className="select-none">{ collectionName }</span></> }
      open={ showDeleteCollectionModal }
      footer={ null }
      onCancel={ handleCloseDeleteCollectionModal }
    >
      <Divider />
      <Form
        layout="vertical"
      >
        <Row gutter={ [0, 20] } className="m-b-20">
          
          <Col span={ 24 }>
            <p className="text-sm font-bold mb-2">To confirm, type &quot;<span className="select-none">{ collectionName }</span>&quot; in the box below</p>
            <Input
              size="large"
              placeholder="Collection name..."
              value={ collectionNameInput }
              onChange={ (e) => setProjectNameInput(e.target.value) }
            />
          </Col>
          <Col span={ 24 }>
            <Button
              danger
              disabled={ isDisabled }
              loading={ loading }
              onClick={ () => destroy(collectionId) }
              style={{ width: '100%' }}
            >
              Delete this collection
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
