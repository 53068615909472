import {
  DoubleLeftOutlined
} from '@ant-design/icons';
import React from 'react';

interface HeaderTitleProps {
  onBack: () => void;
  title: string;
}
const HeaderTitle = ({ onBack, title }: HeaderTitleProps) => {
  return (
    <div className="ant-page-header site-page-header ant-page-header-ghost">
      <div className="ant-page-header-heading">
        <div className="ant-page-header-heading-left">
          <div className="ant-page-header-back">
            <div role="button" className="ant-page-header-back-button" aria-label="Back" onClick={ () => onBack() }>
              <span role="img" aria-label="arrow-left" className="anticon anticon-arrow-left">
                <DoubleLeftOutlined />
              </span>
            </div>
          </div>
          <span className="ant-page-header-heading-title" title="Title">{ title }</span>
        </div>
      </div>
    </div>
  );
};

export default HeaderTitle;
