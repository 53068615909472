export const networks: { [key: number]: any } = {
  1: {
    'name': 'Ethereum Mainnet',
    'chain': 'ETH',
    'icon': 'ethereum',
    'rpc': [
      'https://eth.llamarpc.com',
      'https://endpoints.omniatech.io/v1/eth/mainnet/public',
      'https://rpc.ankr.com/eth',
      'https://eth-mainnet.nodereal.io/v1/1659dfb40aa24bbb8153a677b98064d7',
      'https://ethereum.publicnode.com',
      'https://1rpc.io/eth',
      'https://rpc.builder0x69.io',
      'https://rpc.mevblocker.io',
      'https://rpc.flashbots.net',
      'https://virginia.rpc.blxrbdn.com',
      'https://uk.rpc.blxrbdn.com',
      'https://singapore.rpc.blxrbdn.com',
      'https://eth.rpc.blxrbdn.com',
      'https://cloudflare-eth.com',
      'https://eth-mainnet.public.blastapi.io',
      'https://api.securerpc.com/v1',
      'https://api.bitstack.com/v1/wNFxbiJyQsSeLrX8RRCHi7NpRxrlErZk/DjShIqLishPCTB9HiMkPHXjUM9CNM9Na/ETH/mainnet',
      'https://eth-rpc.gateway.pokt.network',
      'https://eth-mainnet-public.unifra.io',
      'https://ethereum.blockpi.network/v1/rpc/public',
      'https://rpc.payload.de',
      'https://api.zmok.io/mainnet/oaen6dy8ff6hju9k',
      'https://eth-mainnet.g.alchemy.com/v2/demo',
      'https://eth.api.onfinality.io/public',
      'https://core.gashawk.io/rpc',
      'https://mainnet.eth.cloud.ava.do',
      'https://ethereumnodelight.app.runonflux.io',
      'https://eth-mainnet.rpcfast.com?api_key=xbhWBI1Wkguk8SNMu1bvvLurPGLXmgwYeC4S6g2H7WdwFigZSmPWVZRxrskEQwIf',
      'https://main-light.eth.linkpool.io',
      'https://rpc.eth.gateway.fm',
      'https://rpc.chain49.com/ethereum?api_key=14d1a8b86d8a4b4797938332394203dc',
      'https://eth.meowrpc.com',
      'https://eth.drpc.org',
      'https://mainnet.gateway.tenderly.co',
      'https://gateway.tenderly.co/public/mainnet',
      'https://api.mycryptoapi.com/eth'
    ],
    'features': [
      {
        'name': 'EIP155'
      },
      {
        'name': 'EIP1559'
      }
    ],
    'faucets': [],
    'nativeCurrency': {
      'name': 'Ether',
      'symbol': 'ETH',
      'decimals': 18
    },
    'infoURL': 'https://ethereum.org',
    'shortName': 'eth',
    'chainId': 1,
    'networkId': 1,
    'slip44': 60,
    'ens': {
      'registry': '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'
    },
    'explorers': [
      {
        'name': 'etherscan',
        'url': 'https://etherscan.io',
        'standard': 'EIP3091'
      }
    ],
    'tvl': 54633894921.14636,
    'chainSlug': 'ethereum',
    'ftIcon': 'https://ethereum.rpc.fevertokens.com/icon',
    'ftRpcName': 'https://ethereum.rpc.fevertokens.com'
  },
  5: {
    'name': 'Goerli',
    'title': 'Ethereum Testnet Goerli',
    'chain': 'ETH',
    'rpc': [
      'https://rpc.ankr.com/eth_goerli',
      'https://endpoints.omniatech.io/v1/eth/goerli/public',
      'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
      'https://eth-goerli.public.blastapi.io',
      'https://eth-goerli.g.alchemy.com/v2/demo',
      'https://goerli.blockpi.network/v1/rpc/public',
      'https://eth-goerli.api.onfinality.io/public',
      'https://rpc.goerli.eth.gateway.fm',
      'https://ethereum-goerli.publicnode.com',
      'https://goerli.gateway.tenderly.co',
      'https://gateway.tenderly.co/public/goerli',
      'https://rpc.goerli.mudit.blog'
    ],
    'faucets': [
      'http://fauceth.komputing.org?chain=5&address=${ADDRESS}',
      'https://goerli-faucet.slock.it?address=${ADDRESS}',
      'https://faucet.goerli.mudit.blog'
    ],
    'nativeCurrency': {
      'name': 'Goerli Ether',
      'symbol': 'ETH',
      'decimals': 18
    },
    'infoURL': 'https://goerli.net/#about',
    'shortName': 'gor',
    'chainId': 5,
    'networkId': 5,
    'ens': {
      'registry': '0x112234455c3a32fd11230c42e7bccd4a84e02010'
    },
    'explorers': [
      {
        'name': 'etherscan-goerli',
        'url': 'https://goerli.etherscan.io',
        'standard': 'EIP3091'
      }
    ],
    'ftIcon': 'https://goerli.rpc.fevertokens.com/icon',
    'ftRpcName': 'https://goerli.rpc.fevertokens.com'
  },
  137: {
    'name': 'Polygon Mainnet',
    'chain': 'Polygon',
    'icon': 'polygon',
    'rpc': [
      'https://polygon.llamarpc.com',
      'https://rpc-mainnet.maticvigil.com',
      'https://endpoints.omniatech.io/v1/matic/mainnet/public',
      'https://polygon-rpc.com',
      'https://rpc-mainnet.matic.network',
      'https://rpc-mainnet.matic.quiknode.pro',
      'https://matic-mainnet.chainstacklabs.com',
      'https://matic-mainnet-full-rpc.bwarelabs.com',
      'https://matic-mainnet-archive-rpc.bwarelabs.com',
      'https://poly-rpc.gateway.pokt.network',
      'https://rpc.ankr.com/polygon',
      'https://polygon-mainnet.public.blastapi.io',
      'https://polygonapi.terminet.io/rpc',
      'https://1rpc.io/matic',
      'https://polygon-mainnet.rpcfast.com?api_key=xbhWBI1Wkguk8SNMu1bvvLurPGLXmgwYeC4S6g2H7WdwFigZSmPWVZRxrskEQwIf',
      'https://polygon-bor.publicnode.com',
      'https://polygon-mainnet-public.unifra.io',
      'https://polygon-mainnet.g.alchemy.com/v2/demo',
      'https://polygon.blockpi.network/v1/rpc/public',
      'https://polygon.api.onfinality.io/public',
      'https://polygon.rpc.blxrbdn.com',
      'https://polygon.drpc.org',
      'https://polygon.gateway.tenderly.co',
      'https://gateway.tenderly.co/public/polygon',
      'https://polygon.meowrpc.com'
    ],
    'faucets': [],
    'nativeCurrency': {
      'name': 'MATIC',
      'symbol': 'MATIC',
      'decimals': 18
    },
    'infoURL': 'https://polygon.technology/',
    'shortName': 'matic',
    'chainId': 137,
    'networkId': 137,
    'slip44': 966,
    'explorers': [
      {
        'name': 'polygonscan',
        'url': 'https://polygonscan.com',
        'standard': 'EIP3091'
      }
    ],
    'tvl': 1177288989.7249694,
    'chainSlug': 'polygon',
    'ftIcon': 'https://polygon.rpc.fevertokens.com/icon',
    'ftRpcName': 'https://polygon.rpc.fevertokens.com'
  },
  80001: {
    'name': 'Mumbai',
    'title': 'Polygon Testnet Mumbai',
    'chain': 'Polygon',
    'icon': 'polygon',
    'rpc': [
      'https://rpc-mumbai.maticvigil.com',
      'https://endpoints.omniatech.io/v1/matic/mumbai/public',
      'https://rpc.ankr.com/polygon_mumbai',
      'https://polygontestapi.terminet.io/rpc',
      'https://polygon-testnet.public.blastapi.io',
      'https://polygon-mumbai.g.alchemy.com/v2/demo',
      'https://polygon-mumbai.blockpi.network/v1/rpc/public',
      'https://polygon-mumbai-bor.publicnode.com',
      'https://polygon-mumbai.gateway.tenderly.co',
      'https://gateway.tenderly.co/public/polygon-mumbai',
      'https://matic-mumbai.chainstacklabs.com',
      'https://matic-testnet-archive-rpc.bwarelabs.com'
    ],
    'faucets': [
      'https://faucet.polygon.technology/'
    ],
    'nativeCurrency': {
      'name': 'MATIC',
      'symbol': 'MATIC',
      'decimals': 18
    },
    'infoURL': 'https://polygon.technology/',
    'shortName': 'maticmum',
    'chainId': 80001,
    'networkId': 80001,
    'explorers': [
      {
        'name': 'polygonscan',
        'url': 'https://mumbai.polygonscan.com',
        'standard': 'EIP3091'
      }
    ],
    'ftIcon': 'https://mumbai.rpc.fevertokens.com/icon',
    'ftRpcName': 'https://mumbai.rpc.fevertokens.com'
  },
  1442: {
    'name': 'Polygon zkEVM Testnet',
    'title': 'Polygon zkEVM Testnet',
    'chain': 'Polygon',
    'rpc': [
      'https://rpc.public.zkevm-test.net'
    ],
    'faucets': [],
    'nativeCurrency': {
      'name': 'Ether',
      'symbol': 'ETH',
      'decimals': 18
    },
    'infoURL': 'https://polygon.technology/solutions/polygon-zkevm/',
    'shortName': 'testnet-zkEVM-mango',
    'chainId': 1442,
    'networkId': 1442,
    'explorers': [
      {
        'name': 'Polygon zkEVM explorer',
        'url': 'https://explorer.public.zkevm-test.net',
        'standard': 'EIP3091'
      }
    ],
    'ftIcon': 'https://polygon-zkevm-testnet.rpc.fevertokens.com/icon',
    'ftRpcName': 'https://polygon-zkevm-testnet.rpc.fevertokens.com'
  }
};
