import {
  CheckCircleTwoTone,
  ControlOutlined,  DeleteOutlined,
  EnvironmentOutlined,
  FrownTwoTone,
  LockOutlined,
  ProfileOutlined,
  WarningTwoTone } from '@ant-design/icons';
import { Button, Card, Col, Descriptions, Divider, Popover, Row, Space, Spin, Tag } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { UsersAPI } from '../../app/api/endpoints/Users';
import { useCan } from '../../app/can';
import ShowDate from '../../components/Common/ShowDate';
import HeaderTitle from '../../components/Parcial/HeaderTitle';
import ChangeRole from '../../components/Users/ChangeRole';
import { confirmAlert, failedAlert, handleError, successAlert } from '../../helpers/Utils';
import { User } from '../../models/User';

export default function ShowUser() {
  const can = useCan();
  const navigate = useNavigate();
  const { id } = useParams<{id: string}>();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<User>({} as User);
  const [activeTab, setActiveTab] = useState<string>('1');
  const [showChangeRoleModal, setShowChangeRoleModal] = useState<boolean>(false);

  useEffect(() => {
    getRole();
  }, [ id ]);

  const getRole = async() => {
    setLoading(true);
    const { result } = await UsersAPI.getUser(id, (error) => {
      handleError(error);
      setLoading(false);
    });

    if (result) {
      if (result.status) {
        setUser(result.user);
        setLoading(false);
      } else {
        failedAlert(result.message);
        navigate('/users');
      }
      setLoading(false);
    }
  };

  const onTabChange = (key: string) => {
    setActiveTab(key);
  };

  function WithIcon({ icon, title } : { icon: ReactElement, title: string}) {
    return (
      <>
        { icon } { title }
      </>
    );
  }

  const destroy = async (id: string | undefined) => {
    confirmAlert().then(async (res) => {
      if (res) {
        const { result } = await UsersAPI.destroy([ id || '' ],  (error) => {
          handleError(error);
        });
        if (result) {
          if (result.status) {
            navigate('/users');
            successAlert('Deleted successfully.');
          } else {
            failedAlert(result.message);
          }
        }
      }
    });
  };

  const resendActivationLink = async (id: string | undefined) => {
    setLoading(true);
    const { result } = await UsersAPI.sendActivationLink(id, (error) => {
      handleError(error);
      setLoading(false);
    });

    if (result) {
      if (result.status) {
        successAlert('The verification link has been sent successfully.');
      } else {
        failedAlert(result.message);
      }
      setLoading(false);
    }
  };
  
  return (
    <>
      <HeaderTitle
        onBack={ () => navigate('/users') }
        title="User Details"
      />
      <Divider />
      {
        can('read', 'user') &&
        <Spin className="p-t-70" tip="Loading..." spinning={ loading }>
          {
            Object.values(user).length > 0 &&
            <>
              <Row className="m-b-25">
                <Col span={ 24 } style={{ textAlign: 'right' }}>
                  <Space>
                    {
                      can('delete', 'user') &&
                      <>
                        <Button
                          type="primary"
                          danger
                          icon={ <DeleteOutlined/> }
                          onClick={ () => destroy(user?.id?.toString()) }
                        >
                          Delete
                        </Button>
                      </>
                    }
                  </Space>
                </Col>
              </Row>
              <Row gutter={ [20, 0] }>
                <Col span={ 8 }>
                  <Card
                    type="inner"
                    className="m-b-25"
                    title={ <WithIcon icon={ <ProfileOutlined/> } title={ user.name }/> }
                    hoverable={ true }
                  >
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column'
                    }}>
                      {
                        user.email &&
                          <>
                            <p className="m-b-0 f-s-16">
                              { user.email }
                              {
                                user.email_verified_at ?
                                  <Popover content="Email verified">
                                    <CheckCircleTwoTone style={{ marginLeft: 5 }} twoToneColor="#52c41a"  key={ 0 }/>
                                  </Popover>
                                  :
                                  <Popover content="Email not verified">
                                    <FrownTwoTone style={{ marginLeft: 5 }} twoToneColor="red" key={ 1 }/>
                                  </Popover>
                              }
                            </p>
                            {
                              !user.email_verified_at &&
                                <a onClick={ (e) => {
                                  e.preventDefault();
                                  resendActivationLink(user.id);
                                } }>Send activation link</a>
                            }
                          </>
                      }
                    </div>
                    <Divider/>
                    <Descriptions column={ 1 }>
                      {
                        user.username &&
                        <Descriptions.Item label="Username">
                          { user.username }
                        </Descriptions.Item>
                      }
                      {
                        user.phone &&
                        <Descriptions.Item
                          label={ 'Phone number' }
                        >
                          <Tag>
                            <a href={ `tel:${user.phone}` }>{ user.phone }</a>
                          </Tag>
                        </Descriptions.Item>
                      }
                      {
                        user.created_at &&
                        <Descriptions.Item label="Register date">
                          <ShowDate date={ user.created_at }/>
                        </Descriptions.Item>
                      }
                    </Descriptions>
                  </Card>
                  <Card
                    type="inner"
                    className="m-b-25"
                    title={ <WithIcon icon={ <LockOutlined/> } title="Two Factor Authentication"/> }
                    hoverable={ true }
                  >
                    <p>
                      <strong>Status:</strong>
                      { user.isTwoFactorEnabled ? 
                        <>
                          <CheckCircleTwoTone style={{ marginLeft: 5 }} twoToneColor="#52c41a"  key={ 0 }/> Enabled
                        </> :
                        <>
                          <WarningTwoTone style={{ marginLeft: 5 }} twoToneColor="red" key={ 1 }/> Disabled
                        </>
                      }
                    </p>
                  </Card>
                  <Card
                    type="inner"
                    className="m-b-25"
                    title={ <WithIcon icon={ <ControlOutlined/> } title="Role"/> }
                    hoverable={ true }
                    extra={ <Button type="primary" onClick={ () => setShowChangeRoleModal(true) }>Changer</Button> }
                  >
                    {
                      user.roles && user.roles.length > 0 ?
                        user.roles.map(role => (
                          <Tag className="m-b-4" color="blue" key={ role.id }>
                            { role.name }
                          </Tag>
                        )) :
                        <Tag color="blue">
                          Public
                        </Tag>
                    }
                    <ChangeRole
                      user={ user }
                      showChangeRoleModal={ showChangeRoleModal }
                      setShowChangeRoleModal={ setShowChangeRoleModal }
                      onRoleChange={ getRole }
                    />
                  </Card>
                  {
                    user.address && user.zip_code && user.country &&
                    <Card
                      type="inner"
                      className="m-b-25"
                      title={ <WithIcon icon={ <EnvironmentOutlined/> } title="Addresse"/> }
                      hoverable={ true }
                    >
                      <p>
                        { user.address }, { user.zip_code }, { user.country }
                      </p>
                    </Card>
                  }
                </Col>
                <Col span={ 16 }>
                  <Card
                    type="inner"
                    className="m-b-25"
                    hoverable={ true }
                    tabList={ [
                      {
                        tab: 'Tab 1',
                        key: '1',
                      },
                      {
                        tab: 'Tab 2',
                        key: '2',
                      },
                      {
                        tab: 'Tab 3',
                        key: '3',
                      },
                    ] }
                    activeTabKey={ activeTab }
                    onTabChange={ (key) => {
                      onTabChange(key);
                    } }
                  >
                    {
                      activeTab === '1' &&
                      <div>
                        Tab 1
                      </div>
                    }
                    {
                      activeTab === '2' &&
                        <div>Tab 2</div>
                    }
                    {
                      activeTab === '3' &&
                        <div>Tab 3</div>
                    }
                  </Card>
                </Col>
              </Row>
            </>
          }
        </Spin>
      }
    </>
  );
};
