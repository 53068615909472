import { SaveOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Row, Spin } from 'antd';
// @ts-ignore
import groupBy from 'lodash/groupBy';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { RolesAPI } from '../../app/api/endpoints/Roles';
import { useCan } from '../../app/can';
import HeaderTitle from '../../components/Parcial/HeaderTitle';
import { failedAlert, handleError, slugify, successAlert } from '../../helpers/Utils';
import { Permission, Role } from '../../models/User';


const EditRole = () => {
  const can = useCan();
  const navigate = useNavigate();
  const { id } = useParams<{id: string}>();
  const [loading, setLoading] = useState(false);
  const [loadingRole, setLoadingRole] = useState(false);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [validationErrors, setValidationErrors] = useState({} as Role);
  const [inputs, setInputs] = useState({
    name: '',
    slug: '',
    description: ''
  });
  const [checkedPermissions, setCheckedPermissions] = useState<string[]>([]);
  const [rolePermissions, setRolePermissions] = useState<number[]>([]);
  const [role, setRole] = useState<Role>({} as Role);

  useEffect(() => {
    getRole();
  }, [ id ]);

  const getRole = async() => {
    setLoadingRole(true);
    const { result } = await RolesAPI.getRole(id, (error) => {
      handleError(error);
      setLoadingRole(false);
    });

    if (result) {
      if (result.status) {
        setRole(result.role);
        const per = result.permissions.map((p: Permission) => {
          const title = p.slug.split('-')[1];
          return {
            title: title,
            permission: p
          };
        });
        const groupedPer = groupBy(per, function(n: any) {
          return n.title;
        });
        // @ts-ignore
        setPermissions(groupedPer);
      } else {
        failedAlert(result.message);
        navigate('/roles');
      }
      setLoadingRole(false);
    }
  };

  useEffect(() => {
    setInputs({
      name: role.name,
      slug: role.slug,
      description: role.description,
    });
    if(role.permissions){
      setCheckedPermissions(role.permissions.map(p => p.id.toString()));
      setRolePermissions(role.permissions.map(p => p.id));
    }
  }, [ role ]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.name === 'name') {
      setInputs(
        {
          ...inputs,
          name: e.target.value,
          slug: slugify(e.target.value),
        }
      );
    } else {
      setInputs({ ...inputs, [e.target.name]: e.target.value });
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if(event.target.checked) {
      setRolePermissions([...rolePermissions, parseInt(event.target.value)]);
    } else {
      setRolePermissions(rolePermissions.filter((id) => id !== parseInt(event.target.value)));
    }
    const selectedCheckboxes = [ ...checkedPermissions ];
    const isChecked = selectedCheckboxes.includes(event.target.value);
    if (!isChecked) {
      selectedCheckboxes.push(event.target.value);
    } else {
      selectedCheckboxes.splice(selectedCheckboxes.indexOf(event.target.value), 1);
    }
    setCheckedPermissions(selectedCheckboxes);
  };

  const editRole = async (e: FormEvent, id: string) => {
    e.preventDefault();
    setLoading(true);
    setValidationErrors({} as Role);
    const data = {
      ...inputs,
      permissions: checkedPermissions
    };
    const { result } = await RolesAPI.update(id, data, (error) => {
      if(error && error?.response && error.response.status === 422) {
        // @ts-ignore
        setValidationErrors(format422Error(error.response.data?.errors));
      } else {
        handleError(error);
      }
      setLoading(false);
    });

    if (result) {
      if (result.status) {
        successAlert('Updated successfully.');
        // #TODO update profile
        navigate('/roles');
      } else {
        failedAlert(result.message);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <HeaderTitle
        onBack={ () => navigate('/roles') }
        title="Edit role"
      />
      <Divider />
      {
        can('update', 'role') &&
        <Spin tip="Loading..." spinning={ loadingRole }>
          <Row>
            <Col span={ 24 }>
              <Form
                layout="vertical"
              >
                <Row gutter={ 24 }>
                  <Col span={ 12 }>
                    <Form.Item
                      label="Name:"
                      rules={ [ { required: true } ] }
                    >
                      <Input
                        value={ inputs.name }
                        name="name"
                        size="large"
                        onChange={ (e) => handleInputChange(e) }
                      />
                      {
                        validationErrors && validationErrors.name && (
                          <span className="ant-form-item-explain-error">{ validationErrors.name }</span>
                        )
                      }
                    </Form.Item>
                    <Form.Item
                      label="Slug:"
                      rules={ [ { required: true } ] }
                    >
                      <Input
                        value={ inputs.slug }
                        name="slug"
                        size="large"
                        onChange={ (e) => handleInputChange(e) }
                      />
                      {
                        validationErrors && validationErrors.slug && (
                          <span className="ant-form-item-explain-error">{ validationErrors.slug }</span>
                        )
                      }
                    </Form.Item>
                    <Form.Item
                      label="Description:"
                    >
                      <Input.TextArea
                        value={ inputs.description }
                        name="description"
                        onChange={ (e) => handleInputChange(e) }
                      />
                      {
                        validationErrors && validationErrors.description && (
                          <span className="ant-form-item-explain-error">{ validationErrors.description }</span>
                        )
                      }
                    </Form.Item>
                  </Col>
                  <Col span={ 12 }>
                    <Form.Item
                      label="Permissions:"
                      rules={ [ { required: true } ] }
                    >
                      <table className="table table-bordered table-hover">
                        <thead>
                          <tr>
                            <th className="bg-grey">Manage</th>
                            <th className="bg-grey text-center" colSpan={ 4 }>Permissions</th>
                          </tr>
                        </thead>
                        <tbody>
                          { Object.keys(permissions).map((key) => (
                            <tr key={ key }>
                              <td className="bg-grey">Manage { key }</td>
                              {
                                // @ts-ignore
                                permissions[key].map((p) => (
                                  <td key={ p.permission.id }>
                                    <div key={ p.permission.id } className="form-check">
                                      <input
                                        className="form-check-input"
                                        name={ p.permission.name }
                                        type="checkbox"
                                        checked={ rolePermissions.includes(p.permission.id) }
                                        value={ p.permission.id }
                                        onChange={ handleChange }
                                      />
                                      <label className="form-check-label">
                                        <span className="text-capitalize">{ p.permission.slug.split('-')[0] }</span>
                                      </label>
                                    </div>
                                  </td>
                                ))
                              }
                            </tr>
                          ))
                          }
                        </tbody>
                      </table>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={ (e) => editRole(e, role.id.toString()) }
                    loading={ loading }
                    size="large"
                    icon={ <SaveOutlined /> }
                  >
                    Update
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Spin>
      }
    </>
  );
};

export default EditRole;
